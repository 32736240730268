import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeIcon from '@mui/icons-material/Badge';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import { Autocomplete, Box, Button, Card } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_CLIENT } from '../../api/client';
import BaseInput from '../../components/common/BaseInput';
import Loader from '../../components/common/loader';
import rolesData from '../../utils/roles-data';
import Header from '../common/header';

const AddClient = () => {
  const [name, setName] = useState<string>();
  const [roles, setRoles] = useState<{ label: string; id: number }[]>();
  const navigate = useNavigate();

  const handleRoleChange = (roles: { label: string; id: number }[]) => {
    setRoles(roles);
  };

  const [loading, setLoading] = useState(false);

  const create = async () => {
    if (!name || !roles) return;
    const roleCodes: number[] = [];
    roles.forEach((role) => {
      roleCodes.push(role.id);
    });
    const success = await API_CLIENT.create(setLoading, { name, roles: roleCodes });
    // const success = false
    success ? navigate('/clients') : '';
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box maxWidth={1400} marginX='auto'>
          <Link to='/clients' style={{ textDecoration: 'none' }}>
            <Button color='warning' endIcon={<ArrowBackIcon />}>
              Cancel
            </Button>
          </Link>
          <Card>
            <Header text='Name' Icon={<BadgeIcon />} />
            <BaseInput label='Name' onChange={(e) => setName(e.target.value)} />
          </Card>
          <Card sx={{ marginTop: 2 }}>
            <Header text='Roles' Icon={<FactCheckIcon />} />
            <Autocomplete
              onChange={(_, roles) => handleRoleChange(roles)}
              fullWidth
              multiple
              options={rolesData}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              renderInput={(params) => <BaseInput {...params} label='Pick Roles' />}
            />
          </Card>
          <Button
            disabled={!name || !roles}
            variant='contained'
            size='small'
            sx={{ mt: 2, alignSelf: 'flex-start' }}
            onClick={() => create()}
          >
            Create Client
          </Button>
        </Box>
      )}
    </>
  );
};

export default AddClient;
