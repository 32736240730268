import { fabric } from 'fabric';
import { useEffect, useRef, useState } from 'react';
import { CanvasObject, MiniCanvasData } from '../../../../../api/digital-signage-cms/types';
// import { CanvasObject } from '../../../types';

// eslint-disable-next-line
const MiniCanvas = ({
  ogHeight,
  ogWidth,
  data,
  index,
  shape,
  zoom,
}: {
  ogHeight: number | undefined;
  ogWidth: number | undefined;
  data: MiniCanvasData;
  index: number;
  shape: CanvasObject;
  zoom: number | undefined;
}) => {
  // eslint-disable-next-line
  const fabricRef = useRef<any>(null);
  // eslint-disable-next-line
  const canvasRef = useRef<any>(null);
  const [imgURL, setImgURL] = useState<string>('');
  const [oldURL, setOldURL] = useState<string>('');

  function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  useEffect(() => {
    const initFabric = () => {
      fabricRef.current = new fabric.Canvas(canvasRef.current);
    };

    const disposeFabric = () => {
      if (fabricRef.current) fabricRef.current.dispose();
    };

    initFabric();
    if (fabricRef.current) {
      const dataCopy = { ...data };
      const objCopy: CanvasObject = data.objects[index];
      if (objCopy && objCopy.type == 'textbox' && objCopy.fill == dataCopy.background) {
        if (dataCopy.background === '#000000') {
          objCopy.fill = '#ffffff';
        } else objCopy.fill = '#000000';
      }

      dataCopy.objects = [objCopy];
      fabricRef.current.loadFromJSON(dataCopy, function () {
        fabricRef.current.setHeight(ogHeight);
        fabricRef.current.setWidth(ogWidth);
        fabricRef.current.setZoom(zoom);
        const u = dataURItoBlob(fabricRef.current.toDataURL());
        const nu = URL.createObjectURL(u);
        setImgURL(nu);
        setOldURL(nu);
      });
    }

    return () => {
      disposeFabric();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fabricRef.current) {
      const dataCopy = { ...data };
      const objCopy: CanvasObject = data.objects[index];
      if (objCopy && objCopy.type == 'textbox' && objCopy.fill == dataCopy.background) {
        if (dataCopy.background === '#000000') {
          objCopy.fill = '#ffffff';
        } else objCopy.fill = '#000000';
      }
      // adding back only the single object for this canvas
      dataCopy.objects = [data.objects[index]];
      fabricRef.current.loadFromJSON(dataCopy, function () {
        fabricRef.current.setHeight(ogHeight);
        fabricRef.current.setWidth(ogWidth);
        fabricRef.current.setZoom(zoom);
        const u = dataURItoBlob(fabricRef.current.toDataURL());
        const nu = URL.createObjectURL(u);
        setImgURL(nu);
        setOldURL(imgURL);
      });
    }
    // eslint-disable-next-line
  }, [shape && shape.top ? shape.top : '', shape && shape.left ? shape.left : '']);

  const createImg = (url: string) => {
    if (url) {
      if (oldURL !== '') {
        URL.revokeObjectURL(oldURL);
      }
      const img = <img height={'100%'} width={'100%'} src={url} alt='' />;
      return img;
    }
    return null;
  };
  return (
    <>
      {/* <canvas ref={canvasRef} /> */}
      {createImg(imgURL)}
    </>
  );
};
export default MiniCanvas;
