import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { API_USER } from '../../api/user';
import Loader from '../../components/common/loader';
import SingleUser from '../../components/single-user';

const SingleUserPage = () => {
  const location = useLocation();
  const id = location.pathname.split('/')[2];

  const { error, data, refetch } = useQuery(['GetSingleUser', id], API_USER.getSingle);

  if (error) return <p>error</p>;
  if (data) return <SingleUser data={data} id={id} refetch={refetch} />;
  return <Loader />;
};

export default SingleUserPage;
