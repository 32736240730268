import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import LogoUpload from './components/LogoUpload';
import PaletteCreation from './components/PaletteCreation';
import SetupStepsList from './components/SetupStepsList';
import TemplateSelection from './components/TemplateSelection';
import Welcome from './components/Welcome';

const TutorialSetup = () => {
  const [tutorialStep] = useLocalStorageState<number>('tutorial-step');
  const [currentStep, setCurrentStep] = useState<number>(tutorialStep ? tutorialStep : 0);

  return (
    <Stack
      height={'84vh'}
      alignItems={'center'}
      justifyContent={'center'}
      p={2}
      pt={4}
      borderRadius={2}
      spacing={4}
      // maxWidth={{ xs: '320px', sm: '720px', md: '1000px', lg: '1320px', xl: '1800px' }}
    >
      {currentStep === 0 && <Welcome updateNextStep={() => setCurrentStep(currentStep + 1)} />}
      {currentStep === 1 && <SetupStepsList updateNextStep={() => setCurrentStep(currentStep + 1)} />}
      {currentStep === 2 && <PaletteCreation updateNextStep={() => setCurrentStep(currentStep + 1)} />}
      {currentStep === 3 && <LogoUpload updateNextStep={() => setCurrentStep(currentStep + 1)} />}
      {currentStep === 4 && <TemplateSelection />}
      {currentStep === 5 && <Typography>Curio Slides Tutorial</Typography>}
      {currentStep === 6 && <Typography>Create a Playlist</Typography>}
      {currentStep === 7 && <Typography>Create a Presentation</Typography>}
      {currentStep === 8 && <Typography>COngrats</Typography>}
    </Stack>
  );
};

export default TutorialSetup;
