import { Box, Button, Dialog, DialogTitle } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { API_PLAYLIST } from '../../../../api/digital-signage-cms/playlist';
import { PlaylistData } from '../../../../api/digital-signage-cms/types';
import { UserAuthData } from '../../../../api/user/types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const PlaylistModal = ({
  user,
  handleCancel,
  handleAdd,
}: {
  user: UserAuthData;
  handleCancel: (v: boolean) => void;
  // handleAdd: (playlistIds: string[]) => void;
  handleAdd: (playlistSelected: PlaylistData[]) => void;
}) => {
  const [playlistName, setPlaylistName] = useState<string[]>([]);
  // eslint-disable-next-line
  const { data } = useQuery<any>(['AllUserPlaylist'], () => API_PLAYLIST.getAllPlaylist(user.currentClient.client.id));
  const handleChange = (event: SelectChangeEvent<typeof playlistName>) => {
    const {
      target: { value },
    } = event;
    setPlaylistName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleAddClick = () => {
    // const playlistIDs: string[] = [];
    // // transforming to list of IDs
    // for (let i = 0; i < playlistName.length; i++) {
    //   const foundIndex = data.findIndex((playlist: PlaylistData) => playlist.name === playlistName[i]);
    //   playlistIDs.push(data[foundIndex].id);
    // }
    // handleAdd(playlistIDs);
    const playlistIDs: PlaylistData[] = [];
    // transforming to list of IDs
    for (let i = 0; i < playlistName.length; i++) {
      const foundIndex = data.findIndex((playlist: PlaylistData) => playlist.name === playlistName[i]);
      playlistIDs.push(data[foundIndex]);
    }
    handleAdd(playlistIDs);
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Playlist</DialogTitle>
      {data && (
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id='demo-multiple-name-label'>Name</InputLabel>
          <Select
            labelId='demo-multiple-name-label'
            id='demo-multiple-name'
            multiple
            value={playlistName}
            onChange={handleChange}
            input={<OutlinedInput label='Name' />}
            MenuProps={MenuProps}
          >
            {data.map((playlist: PlaylistData) => (
              <MenuItem key={playlist.id} value={playlist.name}>
                {playlist.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Button variant='outlined' color='error' onClick={() => handleCancel(false)}>
          Cancel
        </Button>
        {playlistName.length > 0 && <Button onClick={handleAddClick}>Add</Button>}
      </Box>
    </Dialog>
  );
};

export default PlaylistModal;
