import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import s from '../styles.module.scss';
type Props = {
  item: {
    title: string;
    icon: JSX.Element;
    link: string;
  };
  open: boolean;
  currentLink: string;
};

const Item = ({ item, open, currentLink }: Props) => {
  return (
    <ListItem
      key={item.title}
      disablePadding
      sx={{ display: 'block', backgroundColor: item.link === currentLink ? '#dcdcdc' : '#fff' }}
    >
      <Link to={item.link} title={item.title}>
        <ListItemButton className={s.itemButton} sx={{ justifyContent: open ? 'initial' : 'center' }}>
          <ListItemIcon className={s.itemIcon} sx={{ mr: open ? 2 : 'auto', minWidth: open ? 0 : 'none' }}>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.title} className={s.itemText} sx={{ opacity: open ? 1 : 0 }} disableTypography />
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

export default Item;
