import { Add, Close } from '@mui/icons-material';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

type ColorPalette = {
  title: string;
  colors: string[];
};

const ColorPicker = ({
  handleChange,
  activeColor,
  colorPalettes,
  setColorPalettes,
}: {
  handleChange: (e: string) => void;
  activeColor: string;
  colorPalettes: ColorPalette[];
  setColorPalettes: Dispatch<SetStateAction<ColorPalette[]>>;
}) => {
  const [paletteInView, setPaletteInView] = useState<boolean>(false);
  const [addColorToPaletteModuleInView, setAddColorToPaletteModuleInView] = useState<boolean>(false);
  const [selectedColorPalette, setSelectedColorPalette] = useState<string>(colorPalettes[0].title);

  const handleColorInputClick = () => {
    setPaletteInView(!paletteInView);
  };

  const handleAddColorClick = () => {
    setAddColorToPaletteModuleInView(!addColorToPaletteModuleInView);
  };

  const handleAddColorToPalette = () => {
    // selectedPalette.colors.push(activeColor)
    // setColorPalettes({...colorPalettes, [selectedColorPalette]: selectedPalette})
    const found: ColorPalette | undefined = colorPalettes.find((elem: ColorPalette, index: number) => {
      if (elem.title == selectedColorPalette) {
        return { colorPalette: elem, index: index };
      }
    });
    if (found != undefined) {
      found.colors = [...found.colors, activeColor];
    }
    setColorPalettes([...colorPalettes]);
    setAddColorToPaletteModuleInView(!addColorToPaletteModuleInView);
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedColorPalette(e.target.value);
  };

  return (
    // <div className={s.colorPickerContainer}></div>
    <Box display={'flex'} position={'relative'} height={'100%'} width={'130%'}>
      <Box display={'flex'} width={'50%'} height={'80%'} alignItems={'center'}>
        <Typography color={'#fff'} width={'30%'} textAlign={'center'} fontWeight={'bold'}>
          Fill
        </Typography>
        <Box position={'relative'} width={'70%'} height={'100%'} bgcolor={activeColor} onClick={handleColorInputClick}>
          <input
            onChange={(e) => handleChange(e.target.value)}
            value={activeColor}
            type='color'
            style={{
              width: '100%',
              height: '100%',
              padding: 0,
              border: 'none',
              backgroundColor: 'transparent',
              outline: 'none',
            }}
          />
        </Box>
      </Box>
      <Box display={'grid'} sx={{ placeItems: 'center' }} width={'30%'} height={'100%'}>
        <Tooltip title='Add Color to Palette'>
          <Button sx={{ padding: 0, minWidth: '100%', width: '100%', height: '100%' }} onClick={handleAddColorClick}>
            {addColorToPaletteModuleInView ? (
              <Close htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
            ) : (
              <Add htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
            )}
          </Button>
        </Tooltip>
      </Box>
      {addColorToPaletteModuleInView && (
        <Box position={'absolute'} display={'flex'} flexWrap={'wrap'} top={'105%'} bgcolor={'#2a2c31'}>
          <Typography fontSize={{ md: 11 }} width={'100%'} color={'#fff'}>
            Choose a Color Palette
          </Typography>
          <Box width={'75%'}>
            <form style={{ width: '75%', height: '100%' }}>
              <select value={selectedColorPalette} onChange={handleSelectChange} style={{ height: '100%' }}>
                {colorPalettes.map((colorPalette: ColorPalette, index: number) => {
                  return (
                    <option value={colorPalette.title} key={`${colorPalette.title}-color-${index}`}>
                      {colorPalette.title}
                    </option>
                  );
                })}
              </select>
            </form>
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'25%'}>
            <Button sx={{ width: '100%', minWidth: '100%' }} onClick={handleAddColorToPalette}>
              <Add sx={{ width: '18px', height: '18px' }} />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ColorPicker;
