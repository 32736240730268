// import { API_WEATHER } from '../../api/weather';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { API_ACCESS_TOKEN } from '../../api/access-token';
import { API_AUTH_TOKEN } from '../../api/auth-token';
import AccessTokenTable from '../../components/access-token-table';
import AuthKeysTable from '../../components/auth-keys-table';
import Loader from '../../components/common/loader';

const AccessTokenPage = () => {
  // this will need to make a call to fetch ALL ACCESS TOKENS
  const { error, data } = useQuery(['GetAllAccessTokens'], API_ACCESS_TOKEN.getAllTokens);
  const { error: authKeyError, data: authKeyData } = useQuery(['GetAllAuthKeys'], API_AUTH_TOKEN.getAllAuthTokenCount);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} justifyContent={'center'} alignItems={'center'}>
      {data ? <AccessTokenTable data={data} /> : error ? <p>error retrieving the access tokens</p> : <Loader />}
      {authKeyData ? (
        <AuthKeysTable data={authKeyData} />
      ) : authKeyError ? (
        <p>error retrieving the auth keys</p>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default AccessTokenPage;
