import { ClientUser } from '../../../api/client/types';

const createData = (id: string, name: string, users: ClientUser[]) => {
  return { id, name, users };
};

const formatClientNameToDisplay = (name: string) => {
  const words = name.split('-');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }

  return words.join(' ');
};

export { createData, formatClientNameToDisplay };
