import { useNavigate } from 'react-router-dom';
import CanvasDrawing from '../../components/canvas-drawing/CanvasDrawing';
import useLocalStorage from '../../hooks/useLocalStorage';
import { decrypt } from '../../utils/cryptojs-helper';
import checkIfEmptyString from '../../utils/check-if-empty';

const DrawingCanvasPage = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [drawSiteId, setDrawSiteId] = useLocalStorage('draw_site', {} as { siteId: string; siteName: string });
  const siteId = decrypt(drawSiteId.siteId).slice(1, -1);
  const siteName = drawSiteId.siteName;

  // the following is for tracking the tab visibility
  if (checkIfEmptyString(siteId) || checkIfEmptyString(siteName)) {
    navigate('/map-whiteboard');
  }
  return <CanvasDrawing siteId={siteId} siteName={siteName} />;
};

export default DrawingCanvasPage;
