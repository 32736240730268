import BackupTableIcon from '@mui/icons-material/BackupTable';
import { Box, Button, Card, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MUITable from '@mui/material/Table';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_ACCESS_TOKEN } from '../../api/access-token';
import { AccessTokenGetData } from '../../api/access-token/types';
import Header from '../common/header';
import Modal from '../common/modal';
import Row from './components/Row';
import s from './styles.module.scss';
import { Props, TableData } from './types';
import createData from './utils/create-data';

const AccessTokenTable = ({ data }: Props) => {
  const rows: TableData[] = [];
  data.forEach((item: AccessTokenGetData) => {
    rows.push(createData(item.id, item.deviceName, item.callsActual, item.zipCode, item.client));
  });

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState('');

  const deleteToken = async () => {
    const success = await API_ACCESS_TOKEN.removeToken(id);
    success
      ? setTimeout(() => {
          navigate(0);
        }, 2000)
      : '';
  };

  return (
    <>
      <TableContainer component={Card} className={s.container}>
        <Header text='Access Tokens' Icon={<BackupTableIcon />} />
        <MUITable sx={{ marginTop: 5.5 }}>
          <TableHead className={s.header}>
            <TableRow>
              <TableCell className={s.cell}>Device Name</TableCell>
              <TableCell className={s.cell}>Zip</TableCell>
              <TableCell className={s.cell}>Actual Calls</TableCell>
              <TableCell className={s.cell}>Client</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length < 1 ? (
              <TableRow>
                <td style={{ padding: '4px 12px' }}>No Access Tokens</td>
              </TableRow>
            ) : (
              <>
                {rows.map((row) => {
                  return <Row key={row.id} data={row} setIsOpen={setIsOpen} setId={setId} />;
                })}
              </>
            )}
          </TableBody>
        </MUITable>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'left'} width={'100%'}>
          <Link to='/access-tokens/new' style={{ textDecoration: 'none' }}>
            <Button variant='contained' sx={{ alignSelf: 'flex-start' }}>
              New Access Token
            </Button>
          </Link>
        </Box>
      </TableContainer>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} fn={deleteToken} />
    </>
  );
};

export default AccessTokenTable;
