/* eslint-disable quotes */
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import { Button, ClickAwayListener, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { Box } from '@mui/system';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContext';
import { Roles } from '../../../types';
import { decrypt } from '../../../utils/cryptojs-helper';
import PickClientModal from './PickClientModal';

const drawerWidth = 220;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const CustomAppBar = ({ open, setOpen }: Props) => {
  // const { setUser, user } = useAuthContext();
  const { userNew, setUserNew } = useAuthContext();

  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  const navigate = useNavigate();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <AppBar position='fixed' open={open} elevation={0} sx={{ left: open ? 0 : 57 }}>
      <Toolbar sx={{ width: open ? '100%' : '90%' }}>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={() => setOpen(true)}
          edge='start'
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            width: '95%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant='outlined'
            size='small'
            color='info'
            endIcon={<ArrowDropDownIcon />}
            disableRipple
            onClick={() => setModalOpen(true)}
          >
            {user.currentClient.client.name}
          </Button>
          <PickClientModal modalOpen={modalOpen} setModalOpen={setModalOpen} user={user} setUser={setUserNew} />

          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <HtmlTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <>
                    {/* If have user role, then show profile page */}
                    {user.currentClient.client.roles.includes(Roles.USERS) && (
                      <Link to='/profile' style={{ textDecoration: 'none', color: '#000' }}>
                        <Box mx={{ display: 'flex', cursor: 'pointer', padding: '10px' }}>
                          <PersonIcon color='primary' />
                          <Typography ml={1}>My Profile</Typography>
                        </Box>
                      </Link>
                    )}
                    <Box
                      mx={{ display: 'flex', cursor: 'pointer', padding: '10px' }}
                      onClick={() => navigate('/logout')}
                    >
                      <LogoutIcon color='primary' />
                      <Typography ml={1}>Log Out</Typography>
                    </Box>
                  </>
                }
              >
                <div onClick={handleTooltipOpen} style={{ cursor: 'pointer' }}>
                  <Typography color='inherit' mx={{ display: 'flex', alignItems: 'center' }}>
                    {`${user.firstName} ${user.lastName}`} <ArrowDropDownIcon />
                  </Typography>
                </div>
              </HtmlTooltip>
            </div>
          </ClickAwayListener>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
