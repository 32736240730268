import { TableCell, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import s from '../styles.module.scss';
import { TableData } from '../types';
import { formatClientNameToDisplay } from '../utils/create-data';

const Row = ({ data }: { data: TableData }) => {
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell component='th' scope='row'>
        <Link to={`/clients/${data.id}`} className={s.link}>
          {formatClientNameToDisplay(data.name)}
        </Link>
      </TableCell>
      <TableCell>{data.users.length}</TableCell>
    </TableRow>
  );
};

export default Row;
