import { Add, East } from '@mui/icons-material';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import { Box, Button, Card, CardHeader, Grid, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { UserAuthData } from '../../../api/user/types';
import PlaylistTable from './components/PlaylistTable';

const PlaylistView = ({ user }: { user: UserAuthData }) => {
  const theme = useTheme();
  const [tutorialStep] = useLocalStorageState<number>('tutorial-step');
  return (
    <Card
      sx={{
        width: '100%',
        padding: 0,
        paddingTop: { xs: '5vh', md: '8vh', lg: '7vh', xl: '6vh' },
        height: { xs: '88vh', md: '84vh' },
        justifyContent: 'initial',
        alignItems: 'initial',
      }}
    >
      <CardHeader
        disableTypography
        title={
          <Grid container>
            <Grid
              item
              xs={9}
              display={'flex'}
              flexDirection={'row'}
              gap={1}
              alignItems={'center'}
              justifyContent={'left'}
            >
              <Typography fontWeight={'bold'}>Playlists</Typography>
              <FeaturedPlayListIcon fontSize='medium' />
            </Grid>
            <Grid item xs={3} display={'flex'} justifyContent={'center'}>
              <Link to={'create'}>
                <Button sx={{ backgroundColor: theme.palette.primary.main }}>
                  <Add htmlColor='#fff' />
                </Button>
              </Link>
            </Grid>
          </Grid>
        }
      />
      <PlaylistTable user={user} />
      {/* Check to see if they are in the tutorial step */}
      {tutorialStep === 6 ? (
        <Box position={'absolute'} top={0} left={0} bgcolor={'#000000de'} height={'100%'} width={'100%'}>
          <Grid container p={1}>
            <Grid
              item
              xs={9}
              display={'flex'}
              flexDirection={'row'}
              gap={3}
              alignItems={'center'}
              justifyContent={'left'}
            >
              <Typography width={'100%'} color={'#fff'} textAlign={'right'} fontWeight={'bold'} fontSize={'1.8rem'}>
                Click here to create your first playlist
              </Typography>
              <East htmlColor='#fff' fontSize='large' />
            </Grid>
            <Grid item xs={3} display={'flex'} justifyContent={'center'}>
              <Link to={'create'}>
                <Button sx={{ backgroundColor: theme.palette.primary.main }}>
                  <Add htmlColor='#fff' />
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ''
      )}
    </Card>
  );
};

export default PlaylistView;
