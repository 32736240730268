import LinkIcon from '@mui/icons-material/Link';
import { Box, Card } from '@mui/material';
import { useState } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import LINK_ITEMS from '../../data/link-items';
import { decrypt } from '../../utils/cryptojs-helper';
import Header from '../common/header';
import LinkItem from './components/LinkItem';
import s from './styles.module.scss';
import { Item } from './types';

const QuickLinks = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  const roles = user.currentClient.client.roles;

  const [items] = useState<Item[]>(
    LINK_ITEMS.filter((item) => roles.some((role: number) => item.roles.includes(role)))
  );

  return (
    <Card style={{ height: 400 }}>
      <Header text='Quick Links' Icon={<LinkIcon />} />
      <Box className={s.container}>
        {items.map((item: Item) => (
          <LinkItem key={item.title} data={item} />
        ))}
      </Box>
    </Card>
  );
};

export default QuickLinks;
