import axios from '../../../../config/axiosV2';
import { canvasJSON } from '../../drawing/types';
import { SiteLocation } from './types';

const getAllSiteLcationsForLocation = async (locationId: string): Promise<SiteLocation[]> => {
  const url = `whiteboard/map/location/${locationId}/site-locations`;
  try {
    const { data } = await axios.get(url);
    const { sitelocations }: { sitelocations: SiteLocation[] } = data;
    return sitelocations;
  } catch {
    return [];
  }
};

// function for creating a new site location connected to the Location of User
const createNewSiteLocation = async ({
  locationId,
  name,
}: {
  locationId: string;
  name: string;
}): Promise<SiteLocation> => {
  const url = `whiteboard/map/add-new-site-location/${locationId}`;
  try {
    const { data } = await axios.post(url, { name });
    const newSite: SiteLocation = data;
    return newSite;
  } catch {
    throw Error('Failed to create new label');
  }
};

// function for deleting a sitelocation
const deleteSiteLocation = async (locationId: string, siteLocationId: string) => {
  const url = `whiteboard/map/site/${locationId}/${siteLocationId}`;
  try {
    const { data } = await axios.delete(url);
    return data;
  } catch (error) {
    throw Error('Failed to delete site location.');
  }
};

// function for archiving a sitelocation
const archiveSiteLocation = async (locationId: string, siteLocationId: string) => {
  const url = `whiteboard/map/site/archive/${locationId}/${siteLocationId}`;
  try {
    const { data } = await axios.put(url);
    return data;
  } catch (error) {
    throw Error('Failed to delete site location.');
  }
};

// function for getting HISTORY
const getSiteLocationHistory = async (siteLocationId: string) => {
  const url = `whiteboard/map/site/${siteLocationId}/history`;
  try {
    const { data } = await axios.get(url);
    const { history } = data;
    return history;
  } catch (error) {
    throw Error;
  }
};

// Fetching a single SITELOCATION via id
const getSingleSiteLocation = async (siteLocation: string): Promise<SiteLocation | null> => {
  const url = `/whiteboard/map/site-location/${siteLocation}`;
  try {
    const { data } = await axios.get(url);
    return data.sitelocation;
  } catch {
    return null;
  }
};

const fetchCanvasJSON = async (url: string): Promise<canvasJSON | null> => {
  try {
    const response = await fetch(url);
    const data: canvasJSON = await response.json();
    return data;
  } catch {
    return null;
  }
};

export {
  archiveSiteLocation,
  createNewSiteLocation,
  deleteSiteLocation,
  fetchCanvasJSON,
  getAllSiteLcationsForLocation,
  getSingleSiteLocation,
  getSiteLocationHistory,
};
