import { Add, Delete } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import { Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_DS_MEDIA } from '../../../api/digital-signage-cms/media';
import { MediaGetData } from '../../../api/digital-signage-cms/types';
import { bytesToKilobytes, formatDateMMDDYYY } from '../../../utils/format-data';
import ConfirmDialog from '../../common/ConfirmDialog';

type Props = {
  allMedia: MediaGetData[];
};

const MediaView = ({ allMedia }: Props) => {
  const queryClient = useQueryClient();
  const [confirmationDialogInView, setConfirmationDialogInView] = useState<boolean>(false);
  const [selectedMediaForDeletion, setSelectedMediaForDeletion] = useState<number>(-1);
  const [mediaType, setMediaType] = useState<string>('media');
  const [mediaInView, setMediaInView] = useState<MediaGetData[]>([]);

  // table row for media. It will do any formatting needed
  // and return an HTML TableRow
  const generateTableRow = (media: MediaGetData, index: number) => {
    // we will format the name of the file...let it to 10 characters
    return (
      <Box display={'flex'} justifyContent={'center'} borderBottom={'1px solid #e3e3e3'} pb={1} key={index}>
        <Tooltip title={media.name} arrow>
          <Typography
            textAlign={'center'}
            flex={1}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
            textOverflow={'ellipsis'}
            noWrap
          >
            {media.name.length > 10 ? `${media.name.substring(0, 10)}...` : media.name}
          </Typography>
        </Tooltip>
        <Box flex={2} height={'16vh'} width={'100%'} maxWidth={'15vw'}>
          <img src={media.previewBucketURL} height={'100%'} width={'100%'}></img>
        </Box>
        <Typography textAlign={'center'} flex={1} alignItems={'center'} justifyContent={'center'} display={'flex'}>
          {media.width}x{media.height}
        </Typography>
        <Typography textAlign={'center'} flex={1} alignItems={'center'} justifyContent={'center'} display={'flex'}>
          {bytesToKilobytes(media.size)}
        </Typography>
        <Typography textAlign={'center'} flex={1} alignItems={'center'} justifyContent={'center'} display={'flex'}>
          {formatDateMMDDYYY(media.createdAt)}
        </Typography>
        <Stack alignItems={'center'} justifyContent={'center'}>
          <Button
            onClick={() => {
              setConfirmationDialogInView(true);
              setSelectedMediaForDeletion(index);
            }}
          >
            <Delete htmlColor='red' />
          </Button>
        </Stack>
      </Box>
    );
  };

  const handleDeleteMediaClick = (res: boolean) => {
    if (res) handleMediaDelete(selectedMediaForDeletion);
    setConfirmationDialogInView(false);
    setSelectedMediaForDeletion(-1);
  };

  const handleMediaDelete = async (index: number) => {
    const media = mediaInView[index];
    deleteMediaMutation.mutate(media);
  };

  // deleting mutation
  const deleteMediaMutation = useMutation({
    mutationFn: (mediaToDelete: MediaGetData) => API_DS_MEDIA.deleteMedia(mediaToDelete.id),
    // eslint-disable-next-line
    onSuccess: (data: any, variables, context) => {
      const filteredDeletedMedia = allMedia.filter((m) => {
        return m.id !== variables.id;
      });
      queryClient.setQueryData(['allClientMedia'], filteredDeletedMedia);
      // we need to have a way to know which media to show
      // filterMediaByType(mediaType);
    },
  });

  // changing the current media being shown via button click
  const handleMediaTypeShown = (type: string) => {
    filterMediaByType(type);
    setMediaType(type);
  };

  const filterMediaByType = (typeToView: string) => {
    const _filteredMedia = allMedia.filter((media: MediaGetData) => {
      return media.type === typeToView;
    });
    setMediaInView(_filteredMedia);
  };

  useEffect(() => {
    filterMediaByType(mediaType);
    // eslint-disable-next-line
  }, [allMedia]);

  return (
    <>
      <Box bgcolor={'#ffffff'} height={{ md: '80vh' }} borderRadius={4} border={'1px solid #e3e3e3'}>
        <Grid container style={{ borderBottom: '1px solid #e3e3e3' }}>
          <Grid item xs={12}>
            <Box display={'flex'} alignItems={'center'} gap={1} pl={2} pr={2} height={{ md: '6vh' }}>
              <Typography fontWeight={'bold'}>Media / Assets</Typography>
              <FolderIcon />
              <Typography fontWeight={'bold'}>({allMedia.length})</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} gap={2} pl={2} pt={1} pb={1} height={{ md: '8vh' }}>
              <Button
                onClick={() => handleMediaTypeShown('media')}
                variant={mediaType === 'media' ? 'contained' : 'outlined'}
              >
                Media
              </Button>
              <Button
                onClick={() => handleMediaTypeShown('asset')}
                variant={mediaType === 'asset' ? 'contained' : 'outlined'}
              >
                Assets
              </Button>
              {mediaType == 'media' ? (
                <Link to={'add'}>
                  <Button onClick={() => handleMediaTypeShown('asset')} variant={'contained'}>
                    <Add htmlColor='#fff' />
                  </Button>
                </Link>
              ) : (
                <Link to={'asset/add'}>
                  <Button onClick={() => handleMediaTypeShown('asset')} variant={'contained'}>
                    <Add htmlColor='#fff' />
                  </Button>
                </Link>
              )}
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'} pl={1} pr={1} mr={1} ml={'auto'}>
                <Typography fontWeight={'bold'}>Count:</Typography>
                <Typography fontWeight={'bold'}>{mediaInView.length}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* MEDIA CONTENT SHOWING */}
        <Box height={'70vh'} bgcolor={'rose'}>
          <Box display={'flex'} alignItems={'center'} height={{ md: '15%' }} borderBottom={'1px solid #e3e3e3'} mb={1}>
            <Typography flex={1} textAlign={'center'}>
              Name
            </Typography>
            <Typography flex={2} textAlign={'center'}>
              Preview
            </Typography>
            <Typography flex={1} textAlign={'center'}>
              Dimensions
            </Typography>
            <Typography flex={1} textAlign={'center'}>
              Size
            </Typography>
            <Typography flex={1} textAlign={'center'}>
              Created At
            </Typography>
            <Typography flex={1} textAlign={'center'}>
              Actions
            </Typography>
          </Box>
          <Box
            height={'75%'}
            overflow={'scroll'}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            className={'hide-scrollbars'}
          >
            {mediaInView.map((media: MediaGetData, index: number) => {
              return generateTableRow(media, index);
            })}
          </Box>
        </Box>
      </Box>
      {confirmationDialogInView && (
        <ConfirmDialog
          title={'Please confirm action'}
          message={'Are you sure you would like to delete this media?'}
          handleClick={handleDeleteMediaClick}
        />
      )}
    </>
  );
};

export default MediaView;
