import { Card } from '@mui/material';
import { useAuthContext } from '../../context/AuthContext';
import getDaysBetweenNowAndFutureDate from '../../utils/get-days-between-now-and-future-date';
import LockClockIcon from '@mui/icons-material/LockClock';
import PasswordNotExpired from './components/PasswordNotExpired';
import PasswordExpired from './components/PasswordExpired';
import Header from '../common/header';

const PasswordExpiration = () => {
  const { user } = useAuthContext();
  const daysTillExpiration = getDaysBetweenNowAndFutureDate(user.passwordExpiresAt);

  return (
    <Card style={{ height: 400 }}>
      <Header text='Password Details' Icon={<LockClockIcon />} />
      {daysTillExpiration > 0 ? <PasswordNotExpired daysTillExpiration={daysTillExpiration} /> : <PasswordExpired />}
    </Card>
  );
};

export default PasswordExpiration;
