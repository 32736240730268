import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import s from '../styles.module.scss';

const PasswordNotExpired = ({ daysTillExpiration }: { daysTillExpiration: number }) => (
  <Typography lineHeight={2}>
    Your Password Expires In{' '}
    <span className={s.day}>
      {daysTillExpiration} {daysTillExpiration === 1 ? 'Day' : 'Days'}
    </span>
    <br></br>
    You can update your password{' '}
    <Link className={s.link} to='/update-password'>
      here
    </Link>
  </Typography>
);

export default PasswordNotExpired;
