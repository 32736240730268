import { TableData } from '../types';

const createData = (
  id: string,
  deviceName: string,
  callsActual: number,
  zipCode: string,
  client: string
): TableData => {
  return { id, deviceName, zipCode, callsActual, client };
};

export default createData;
