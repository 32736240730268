import toast from 'react-hot-toast';
import axios from '../../config/axiosV2';

const generateTokens = async (tokenData: {
  service: string;
  clientNames: string[];
}): Promise<boolean | { success: string[]; failed: string[] }> => {
  const { service, clientNames } = tokenData;
  try {
    const res = await axios.post('/auth-keys/generate', { service, clients: clientNames });
    return res.data;
  } catch (error) {
    toast.error('Error Generating Auth Token');
    return false;
  }
};

const getAllAuthTokenCount = async (): Promise<{ authkeys: { [key: string]: number } }> => {
  try {
    const { data } = await axios.get<{ authkeys: { [key: string]: number } }>('/auth-keys/all');
    return data;
  } catch (error) {
    toast.error('Error Getting Auth Keys');
    return { authkeys: {} };
  }
};

export { generateTokens, getAllAuthTokenCount };
