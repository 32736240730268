import axios from '../../../config/axiosV2';
import { canvasJSONObjects } from './types';

// function for creating a new site location connected to the Location of User
const saveWBMapDrawingJSON = async ({
  locationId,
  jsonData,
}: {
  locationId: string;
  jsonData: canvasJSONObjects;
}): Promise<boolean> => {
  try {
    const url = `whiteboard/map/drawing/save/labeled-icon-map/${locationId}`;
    await axios.post(url, jsonData);
    return true;
  } catch (error) {
    throw Error('Failed to save JSON');
  }
};

// function for publishing a new site location Map image
const publishWBMapDrawingJSON = async ({
  locationId,
  jsonData,
}: {
  locationId: string;
  jsonData: canvasJSONObjects;
}): Promise<boolean> => {
  try {
    const url = `whiteboard/map/drawing/final/publish/labeled-icon-map/${locationId}`;
    await axios.post(url, jsonData);
    return true;
  } catch {
    throw Error('Failed to publish. Please ensure you have saved before publishing.');
  }
};

// function for checking if a SiteLocation Map is available to draw
const checkWBMapDrawingAvailability = async ({ locationId }: { locationId: string }): Promise<boolean> => {
  try {
    const url = `whiteboard/map/drawing/availability/${locationId}`;
    const { data } = await axios.get(url);
    const { isInDrawMode } = data;
    return isInDrawMode;
  } catch (error) {
    throw Error('Failed to save JSON');
  }
};

// function for checking if a Map is available to draw
// we will send the siteLocationId and the new status
const updateWBMapDrawingAvailability = async ({
  locationId,
  status,
}: {
  locationId: string;
  status: boolean;
}): Promise<boolean> => {
  try {
    const url = `whiteboard/map/drawing/availability/${locationId}`;
    await axios.put(url, { status });
    return true;
  } catch (error) {
    throw Error('Failed to update SiteLocation isInDrawMode');
  }
};

export { checkWBMapDrawingAvailability, publishWBMapDrawingJSON, saveWBMapDrawingJSON, updateWBMapDrawingAvailability };
