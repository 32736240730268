import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import s from '../styles.module.scss';
import { Item } from '../types';

const LinkItem = ({ data }: { data: Item }) => (
  <Link to={data.link} key={data.title} className={s.link}>
    <div className={s.row}>
      <ArrowRightAltIcon />
      {data.title}
    </div>
  </Link>
);

export default LinkItem;
