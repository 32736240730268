import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Props } from './types';

// used for single client and single user components to delete
const Modal = ({ isOpen, setIsOpen, fn }: Props) => {
  return (
    <Dialog open={isOpen} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>Are you sure you want to delete this?</DialogTitle>
      <DialogActions>
        <Button color='error' onClick={() => setIsOpen(false)}>
          No
        </Button>
        <Button onClick={() => fn()} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
