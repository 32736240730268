import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import 'primeicons/primeicons.css';
import { FileUpload } from 'primereact/fileupload';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';

const AssetUploadFile = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  // const clientId = user.currentClient.client.id;
  // const userId = user.id;
  // const clientName = user.currentClient.client.name;
  const theme = useTheme();
  const primaryMain = theme.palette.primary.main;
  const successMain = theme.palette.success.main;
  const errorMain = theme.palette.error.main;

  const handleUploadError = () => {
    toast.error('Failed to upload the media. Please try again.');
    return;
  };

  const handleSuccessfulUpload = () => {
    toast.success('Successfully uploaded new Media!');
    return;
  };

  return (
    <>
      <Box bgcolor={'#ffffff'} border={'1px solid #e3e3e3'} borderRadius={2}>
        <Typography p={2} fontWeight={'bold'}>
          You Are Uploading Assets
        </Typography>
        <FileUpload
          url={`${process.env.REACT_APP_API_BASE_URL_V2}/digitalsignage/media/assets/upload/${user.currentClient.client.id}`}
          withCredentials={true}
          name='media[]' // name 'media[]' is what is called in the api, also has to be array to handle multiple files
          multiple
          accept='image/*'
          maxFileSize={1000000}
          onError={handleUploadError}
          onUpload={handleSuccessfulUpload}
          emptyTemplate={<p>Drag and drop files to here to upload.</p>}
          chooseOptions={{ style: { background: primaryMain, border: 'none' } }}
          uploadOptions={{ style: { background: successMain, border: 'none' } }}
          cancelOptions={{ style: { background: errorMain, border: 'none' } }}
        />
      </Box>
      <Link to='/digital-signage/media' style={{ textDecoration: 'none' }}>
        <Button startIcon={<ArrowBackIcon />} color='warning'>
          Go Back
        </Button>
      </Link>
    </>
  );
};

export default AssetUploadFile;
