import { Add, Close, Delete } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { ChangeEvent, FormEvent, useState } from 'react';
import checkIfEmptyString from '../../../../utils/check-if-empty';

import s from '../styles/colorPalette.module.scss';

const CreateColorPalette = ({
  handleColorPaletteSubmit,
  handleClose,
}: {
  handleColorPaletteSubmit: (
    e: FormEvent<HTMLFormElement>,
    newColorPaletteTitle: string,
    newColorPaletteColors: string[]
  ) => void;
  handleClose: () => void;
}) => {
  const [newColorPaletteColors, setNewColorPaletteColors] = useState<string[]>([]);
  const [newColorPaletteTitle, setNewColorPaletteTitle] = useState<string>('');
  const [currentInputColorValue, setCurrentInputColorValue] = useState('#fff');

  const handleColorInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentInputColorValue(e.target.value);
  };

  const handleColorAdd = () => {
    const newColors = [...newColorPaletteColors];
    newColors.push(currentInputColorValue);
    setNewColorPaletteColors(newColors);
  };

  const removeColor = (index: number) => {
    const newPaletteColors = newColorPaletteColors.filter((color) => {
      return color !== newColorPaletteColors[index];
    });
    setNewColorPaletteColors(newPaletteColors);
  };

  return (
    <div className={s.colorPaletteCreationModule}>
      <div className={s.formHeader}>
        <p>Create a New Color Palette</p>
        <button onClick={handleClose}>
          <Close />
        </button>
      </div>
      <form
        className={s.form}
        onSubmit={(e) => handleColorPaletteSubmit(e, newColorPaletteTitle, newColorPaletteColors)}
      >
        <TextField
          id='standard-basic'
          label='Palette Name'
          variant='standard'
          onChange={(e) => setNewColorPaletteTitle(e.target.value)}
          value={newColorPaletteTitle}
        />
        <div className={s.newColorsList}>
          {newColorPaletteColors.map((color, index: number) => {
            return (
              <div className={s.colorRow} key={`color-${color}-${index}`}>
                <div className={s.color}>
                  <span style={{ backgroundColor: color }}></span>
                </div>
                <input type='text' value={color} readOnly />
                <button onClick={() => removeColor(index)}>
                  <Delete />
                </button>
              </div>
            );
          })}
        </div>
        {!checkIfEmptyString(newColorPaletteTitle) && newColorPaletteColors.length > 0 ? (
          <button className={s.submitBtn} type='submit'>
            CREATE
          </button>
        ) : (
          ''
        )}
      </form>
      <div className={s.colorPickerWrp}>
        <input type='color' value={currentInputColorValue} onChange={handleColorInputChange} />
        <button className={s.addColorBtn} onClick={handleColorAdd}>
          <Add />
        </button>
      </div>
    </div>
  );
};

export default CreateColorPalette;
