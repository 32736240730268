import { TextField } from '@mui/material';
import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';
import checkIfEmptyString from '../../../../utils/check-if-empty';

const TemplateNameTextfield = forwardRef((props, ref) => {
  const [templateName, setTemplateName] = useState<string>('');

  useImperativeHandle(ref, () => ({
    getValue: () => templateName,
  }));

  return (
    <TextField
      error={checkIfEmptyString(templateName) ? true : false}
      id={checkIfEmptyString(templateName) ? 'outlined-error' : 'outlined-basic'}
      label={checkIfEmptyString(templateName) ? 'Must not be empty' : 'Template Name'}
      variant='outlined'
      value={templateName}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setTemplateName(e.target.value)}
      InputProps={{
        style: {
          width: '100%',
          color: '#ffffff',
        },
      }}
      InputLabelProps={{
        style: {
          color: templateName.length == 0 ? 'red' : '#ffffff',
        },
      }}
      style={{ width: '100%', marginBottom: '2vh' }}
    />
  );
});
TemplateNameTextfield.displayName = 'TemplateNameTextfield';

export default TemplateNameTextfield;
