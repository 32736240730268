import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../context/AuthContext';
import { IClient } from '../../../types';
import { encrypt } from '../../../utils/cryptojs-helper';

type Props = {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  user: IUser;
  setUser: Dispatch<SetStateAction<string>>;
};

const PickClientModal = ({ modalOpen, setModalOpen, user, setUser }: Props) => {
  const navigate = useNavigate();

  const changeClient = (client: IClient) => {
    const updatedUserClients = { ...user, currentClient: client };
    // updatedUserClients.currentClient.client.roles = roleCodes;
    const encryptedUser = encrypt(JSON.stringify(updatedUserClients));
    setUser(encryptedUser);
    // setUser(JSON.stringify(updatedUserClients));
    setModalOpen(false);
    navigate('/dashboard');
    // navigate(0); // refresh page
  };

  return (
    <Dialog onClose={() => setModalOpen(false)} open={modalOpen}>
      <List sx={{ py: 5, minWidth: 400 }}>
        {user.clients.map((client) => (
          <ListItem button onClick={() => changeClient(client)} key={client.client.id} sx={{ px: 5 }}>
            <ListItemText primary={client.client.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default PickClientModal;
