import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({
  open,
  handleClose,
  title,
  contentText,
  confirmBtnText,
  rejectBtnText,
}: {
  open: boolean;
  handleClose: (response: boolean) => void;
  title: string;
  contentText: string;
  confirmBtnText: string;
  rejectBtnText: string;
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          &#9888;&#65039;
          {title}
          &#9888;&#65039;
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{contentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant='outlined' color='error'>
            {rejectBtnText}
          </Button>
          <Button onClick={() => handleClose(true)} variant='contained' autoFocus color='success'>
            {confirmBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
