import CategoryIcon from '@mui/icons-material/Category';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import PaletteIcon from '@mui/icons-material/Palette';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import { List, ListItem } from '@mui/material';
import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { ChangeEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import ObjectEditTools from './ObjectEditTools';
import ToolButton from './common/ToolButton';
import ColorPaletteModule from './modules/ColorPaletteModule';
import ImageModule from './modules/ImageModule';
import ShapesModule from './modules/ShapesModule';
import TemplateModule from './modules/TemplateModule';

const ToolsSidebar = ({
  canvas,
  canvasHeight,
  canvasWidth,
}: {
  canvas: MutableRefObject<Canvas | undefined>;
  canvasHeight: number;
  canvasWidth: number;
}) => {
  const backgroundObject = canvas.current?.getObjects()[0];
  const [shapesInView, setShapesInView] = useState<boolean>(false);
  const [imageInView, setImageInView] = useState<boolean>(false);
  const [colorPalleteInView, setColorPalleteInView] = useState<boolean>(false);
  const [activeColor, setActiveColor] = useState<string>('#eeeeee');
  const [templateInView, setTemplateInView] = useState<boolean>(false);
  const [activeTools, setActiveTools] = useState<boolean[]>([false, false, false, false]);
  // refs
  const paletteBtnRef = useRef<HTMLLIElement | null>(null);

  // setReset(!reset)
  // color palettes
  const [colorPalettes, setColorPalettes] = useLocalStorageState('colorPalettes', {
    defaultValue: [{ title: 'Basic Colors', colors: ['#C70039', '#0000FF', '#00FF00'] }],
  });

  // Handling the shapes button click
  const handleShapeBtnClick = () => {
    setShapesInView(!shapesInView);
  };

  const handleTextBtnClick = () => {
    const text = new fabric.Textbox('text', {
      top: 200,
      left: 100,
      editable: true,
      fontSize: 164,
      fill: '#000000',
    });
    text.setControlsVisibility({
      mt: false, // Middle top
      mb: false, // Middle bottom
      ml: true, // Middle left
      mr: true, // Middle right
      tl: false, // Top left
      tr: false, // Top right
      bl: false, // Bottom left
      br: false, // Bottom right
    });
    canvas.current?.add(text);
    canvas.current?.setActiveObject(text);
    canvas.current?.renderAll();
  };

  const handleImageInViewClick = () => {
    setShapesInView(false);
    setColorPalleteInView(false);
    setTemplateInView(false);
    setImageInView(!imageInView);
  };

  const handlePaletteClick = () => {
    setColorPalleteInView(!colorPalleteInView);
  };

  const handleBackgroundColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    setActiveColor(e.target.value);
    // fetch the first object of the list of obejcts
    // const backgroundObject = canvas.current?.getObjects()[0]
    backgroundObject?.set('fill', e.target.value);
    canvas.current?.setBackgroundColor(e.target.value, () => {
      canvas.current?.renderAll();
    });
  };

  const handleTemplateClick = () => {
    setShapesInView(false);
    setColorPalleteInView(false);
    setImageInView(false);
    setTemplateInView(!templateInView);
  };

  const handleActiveToolsChange = (index: number) => {
    const activeToolsClone = [...activeTools];
    activeToolsClone[index] = !activeTools[index];
    setActiveTools(activeToolsClone);
  };

  const resetAll = () => {
    setShapesInView(false);
    setImageInView(false);
    setColorPalleteInView(false);
    setTemplateInView(false);
  };

  // adding an event listeren to key down pressed
  useEffect(() => {
    canvas.current?.on('object:added', () => resetAll());
    canvas.current?.on('selection:created', () => resetAll());
    canvas.current?.on('focus:reset', () => resetAll());
    return function cleanup() {
      // eslint-disable-next-line
      canvas.current?.off({
        'object:added': () => resetAll(),
        'selection:created': () => resetAll(),
        'focus:reset': () => resetAll(),
      });
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <List
        style={{
          height: '100%',
          background: '#2a2c31',
          boxSizing: 'border-box',
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          listStyle: 'none',
        }}
      >
        <ListItem
          sx={{
            boxSizing: 'border-box',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px',
            color: '#fff',
          }}
        >
          <p>BG Color</p>
          <button
            style={{
              width: '5vh',
              height: '5vh',
              padding: 0,
              border: 'none',
              borderRadius: 6,
              backgroundColor: 'transparent',
            }}
          >
            <input
              type='color'
              onChange={handleBackgroundColorChange}
              value={activeColor}
              style={{ width: '100%', height: '100%', border: 'none', borderRadius: 6, backgroundColor: 'transparent' }}
            ></input>
          </button>
        </ListItem>
        <ListItem
          onClick={() => handleActiveToolsChange(0)}
          style={{
            boxSizing: 'border-box',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '12px',
          }}
        >
          <p>Shapes</p>
          <ToolButton handleClick={handleShapeBtnClick} title={'shapes'}>
            <CategoryIcon />
          </ToolButton>
        </ListItem>
        <ListItem
          style={{
            boxSizing: 'border-box',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '12px',
          }}
        >
          <p>Text</p>
          <ToolButton handleClick={handleTextBtnClick} title={'text'}>
            <FormatShapesIcon />
          </ToolButton>
        </ListItem>
        <ListItem
          onClick={() => handleActiveToolsChange(1)}
          style={{
            boxSizing: 'border-box',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '12px',
          }}
        >
          <p>Assets</p>
          <ToolButton handleClick={handleImageInViewClick} title={'media'}>
            <InsertPhotoOutlinedIcon />
          </ToolButton>
        </ListItem>
        <ListItem
          ref={paletteBtnRef}
          onClick={() => handleActiveToolsChange(2)}
          style={{
            boxSizing: 'border-box',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '12px',
          }}
        >
          <p>Template</p>
          <ToolButton handleClick={handleTemplateClick} title={'templates'}>
            <ViewQuiltIcon />
          </ToolButton>
        </ListItem>
        {/* <ListItem>
          <p>Load</p>
          <ToolButton handleClick={() => alert('edit tool coming soon')} title={'Load and Edit Media'}>
            <EditIcon />
          </ToolButton>
        </ListItem> */}
        <ListItem
          onClick={() => handleActiveToolsChange(3)}
          style={{
            boxSizing: 'border-box',
            borderRadius: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontSize: '12px',
          }}
        >
          <p>Palette</p>
          <ToolButton handleClick={handlePaletteClick} title={'color palette'}>
            <PaletteIcon />
          </ToolButton>
        </ListItem>
      </List>
      {shapesInView && <ShapesModule canvas={canvas} />}
      {imageInView && <ImageModule canvas={canvas} handleClose={handleImageInViewClick} />}
      {colorPalleteInView && (
        <ColorPaletteModule
          paletteRef={paletteBtnRef}
          canvas={canvas}
          colorPalettes={colorPalettes}
          setColorPalettes={setColorPalettes}
        />
      )}
      {templateInView && (
        <TemplateModule
          canvas={canvas}
          handleClose={handleTemplateClick}
          height={canvasHeight}
          width={canvasWidth}
          bgColorUpdate={setActiveColor}
        />
      )}
      <ObjectEditTools canvas={canvas} colorPalettes={colorPalettes} setColorPalettes={setColorPalettes} />
    </>
  );
};

export default ToolsSidebar;
