import axios from '../../../../config/axiosV2';
import { Location, LocationData, WBLabel } from './types';

const getAllLocations = async (): Promise<Location[]> => {
  const url = '/whiteboard/map/locations';
  try {
    const { data } = await axios.get(url);
    const { locations }: { locations: Location[] } = data;
    return locations;
  } catch {
    return [];
  }
};

const getSingleLocation = async (): Promise<LocationData | null> => {
  const url = '/whiteboard/map/location';
  try {
    const { data } = await axios.get(url);
    return data;
  } catch {
    return null;
  }
};

// function for creating a new label connected to the Location of User
const createNewLabelForLocation = async ({
  locationId,
  name,
}: {
  locationId: string;
  name: string;
}): Promise<WBLabel> => {
  const url = `whiteboard/map/add-new-label/${locationId}`;
  try {
    const { data } = await axios.post(url, { name });
    const newLabel: WBLabel = data.newLabel;
    return newLabel;
  } catch {
    throw Error('Failed to create new label');
  }
};

const deleteLabelForLocation = async (locationId: string, labelId: string): Promise<string | boolean> => {
  const url = `whiteboard/map/label/${locationId}/${labelId}`;
  try {
    const { data } = await axios.delete(url);
    return data.wbLabelId;
  } catch {
    throw Error('Failed to delete label');
  }
};

const updateLabelForLocation = async (locationId: string, labelId: string, name: string): Promise<string | boolean> => {
  const url = `whiteboard/map/label/${locationId}/${labelId}`;
  try {
    const { data } = await axios.put(url, { name });
    return data.label.id;
  } catch {
    throw Error('Failed to delete label');
  }
};
export {
  createNewLabelForLocation,
  deleteLabelForLocation,
  getAllLocations,
  getSingleLocation,
  updateLabelForLocation,
};
