import { Box, CardHeader } from '@mui/material';
import { JSXElementConstructor, ReactElement } from 'react';
import s from './styles.module.scss';

type Props = {
  text: string;
  Icon: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
};

const Header = ({ text, Icon }: Props) => {
  return (
    <CardHeader
      disableTypography
      className={s.container}
      title={
        <Box display='flex' alignItems='center'>
          {text} {Icon}
        </Box>
      }
    />
  );
};

export default Header;
