import { Box, FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';

type Props = {
  labels: { description: string; id: string; name: string }[];
  updateParent: (value: string) => void;
};

const LabelSelect = ({ labels, updateParent }: Props) => {
  const [selected, setSelected] = useState(labels.length > 0 ? labels[0].name : '');
  updateParent(selected);
  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
    updateParent(event.target.value as string);
  };

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'}>
      <FormControl fullWidth>
        <Select id='label-select' value={selected} onChange={handleChange}>
          {labels.map((label) => {
            return (
              <MenuItem key={label.id} value={label.name}>
                {label.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default LabelSelect;
