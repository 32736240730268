import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { Stack, Typography, useTheme } from '@mui/material';
import { FileUpload } from 'primereact/fileupload';
import toast from 'react-hot-toast';
import useLocalStorageState from 'use-local-storage-state';
import { useAuthContext } from '../../../../context/AuthContext';
import { decrypt } from '../../../../utils/cryptojs-helper';

type Props = {
  updateNextStep: () => void;
};

const LogoUpload = ({ updateNextStep }: Props) => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const userJSON = JSON.parse(decrypted);
  const { currentClient } = userJSON;
  const theme = useTheme();
  const darkblue = theme.palette.primary.main;

  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');

  const handleUploadError = () => {
    toast.error('Failed to upload the media. Please try again.');
    return;
  };

  const handleSuccessfulUpload = () => {
    toast.success('Successfully uploaded new Media!');
    updateNextStep();
    if (tutorialStep) {
      setTutorialStep(tutorialStep + 1);
    } else setTutorialStep(1);
    return;
  };

  return (
    <Stack bgcolor={darkblue} height={'100%'} alignItems={'center'} p={2} pt={4} borderRadius={2} spacing={4}>
      <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <LooksTwoIcon fontSize='large' color={'info'} />
        <Typography fontSize={'2rem'} color={'#fff'} width={'100%'} pl={1}>
          Logo Upload
        </Typography>
      </Stack>
      <Typography color={'#fff'} fontSize={'1.2rem'}>
        Let&apos;s go ahead an upload your company logo!
      </Typography>
      <FileUpload
        url={`${process.env.REACT_APP_API_BASE_URL_V2}/digitalsignage/media/assets/upload/${currentClient.client.id}`}
        // eslint-disable-next-line
        withCredentials={true}
        name='media[]' // name 'media[]' is what is called in the api, also has to be array to handle multiple files
        multiple
        accept='image/*'
        maxFileSize={1000000}
        onError={handleUploadError}
        onUpload={handleSuccessfulUpload}
        emptyTemplate={<p>Drag and drop files to here to upload.</p>}
        chooseOptions={{ style: { background: theme.palette.primary.main, border: 'none' } }}
        uploadOptions={{ style: { background: theme.palette.success.main, border: 'none' } }}
        cancelOptions={{ style: { background: theme.palette.error.main, border: 'none' } }}
      />
    </Stack>
  );
};

export default LogoUpload;
