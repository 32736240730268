const Triangle = ({ color }: { color: string }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 50 50'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='photo-edit' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='svgs' transform='translate(-238.000000, -71.000000)'>
          <g id='triangle' transform='translate(238.000000, 71.000000)'>
            <rect id='ViewBox' x='0' y='0' width='50' height='50'></rect>
            <polyline
              id='Path'
              fill={color}
              fillRule='nonzero'
              points='2.08333333 43.75 47.9166667 43.75 25 4.16666667'
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Triangle;
