import toast from 'react-hot-toast';
import axios from '../../../config/axiosV2';
import { IClient } from '../../../types';
import { CanvasTemplateData, LocalSavedTemplate } from '../types';
// import { Template, TemplateData } from '../types';

// route for Admin to upload ALL CLOUD template
const uploadTemplateToCloud = async (templateData: LocalSavedTemplate): Promise<boolean> => {
  try {
    const res = await axios.post('/digitalsignage/files/cloud/templates', templateData);
    if (res.status === 200) {
      return true;
    } else return false;
  } catch {
    toast.error('Error - Failed to uploadTemplateToCloud presentation. Please Try Again.');
    return false;
  }
};

const fetchCloudTemplates = async (): Promise<boolean | { id: string; name: string; previewURL: string }[]> => {
  try {
    const res = await axios.get('/digitalsignage/files/templates');
    // checking to make sure the statis is okay
    if (res.status === 200) {
      const { data } = res;
      const { previews } = data;
      return previews;
    } else {
      throw Error('Failed to fetch Cloud templates');
    }
  } catch {
    return false;
  }
};

const fetchCloudTemplateJSON = async (id: string): Promise<boolean | CanvasTemplateData> => {
  try {
    // TODO: at the ID to the route
    const res = await axios.get(`/digitalsignage/files/cloud/templates/json/${id}`);
    const { data }: { data: CanvasTemplateData } = res;
    return data;
  } catch {
    toast.error('Error - Failed to uploadTemplateToCloud presentation. Please Try Again.');
    return false;
  }
};

const deleteCloudTemplate = async (id: string): Promise<boolean> => {
  try {
    // TODO: at the ID to the route
    await axios.delete(`/digitalsignage/files/cloud/templates/${id}`);
    return true;
  } catch {
    toast.error('Error - Failed to uploadTemplateToCloud presentation. Please Try Again.');
    return false;
  }
};

/* SHARED TEMPLATES API */

const uploadSharedTemplateToCloud = async (templateData: LocalSavedTemplate, client: IClient): Promise<boolean> => {
  const finalData = {
    canvasData: templateData,
    client: client.client.id,
  };
  try {
    const res = await axios.post('/digitalsignage/files/shared/templates', finalData);
    if (res.status === 200) {
      return true;
    } else return false;
  } catch {
    toast.error('Error - Failed to uploadTemplateToCloud presentation. Please Try Again.');
    return false;
  }
};
// this will fetch a list of the Preview URLS for the Shared templates
const fetchSharedTemplates = async (clientId: string) => {
  try {
    const res = await axios.get(`/digitalsignage/files/shared/templates/${clientId}`);
    // checking to make sure the statis is okay
    if (res.status === 200) {
      const { data } = res;
      const { previews } = data;
      return previews;
    } else {
      throw Error('Failed to fetch Cloud templates');
    }
  } catch {
    return false;
  }
};
// this will fetch the selected Shared Template JSON data
const fetchSharedTemplateJSON = async (id: string): Promise<boolean | CanvasTemplateData> => {
  try {
    // TODO: at the ID to the route
    const res = await axios.get(`/digitalsignage/files/shared/templates/json/${id}`);
    const { data }: { data: CanvasTemplateData } = res;
    return data;
  } catch {
    toast.error('Error - Failed to uploadTemplateToCloud presentation. Please Try Again.');
    return false;
  }
};

const deleteSharedTemplate = async (id: string): Promise<boolean> => {
  try {
    const res = await axios.delete(`/digitalsignage/files/shared/templates/${id}`);
    const { data } = res;
    console.log(res);
    return data.deletedTemplateId;
  } catch {
    toast.error('Error - Failed to uploadTemplateToCloud presentation. Please Try Again.');
    return false;
  }
};

export {
  deleteCloudTemplate,
  deleteSharedTemplate,
  fetchCloudTemplateJSON,
  fetchCloudTemplates,
  fetchSharedTemplateJSON,
  fetchSharedTemplates,
  uploadSharedTemplateToCloud,
  uploadTemplateToCloud,
};
