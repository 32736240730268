import { Box, Button, Grid, Typography } from '@mui/material';
import { useGesture } from '@use-gesture/react';
import { fabric } from 'fabric';
import { Canvas, Group, Image, Path } from 'fabric/fabric-impl';
import { Ref, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import toast from 'react-hot-toast';
import { canvasJSON } from '../../../../api/whiteboard/drawing/types';
import { fetchCanvasJSON } from '../../../../api/whiteboard/map/site/api';
import { WBMedia } from '../../../../api/whiteboard/map/site/types';
import LabelSelect from './LabelSelect';
import Pencils from './Pencils';
import Tools from './Tools';

const COLORS = ['#DB2B39', '#29335C', '#F3A712', '#441151', '#0D5C63', '#DBF4A7', '#E63B2E', '#FF7733'];

const FabricCanvas = ({
  json,
  locationId,
  siteId,
  siteName,
  bgImgURL,
  icons,
  container,
  labels,
}: {
  json: {
    id: string;
    createdAt: Date;
    hasBeenFinalized: boolean;
    name: string;
    bucketURL: string;
    jsonURL: string;
    height: number;
    width: number;
  }[];
  locationId: string;
  siteId: string;
  siteName: string;
  bgImgURL: string;
  icons: WBMedia[] | undefined;
  container: null;
  labels: { description: string; id: string; name: string }[];
}) => {
  const selectedLabel = useRef('');

  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvas = useRef<Canvas>();

  const updateSelectedLabel = (value: string) => {
    selectedLabel.current = value;
  };

  /**
   * This funciton will add an icon to the Map.
   * Depending on the Icon clicked will determine the
   * Image for the Icon
   * @param icon: WBMedia: bucketURL will be used for fetching the image
   */
  const handleButtonClick = (icon: WBMedia) => {
    const label = new fabric.Textbox(selectedLabel.current, {
      fontFamily: 'arial',
      left: 5,
      // top: t + (35 + (i * 14)),
      top: 28,
      fontSize: 10,
      width: 40,
      textAlign: 'center',
    });
    // iterate through the words to create a Text for each
    fabric.Image.fromURL(
      icon.bucketURL,
      function (oImg) {
        oImg.scale(0.28);
        const imgWidth = oImg.getScaledWidth();
        const leftOffset = (50 - imgWidth) / 2;
        oImg.left = leftOffset;
        if (canvas.current) {
          const { x, y } = canvas.current.getVpCenter();
          const group = new fabric.Group([label, oImg], {
            left: x,
            top: y,
            width: 50,
            height: 50,
            backgroundColor: 'red',
          });
          // activeObjRef.current = group;
          canvas.current.add(group);
          canvas.current.requestRenderAll();
        }
      },
      { crossOrigin: 'anonymous' }
    );
  };

  /**
   * This function will be based to the child component <Pencils/>
   * it is called whenever a
   */
  const enterDrawMode = (color: string) => {
    // canvas.freeDrawingBrush.width = 3;
    if (canvas.current) {
      canvas.current.isDrawingMode = true;
      // canvas.isDrawingMode = true;
      const brush = canvas.current.freeDrawingBrush;
      brush.color = color;
    }
  };

  const exitDrawMode = () => {
    if (canvas.current) {
      canvas.current.isDrawingMode = false;
    }
  };

  // this function is for adjusting the pen drawing size
  const adjustPenSize = (size: number) => {
    if (canvas.current) {
      canvas.current.freeDrawingBrush.width = size;
    }
  };

  // use-gesture/react
  const bind = useGesture(
    {
      // eslint-disable-next-line
      onDrag: ({ canceled, movement, touches }: any) => {
        if (canceled) {
          return;
        }
        if (touches == 1) {
          if (canvas.current?.getZoom() == 1) return;
          if (canvas.current?.isDrawingMode) return;
          if (canvas.current?.getActiveObject()) return;
          lockAllCanvasObjects();
          if (canvas.current) {
            canvas.current.selection = false;
          }
          // negative -> right
          // positive -> left
          const delta = new fabric.Point(0.09 * movement[0], 0.09 * movement[1]);
          // canvas.current?.relativePan(delta);
          canvas.current?.relativePan(delta);
        }
      },
      onDragEnd: () => {
        unlockAllCanvasObjects();
        if (canvas.current) {
          canvas.current.selection = true;
        }
      },
      // eslint-disable-next-line
      onPinch: ({ canceled, origin: [ox, oy], movement: [ms, m2], memo }: any) => {
        if (canceled) return;
        lockAllCanvasObjects();
        if (canvas.current) {
          canvas.current.selection = false;
        }
        if (memo) {
          let zoom = memo * ms;
          if (zoom > 8) zoom = 8;
          if (zoom < 1) {
            zoom = 1;
            canvas.current?.setViewportTransform([1, 0, 0, 1, 0, 0]);
          } else {
            canvas.current?.zoomToPoint({ x: ox, y: oy }, zoom);
          }
        } else {
          memo = canvas.current?.getZoom();
        }
        return memo;
      },
    },
    {
      pinch: { threshold: 0.1 },
      eventOptions: { passive: false },
      drag: {
        // bounds: {top: (-1 * windowHeight), bottom: windowHeight, left: (-1 * windowWidth), right: windowWidth},
        filterTaps: true,
        // preventDefault: true,
        pointer: {
          touch: true,
        },
      },
    }
  );
  // Called for drag of pinch zoom to lock down all objects
  const lockAllCanvasObjects = () => {
    // const objects = canvas.getObjects();
    if (canvas.current) {
      // eslint-disable-next-line
      const objects: any = canvas.current.getObjects();
      // eslint-disable-next-line
      objects?.forEach((obj: any) => {
        // obj.selectable = false;
        obj.hasControls = false;
        obj.hasBorders = false;
        obj.lockMovementX = true;
        obj.lockMovementY = true;
      });
    }
  };
  // Called for END of drag of pinch zoom to unlock all objects
  const unlockAllCanvasObjects = () => {
    const objects = canvas.current?.getObjects();
    // eslint-disable-next-line
    objects?.forEach((obj: any) => {
      // obj.selectable = true;
      obj.hasControls = true;
      obj.hasBorders = true;
      obj.lockMovementX = false;
      obj.lockMovementY = false;
    });
  };
  // DELETE a single Object
  const handleObjectDelete = () => {
    const activeObject = canvas.current?.getActiveObject();
    if (activeObject) {
      canvas.current?.remove(activeObject);
    }
  };
  // RESET Canvas
  const handleClearCanvas = () => {
    if (canvas.current) {
      canvas.current.remove(...canvas.current.getObjects());
    }
  };

  const canvasResetView = () => {
    canvas.current?.setViewportTransform([1, 0, 0, 1, 0, 0]);
  };

  const useFabric = (ref: Ref<Canvas | unknown> | undefined) => {
    const canvas = useRef<Canvas>();

    useImperativeHandle(ref, () => {
      return canvas.current;
    });

    const fabricRef = useCallback((element: HTMLCanvasElement | null) => {
      if (!element) {
        return canvas.current?.dispose();
      }
      canvas.current = new fabric.Canvas(element, { backgroundColor: '#eeeeee' });
      let containerHeight = 0;
      let containerWidth = 0;
      // check to see if we have the container clientHeight and clientWidth exist
      // If we DO NOT have the container ref then we will use the entire window width and height
      // and adjust those by width * .9 and height * .8
      if (container) {
        // if it does then we have the width and height of the parent container, so
        // we can keep the width but need to adjust the height by *.8
        containerHeight = (container as HTMLElement).clientHeight * 0.8;
        containerWidth = (container as HTMLElement).clientWidth;
      } else {
        containerHeight = window.screen.height * 0.8;
        containerWidth = window.screen.width * 0.9;
      }
      canvas.current.setWidth(containerWidth);
      canvas.current.setHeight(containerHeight);
      // box that will overlay the canvas
      const loadingJSONBox = new fabric.Rect({
        height: containerHeight,
        width: containerWidth,
        fill: '#ffffff', // pink
      });
      // text that will say loading objects
      const loadingJSONTextObj = new fabric.Text('Loading...', {
        fontSize: 24,
        left: containerWidth / 2 - 100,
        top: containerHeight / 2,
      });
      // grouping both objects to add to canvas
      const group = new fabric.Group([loadingJSONBox, loadingJSONTextObj], {
        left: 0,
        top: 0,
        height: containerHeight,
        width: containerWidth,
        selectable: false,
      });
      canvas.current?.add(group);
      canvas.current?.renderAll();
      fabric.Image.fromURL(
        bgImgURL,
        async function (img) {
          const width = img.width ? img.width : 400;
          const height = img.height ? img.height : 600;

          const scalex = containerWidth / width;
          const scaley = containerHeight / height;
          // setting the bounds
          canvas.current?.setBackgroundImage(img, canvas.current?.renderAll.bind(canvas.current), {
            scaleX: scalex,
            scaleY: scaley,
          });
          if (json.length > 0) {
            // How do we get the new screen size of the canvas?
            // newWidth / originalWidth
            const widthScale = containerWidth / json[0].width;
            const heightScale = containerHeight / json[0].height;
            const res: canvasJSON | null = await fetchCanvasJSON(json[0].jsonURL);
            if (res) {
              // now time to add all the objects
              fabric.util.enlivenObjects(
                res.objects,
                (objs: (Path | Group | Image)[]) => {
                  objs.forEach((item: Path | Group | Image) => {
                    canvas.current?.add(item);
                    item.left = item.left ? item.left * widthScale : 0;
                    item.top = item.top ? item.top * heightScale : 0;

                    item.scaleX = item.scaleX ? item.scaleX * widthScale : 0;
                    item.scaleY = heightScale;
                    item.setCoords();
                  });
                  // canvas.current?.renderAll();
                },
                '',
                () => {
                  return;
                }
              );
              // now we remove the group object with the loading message
              canvas.current?.remove(group);
              canvas.current?.requestRenderAll();
            } else {
              toast.error('Failed to load previous JSON. Refresh to ensure no overwrite occurs.');
            }
          } else {
            canvas.current?.remove(group);
            canvas.current?.requestRenderAll();
          }
        },
        { crossOrigin: 'anonymous' }
      );
    }, []);
    return fabricRef;
  };

  /* eslint-disable react/display-name */
  const MyFabric = forwardRef((props, ref: Ref<Canvas | unknown> | undefined) => {
    const fabricRef = useFabric(ref);
    return <canvas ref={fabricRef} />;
  });

  return (
    <Box position={'relative'} display={'flex'} flexDirection={'column'} flexWrap={'wrap'} bgcolor={'#ffffff'}>
      <Grid
        container
        height={'8vh'}
        justifyContent={'space-around'}
        bgcolor={'#ffffff'}
        borderBottom={'1px solid #000'}
      >
        <Grid item xs={2} position={'relative'}>
          <LabelSelect labels={labels} updateParent={updateSelectedLabel} />
          <Box position={'absolute'} top={'102%'} zIndex={200} bgcolor={'#ffffff'}>
            <Typography fontSize={24}>{siteName}</Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Tools
            locationId={locationId}
            siteId={siteId}
            deleteObj={handleObjectDelete}
            refresh={handleClearCanvas}
            resetView={canvasResetView}
            canvas={canvas}
            lockAllItems={lockAllCanvasObjects}
          />
        </Grid>
        <Grid item xs={5}>
          <Pencils colors={COLORS} drawMode={enterDrawMode} endDrawMode={exitDrawMode} setPenSize={adjustPenSize} />
        </Grid>
      </Grid>
      <Box
        width={'100%'}
        height={'70vh'}
        ref={canvasContainerRef}
        {...bind()}
        display={'grid'}
        sx={{ placeItems: 'center' }}
      >
        {/* <canvas ref={canvasRef}/> */}
        <MyFabric ref={canvas} />
      </Box>
      <Box display={'flex'} width={'100%'} height={'10vh'} bgcolor={'#ffffff'} borderTop={'1px solid #000'}>
        {icons?.map((icon) => {
          return (
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flex={1} key={icon.id}>
              <Button onClick={() => handleButtonClick(icon)} sx={{ padding: 1 }}>
                <img src={icon.bucketURL} height={'100%'} width={'100%'} />
              </Button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default FabricCanvas;
