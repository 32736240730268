import { Add, Delete } from '@mui/icons-material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import { Box, Button, List, ListItem, Stack, TextField, Typography, useTheme } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';

type Props = {
  updateNextStep: () => void;
};

const PaletteCreation = ({ updateNextStep }: Props) => {
  const theme = useTheme();
  const darkblue = theme.palette.primary.main;

  const [paletteName, setPaletteName] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [currentInputColorValue, setCurrentInputColorValue] = useState<string>('#fff');
  const [newColorPaletteColors, setNewColorPaletteColors] = useState<{ [key: string]: boolean }>({});

  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');
  const [colorPalettes, setColorPalettes] = useLocalStorageState('colorPalettes', {
    defaultValue: [{ title: 'Basic Colors', colors: ['#C70039', '#0000FF', '#00FF00'] }],
  });

  const handleColorInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentInputColorValue(e.target.value);
  };

  const handleColorAdd = () => {
    const newColors = { ...newColorPaletteColors };
    newColors[currentInputColorValue] = true;
    setNewColorPaletteColors(newColors);
    checkIfCanAdvance();
  };

  const removeColor = (color: string) => {
    const _newPaletteColors = { ...newColorPaletteColors };
    delete _newPaletteColors[color];
    setNewColorPaletteColors(_newPaletteColors);
    checkIfCanAdvance();
  };

  const handleNextClick = () => {
    if (tutorialStep) {
      // updating the color palettes
      const _newColorPalettes = [...colorPalettes];
      const _newColorPalette = {
        title: paletteName,
        colors: Object.keys(newColorPaletteColors),
      };
      _newColorPalettes.push(_newColorPalette);
      setColorPalettes(_newColorPalettes);
      updateNextStep();
      setTutorialStep(tutorialStep + 1);
    } else setTutorialStep(1);
  };

  const checkIfCanAdvance = () => {
    if (paletteName.length > 3 && Object.keys(newColorPaletteColors).length > 0) {
      setIsButtonDisabled(false);
    }
  };

  return (
    <Box
      display={{ xs: 'flex' }}
      flexDirection={'column'}
      bgcolor={darkblue}
      height={'100%'}
      minWidth={{ xs: '100%', lg: '75%' }}
      maxWidth={{ lg: '80%' }}
      alignItems={'center'}
      p={2}
      pt={4}
      borderRadius={2}
    >
      <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'center'} mb={2}>
        <LooksOneIcon fontSize='large' color={'info'} />
        <Typography fontSize={'2rem'} color={'#fff'} width={'100%'} pl={1}>
          Palette Creation
        </Typography>
      </Stack>
      <Box width={'100%'} height={'12vh'} display={'flex'} flexDirection={'column'} pt={{ xs: 2, md: 0 }}>
        <TextField
          sx={{
            backgroundColor: '#fff',
            color: darkblue,
          }}
          placeholder='Palette Name'
          value={paletteName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setPaletteName(e.target.value)}
        />
        <Stack
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={1}
          mt={1}
          mb={1}
          pt={1}
          pb={1}
        >
          <input
            type='color'
            value={currentInputColorValue}
            onChange={handleColorInputChange}
            style={{
              border: 'none',
              padding: 0,
              outline: 'none',
              backgroundColor: currentInputColorValue,
            }}
          />
          <Button variant='contained' color='info' onClick={handleColorAdd}>
            <Add htmlColor={darkblue} fontSize='small' />
          </Button>
        </Stack>
      </Box>
      <Box
        width={{ xs: '100%' }}
        height={'50vh'}
        sx={{
          '&::-webkit-scrollbar': { display: 'none' },
          overflow: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {Object.keys(newColorPaletteColors).length > 0 ? (
          <List
            style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'row', padding: 0 }}
            sx={{ height: '100%' }}
          >
            {Object.keys(newColorPaletteColors).map((color, index: number) => {
              return (
                <ListItem
                  key={`color-${color}-${index}`}
                  sx={{
                    padding: 0,
                    m: 0,
                    flex: 1,
                    height: '100%',
                    backgroundColor: color,
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'center',
                  }}
                >
                  <Stack
                    direction={'row'}
                    height={'5vh'}
                    width={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    spacing={1}
                  >
                    <Typography
                      fontSize={'1.8rem'}
                      textAlign={'center'}
                      color={'#fff'}
                      fontWeight={'bold'}
                      textTransform={'uppercase'}
                    >
                      {color}
                    </Typography>
                    <Button variant='outlined' color={'info'} onClick={() => removeColor(color)}>
                      <Delete htmlColor='red' />
                    </Button>
                  </Stack>
                </ListItem>
              );
            })}
          </List>
        ) : (
          ''
        )}
      </Box>
      <Box mt={2} display={'flex'} justifyContent={'center'}>
        <Button disabled={isButtonDisabled} variant='contained' color={'info'} onClick={handleNextClick}>
          <Typography p={1} fontWeight={'bold'}>
            Next: Logo Upload
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default PaletteCreation;
