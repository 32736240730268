import { Button, Stack, Typography, useTheme } from '@mui/material';
import useLocalStorageState from 'use-local-storage-state';

type Props = {
  updateNextStep: () => void;
};

const Welcome = ({ updateNextStep }: Props) => {
  const theme = useTheme();
  const darkblue = theme.palette.primary.main;

  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');

  const handleNextClick = () => {
    updateNextStep();
    if (tutorialStep) {
      setTutorialStep(tutorialStep + 1);
    } else setTutorialStep(1);
  };

  return (
    <Stack
      bgcolor={darkblue}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      p={2}
      pt={4}
      borderRadius={2}
      spacing={4}
      minWidth={{ md: 952, lg: 1100 }}
      boxShadow={'1px 1px 12px #000'}
    >
      <Typography fontSize={'2rem'} textAlign={'center'} color={'#fff'}>
        Welcome!
      </Typography>
      <Typography fontSize={'2rem'} textAlign={'center'} color={'#fff'}>
        Thank you for choosing
      </Typography>
      <Typography fontSize={'3rem'} textAlign={'center'} color={'#fff'}>
        Creating Margin Digital Signage CMS
      </Typography>
      <Typography fontSize={'2rem'} textAlign={'center'} color={'#fff'}>
        Let&apos;s get started with the initial setup.
      </Typography>
      <Button variant='contained' color={'info'} onClick={handleNextClick}>
        <Typography p={1} fontWeight={'bold'}>
          Let&apos;s Begin
        </Typography>
      </Button>
    </Stack>
  );
};

export default Welcome;
