import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Layout from './components/layout';
import AccessTokenPage from './pages/access-tokens-page/AccessTokenPage';
import AddClientPage from './pages/add-client-page/AddClientPage';
import AddUserPage from './pages/add-user-page/AddUserPage';
import DrawingCanvasPage from './pages/canvas-drawing/DrawingCanvasPage';
import ClientsPage from './pages/clients-page/ClientsPage';
import ContentCreationPage from './pages/content-creation-page/ContentCreationPage';
import DashboardPage from './pages/dashboard-page/DashboardPage';
import AddAssetPage from './pages/digital-signage-cms-pages/add-media-page/AddAssetPage';
import AddMediaPage from './pages/digital-signage-cms-pages/add-media-page/AddMediaPage';
import AddPlaylistsPage from './pages/digital-signage-cms-pages/add-playlist-page/AddPlaylistsPage';
import EditPlaylistPage from './pages/digital-signage-cms-pages/edit-playlist-page/EditPlaylistPage';
import MediaPage from './pages/digital-signage-cms-pages/media-page/MediaPage';
import PlaylistPage from './pages/digital-signage-cms-pages/playlist/PlaylistPage';
import PresentationsPage from './pages/digital-signage-cms-pages/presentations-page/PresentationsPage';
import LaneManagementPage from './pages/lane-management-page/LaneManagementPage';
import LocationsPage from './pages/locations-page/LocationsPage';
import LoggingPage from './pages/logging-page/LoggingPage';
import LoginPage from './pages/login-page/LoginPage';
import LogoutPage from './pages/logout-page/LogoutPage';
import MapWhiteboardPage from './pages/map-whiteboard-page/MapWhiteboardPage';
import MenuPage from './pages/menu-cms-pages/menu-page/MenuPage';
import NewAccessTokenPage from './pages/new-access-token-page/NewAccessTokenPage';
import NotFoundPage from './pages/not-found-page/NotFoundPage';
import PlayerManagementPage from './pages/player-management-page/PlayerManagementPage';
import ProfilePage from './pages/profile-page/ProfilePage';
import SingleClientPage from './pages/single-client-page/SingleClientPage';
import SingleUserPage from './pages/single-user-page/SingleUserPage';
import TutorialPage from './pages/tutorial-page/TutorialPage';
import UpdatePasswordPage from './pages/update-password-page/UpdatePasswordPage';
import UsersPage from './pages/users-page/UsersPage';
import WeatherTablePage from './pages/weather-table-page/WeatherTablePage';
import WeatherWidgetPage from './pages/weather-widget-page/WeatherWidgetPage';
import { Roles } from './types';
import RequiredLocalStoreValue from './utils/nonempty-localstorage-value';
import ProtectedRoute from './utils/protected-route';
import TutorialRoute from './utils/tutorial-route';
const App = () => {
  const allRoles = [
    Roles.ADMIN,
    Roles.USERS,
    Roles.LANE_MANAGEMENT,
    Roles.WEATHER_WIDGET,
    Roles.CONTENT_CREATION,
    Roles.DIGITAL_SIGNAGE_CMS,
    Roles.MENU_CMS,
    Roles.MAP_WHITEBOARD_CMS,
  ];

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route path='/login' element={<LoginPage />} />
        <Route path='/logout' element={<LogoutPage />} />
        {/* <Route path='/drawing/:siteId' element={<CanvasDrawingPage />}/> */}
        {/* PROTECTED ROUTES*/}
        <Route path='/' element={<ProtectedRoute allowedRoles={allRoles} />}>
          <Route path='/' element={<Layout />}>
            <Route path='/' element={<DashboardPage />} />
            <Route path='/dashboard' element={<DashboardPage />} />
            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
              <Route path='/player-management' element={<PlayerManagementPage />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
              <Route path='/logging' element={<LoggingPage />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.LANE_MANAGEMENT]} />}>
              <Route path='/lane-management' element={<LaneManagementPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.USERS]} />}>
              <Route path='/users' element={<UsersPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[Roles.USERS, Roles.ADMIN]} />}>
              <Route path='/users/:id' element={<SingleUserPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[Roles.USERS, Roles.ADMIN]} />}>
              <Route path='/users/add' element={<AddUserPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
              <Route path='/clients' element={<ClientsPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
              <Route path='/clients/:id' element={<SingleClientPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
              <Route path='/clients/add' element={<AddClientPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={allRoles} />}>
              <Route path='/update-password' element={<UpdatePasswordPage />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={allRoles} />}>
              <Route path='/profile' element={<ProfilePage />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.WEATHER_WIDGET]} />}>
              <Route path='/weather-widget' element={<WeatherWidgetPage />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
              <Route path='/weather-table' element={<WeatherTablePage />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
              <Route path='/access-tokens' element={<AccessTokenPage />} />
            </Route>
            <Route element={<ProtectedRoute allowedRoles={[Roles.ADMIN]} />}>
              <Route path='/access-tokens/new' element={<NewAccessTokenPage />} />
            </Route>
            {/* Digital Signage */}
            <Route
              path='/digital-signage'
              element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.DIGITAL_SIGNAGE_CMS]} />}
            >
              <Route element={<TutorialRoute minReqStep={6} />}>
                <Route path='playlist' element={<PlaylistPage />} />
                <Route path='playlist/create' element={<AddPlaylistsPage />} />
                <Route path='playlist/edit/:id' element={<EditPlaylistPage />} />
                <Route path='media' element={<MediaPage />} />
                <Route path='media/add' element={<AddMediaPage />} />
                <Route path='media/asset/add' element={<AddAssetPage />} />
              </Route>
              <Route element={<TutorialRoute minReqStep={7} />}>
                <Route path='presentations' element={<PresentationsPage />} />
              </Route>
            </Route>
            {/* Content Creation */}
            <Route
              element={
                <ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.DIGITAL_SIGNAGE_CMS, Roles.CONTENT_CREATION]} />
              }
            >
              <Route element={<TutorialRoute minReqStep={4} />}>
                <Route path='/content-creation' element={<ContentCreationPage />} />
              </Route>
            </Route>
            {/* MENU ROUTES */}
            <Route path='/locations' element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.MENU_CMS]} />}>
              <Route path='' element={<LocationsPage />} />
            </Route>
            <Route path='/menus' element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.MENU_CMS]} />}>
              <Route path='' element={<MenuPage />} />
            </Route>
            {/* Map Whiteboard */}
            <Route
              path='/map-whiteboard'
              element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.MAP_WHITEBOARD_CMS]} />}
            >
              <Route path='' element={<MapWhiteboardPage />} />
            </Route>
            {/* Map Whiteboard Drawing */}
            <Route path='/drawing' element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.MAP_WHITEBOARD_CMS]} />}>
              <Route element={<RequiredLocalStoreValue storeageKey='draw_site' />}>
                <Route path='' element={<DrawingCanvasPage />} />
              </Route>
            </Route>
            {/* TUTORIAL ROUTES */}
            <Route
              path='/tutorial'
              element={<ProtectedRoute allowedRoles={[Roles.ADMIN, Roles.DIGITAL_SIGNAGE_CMS]} />}
            >
              <Route path='' element={<TutorialPage />} />
            </Route>
          </Route>
        </Route>
        {/* CATCH ALL ROUTE*/}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
