import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { API_AUTH_TOKEN } from '../../../../api/auth-token';
import { API_CLIENT } from '../../../../api/client';
import Loader from '../../../common/loader/Loader';

const AuthKeyDialog = ({ handleClose }: { handleClose: () => void }) => {
  const { error, data } = useQuery(['GetAllClients', '/client'], API_CLIENT.getAll);
  const [clientOptions, setClientOptions] = useState<string[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [serviceRole, setServiceRole] = useState<string>('');
  const APPS = ['Content-Management', 'Whiteboard-Management'];

  const handleServiceSelectChange = (event: SelectChangeEvent) => {
    setServiceRole(event.target.value as string);
  };

  const handleClientSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedClients(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleCreateClick = async () => {
    const formattedData = {
      service: serviceRole,
      clientNames: selectedClients,
    };

    const authGenerated: boolean | { success: string[]; failed: string[] } = await API_AUTH_TOKEN.generateTokens(
      formattedData
    );
    if (authGenerated && typeof authGenerated == 'object') {
      const { success, failed } = authGenerated;
      if (success.length > 0) {
        toast.success(`Successfully created Auth Keys for: ${success.toString()}`);
      }
      if (failed.length > 0) {
        toast.error(`Failed to created Auth Keys for: ${success.toString()}`);
      }
      handleClose();
    } else {
      toast.error('Failed to create any Auth Keys.');
    }
  };

  useEffect(() => {
    if (data) {
      const clientNames = data.map((client) => {
        return client.name;
      });
      setClientOptions(clientNames);
    }
  }, [data]);

  return (
    <Dialog open onClose={handleClose} fullWidth>
      <DialogTitle>
        <Typography fontWeight={'bold'} textAlign={'center'}>
          Create Auth Key(s)
        </Typography>
      </DialogTitle>
      <DialogContent>
        {error ? (
          <Typography>Error Fetching clients</Typography>
        ) : data ? (
          <Box width={'100%'}>
            <Box pt={2}>
              <FormControl fullWidth>
                <InputLabel id='service-select'>Service</InputLabel>
                <Select
                  labelId='service-select'
                  id='service-app-select'
                  value={serviceRole}
                  label='Service Role'
                  onChange={handleServiceSelectChange}
                >
                  {APPS.map((app, index) => {
                    return (
                      <MenuItem value={app} key={`service-${index}`}>
                        {app.toLocaleLowerCase().replaceAll('_', ' ')}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box pt={2}>
              <FormControl fullWidth>
                <InputLabel id='client-select'>Client</InputLabel>
                <Select
                  labelId='client-select'
                  multiple
                  value={selectedClients}
                  renderValue={(selected) => selected.join(', ')}
                  label='Client'
                  onChange={handleClientSelectChange}
                >
                  {clientOptions.map((client: string, index: number) => {
                    return (
                      <MenuItem value={client} key={`client-${index}`}>
                        {client}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Box>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          <Button variant={'contained'} onClick={handleCreateClick}>
            Create Auth Key(s)
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AuthKeyDialog;
