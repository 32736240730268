const getNumberOfDaysBetweenNowAndFutureDate = (futureDate: Date): number => {
  const currentDate = new Date().getTime();
  const date = new Date(futureDate).getTime();

  const timeTillFutureDate = date - currentDate;
  const daysTillFutureDate = Math.floor(timeTillFutureDate / (1000 * 60 * 60 * 24));

  return daysTillFutureDate;
};

export default getNumberOfDaysBetweenNowAndFutureDate;
