import { Delete, Lock, LockOpen, Publish, Refresh, Save } from '@mui/icons-material';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import LayersIcon from '@mui/icons-material/Layers';
import { Box, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Group, Image, Path } from 'fabric/fabric-impl';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { API_WHITEBOARD_APP_DRAWING } from '../../../../api/whiteboard/drawing';
import ConfirmDialog from '../../../common/ConfirmDialog';

type Props = {
  // eslint-disable-next-line
  canvas: any;
  locationId: string;
  siteId: string;
  lockAllItems: () => void;
  resetView: () => void;
  deleteObj: () => void;
  refresh: () => void;
};

const Tools = ({ locationId, siteId, canvas, resetView, deleteObj, refresh, lockAllItems }: Props) => {
  const [currentSelectedObj, setCurrentSelectedObj] = useState<Group | Path | Image | null>(null);
  const [layersInView, setLayersInView] = useState<boolean>(false);
  const [selectedObject, setSelectedObject] = useState<boolean>(false);
  const [itemLocked, setItemLocked] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [publishConfirmation, setPublishConfirmation] = useState<boolean>(false);
  const [clearCanvasConfirmation, setClearCanvasConfirmation] = useState<boolean>(false);
  // function for handling the layers click to show the list
  // of locked objects
  const handleLayerClick = () => {
    setLayersInView(!layersInView);
  };

  // function to lock the selected Object
  const handleLockClick = () => {
    const activeObject = canvas.current?.getActiveObject();
    // item lock
    if (activeObject) {
      activeObject.set({
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
        lockScalingFlip: true,
        lockScalingY: true,
        lockScalingX: true,
        lockSkewingY: true,
        lockSkewingX: true,
      });
    }
    // canvas.current?.discardActiveObject()
    setItemLocked(true);
    setCurrentSelectedObj(null);
  };
  // function to unlock
  const handleUnlockObj = () => {
    if (currentSelectedObj) {
      currentSelectedObj.selectable = true;
      currentSelectedObj.lockMovementX = false;
      currentSelectedObj.lockMovementY = false;
      currentSelectedObj.lockRotation = false;
      currentSelectedObj.lockScalingFlip = false;
      currentSelectedObj.lockScalingY = false;
      currentSelectedObj.lockScalingX = false;
      currentSelectedObj.lockSkewingX = false;
      currentSelectedObj.lockSkewingY = false;
      currentSelectedObj.borderScaleFactor = 0;
      setItemLocked(false);
      setCurrentSelectedObj(null);
    }
  };
  // the function that highlights the object
  // to possibly unlock
  const handleObjUnlockClick = (obj: Group | Path | Image) => {
    obj.hasBorders = true;
    obj.borderColor = '#800080';
    obj.borderScaleFactor = 4;
    canvas.current.setActiveObject(obj);
    canvas.current.requestRenderAll();
    setCurrentSelectedObj(obj);
    setItemLocked(true);
  };
  // this function will return all the objects that are locked
  const getAndColorLockedObj = () => {
    const allLockedObjs = canvas.current?.getObjects().filter((obj: Group | Path | Image) => {
      return obj.selectable == false;
    });
    if (allLockedObjs.length > 0) {
      const allBtns = allLockedObjs.map((nonSelectableObj: Group | Path | Image, index: number) => {
        return (
          <Button
            key={index}
            sx={{ height: '100%', width: 50, padding: 0 }}
            onClick={() => {
              handleObjUnlockClick(nonSelectableObj);
            }}
          >
            {'path' in nonSelectableObj ? (
              <Box height={'100%'} width={'100%'} bgcolor={nonSelectableObj.stroke}></Box>
            ) : (
              // eslint-disable-next-line
              <img src={(nonSelectableObj as any)._objects[1]._element.currentSrc} height={'100%'} width={'100%'} />
            )}
          </Button>
        );
      });
      return allBtns;
    } else return <Typography>No Locked Items</Typography>;
  };

  // the function when the 'clear all' is clicked
  const clearCanvasClick = (res: boolean) => {
    if (res) {
      setLayersInView(false);
      setItemLocked(false);
      setSelectedObject(false);
      setCurrentSelectedObj(null);
      refresh();
    }
    setClearCanvasConfirmation(false);
  };

  // function for saving the canvas
  const handleSaveClick = async () => {
    if (canvas.current) {
      lockAllItems();
      setIsSaving(true);
      // updateStep(0)
      canvas.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
      canvas.current.renderAll();
      const postData = {
        json: canvas.current.toJSON(),
        width: parseInt(canvas.current.getWidth()),
        height: parseInt(canvas.current.getHeight()),
        siteLocationId: siteId,
        multiplier: 8,
      };
      try {
        await API_WHITEBOARD_APP_DRAWING.saveWBMapDrawingJSON({ locationId, jsonData: postData });
        setIsSaving(false);
        toast.success('Successfully Saved!');
      } catch {
        toast.error('Failed to save. Please try again');
        setIsSaving(false);
      }
    } else {
      alert('No Canvas found. Please refresh and redraw');
    }
  };

  // function for publishing the canvas
  const handlePublishClick = async () => {
    setPublishConfirmation(true);
  };

  const submitPublish = async (res: boolean) => {
    setPublishConfirmation(false);
    if (res) {
      if (canvas.current) {
        lockAllItems();
        setIsPublishing(true);
        // updateStep(0)
        canvas.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        canvas.current.renderAll();
        const postData = {
          json: canvas.current.toJSON(),
          width: canvas.current.getWidth(),
          height: canvas.current.getHeight(),
          siteLocationId: siteId,
          multiplier: 8,
        };
        try {
          await API_WHITEBOARD_APP_DRAWING.publishWBMapDrawingJSON({ locationId, jsonData: postData });
          setIsPublishing(false);
          toast.success('Successfully Published!');
        } catch {
          toast.error('Failed to save. Please try again');
          setIsPublishing(false);
        }
      } else {
        alert('No Canvas found. Please refresh and redraw');
      }
    }
  };

  // this will handle adding the events tracking for canvas
  useEffect(() => {
    canvas.current?.on('object:added', function () {
      setItemLocked(false);
      setSelectedObject(true);
    });
    canvas.current?.on('selection:created', function () {
      const activeObject = canvas.current?.getActiveObject();
      if (activeObject) {
        setItemLocked(activeObject.lockMovementX);
        setSelectedObject(true);
      }
    });
    canvas.current?.on('selection:updated', function () {
      const activeObject = canvas.current?.getActiveObject();
      if (activeObject) {
        setItemLocked(activeObject.lockMovementX);
        setSelectedObject(true);
      }
    });
    canvas.current?.on('selection:cleared', function () {
      setSelectedObject(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Box position={'relative'} display={'flex'} alignItems={'center'} justifyContent={'space-evenly'} height={'100%'}>
      {selectedObject ? (
        itemLocked ? (
          <Lock fontSize='large' color='primary' onClick={handleUnlockObj} />
        ) : (
          <Button onClick={handleLockClick}>
            <LockOpen color='primary' fontSize='large' />
          </Button>
        )
      ) : (
        <Button onClick={handleLayerClick}>
          <LayersIcon color='primary' fontSize='large' />
        </Button>
      )}
      <Button onClick={resetView}>
        <CenterFocusStrongIcon htmlColor='orange' fontSize='large' />
      </Button>
      <Button onClick={() => setClearCanvasConfirmation(true)}>
        <Refresh htmlColor='blue' fontSize='large' />
      </Button>
      <Button onClick={deleteObj}>
        <Delete htmlColor='red' fontSize='large' />
      </Button>
      <Button onClick={handleSaveClick}>
        {isSaving ? <CircularProgress color='primary' /> : <Save fontSize='large' />}
      </Button>
      <Button onClick={handlePublishClick}>
        {isPublishing ? <CircularProgress color='primary' /> : <Publish htmlColor='green' fontSize='large' />}
      </Button>
      {layersInView && (
        <Box
          position={'absolute'}
          zIndex={105}
          top={'101%'}
          left={0}
          maxWidth={'70vw'}
          height={'5vh'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          pl={0.5}
          pr={0.5}
          bgcolor={'#ffffff'}
        >
          {getAndColorLockedObj()}
        </Box>
      )}
      {publishConfirmation && (
        <ConfirmDialog
          title={'Please confirm action'}
          message={'Would you like to PUBLISH? Please ensure you have saved first before publishing.'}
          handleClick={submitPublish}
        />
      )}
      {clearCanvasConfirmation && (
        <ConfirmDialog
          title={'Please confirm action'}
          message={'Are you sure you would like to CLEAR the canvas?'}
          handleClick={clearCanvasClick}
        />
      )}
    </Box>
  );
};

export default Tools;
