import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { API_WHITEBOARD_APP_LOCATION } from '../../api/whiteboard/map/location';
import { API_WHITEBOARD_APP_LOCATION_SITES } from '../../api/whiteboard/map/site';
import FabricCanvas from './components/draw-mode/FabricCanvas';

const CanvasDrawing = ({ siteId, siteName }: { siteId: string; siteName: string }) => {
  const containerRef = useRef(null);
  const { data: locationData, isLoading } = useQuery(['clientLocation'], API_WHITEBOARD_APP_LOCATION.getSingleLocation);
  // now we call for the SiteLocation data
  const { data: siteLocationData, isLoading: siteLocationIsLoading } = useQuery(['drawingSiteLocation'], () =>
    API_WHITEBOARD_APP_LOCATION_SITES.getSingleSiteLocation(siteId)
  );

  // we need to fetch the JSON and add the items if needed
  return (
    <Box position={'relative'} height={'88vh'} width={'92vw'} bgcolor={'lightgray'} ref={containerRef}>
      {isLoading || siteLocationIsLoading ? (
        <Typography>Loading Data</Typography>
      ) : locationData && siteLocationData ? (
        <FabricCanvas
          json={siteLocationData.labeledBackgroundImages ? siteLocationData.labeledBackgroundImages : []}
          locationId={locationData.location.id}
          siteId={siteId}
          siteName={siteName}
          icons={locationData.media}
          container={containerRef.current}
          bgImgURL={siteLocationData.backgroundImages[0].bucketURL}
          labels={locationData.labels}
        />
      ) : (
        <Typography>Errors</Typography>
      )}
    </Box>
  );
};

export default CanvasDrawing;
