import { useQuery } from '@tanstack/react-query';
import { API_CLIENT } from '../../api/client';
import AddUser from '../../components/add-user';
import Loader from '../../components/common/loader';
import { useAuthContext } from '../../context/AuthContext';
import { Roles } from '../../types';
import { decrypt } from '../../utils/cryptojs-helper';

const AddUserPage = () => {
  // const { user } = useAuthContext();
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  // pass different url based off if admin or not. If admin get all clients, else get one client
  let url = `client/one/${user.currentClient.client.id}`; // url to get one client
  if (user.currentClient.client.roles.includes(Roles.ADMIN)) {
    url = '/client'; // url to get all clients
  }

  const { error, data } = useQuery(['GetAllClients', url], API_CLIENT.getAll);

  if (error) return <p>error</p>;
  if (data) return <AddUser clientData={data} loggedUser={user} />;
  return <Loader />;
};

export default AddUserPage;
