import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { initAligningGuidelines } from '../../utils/canvas-helper';
import { decrypt } from '../../utils/cryptojs-helper';
import Header from './components/Header';
import ToolsSidebar from './components/ToolsSidebar';
import s from './styles.module.scss';
// import { CanvasObject, CanvasTemplate, CanvasTemplateData} from './types';
import { Box, Grid, Typography } from '@mui/material';
import { CanvasObject } from '../../api/digital-signage-cms/types';
import { UserAuthData } from '../../api/user/types';
import Layers from './components/Layers/Layers';
import FontFaceObserver from 'fontfaceobserver';

type Props = {
  user: UserAuthData;
  presentationData: { width: number; height: number };
};

const ContentCreation = ({ user, presentationData }: Props) => {
  const { width, height } = presentationData;
  // const [templates] = useLocalStorageState<string>('templates');
  const [tutorialStep] = useLocalStorageState<number>('tutorial-step');
  const [tutorialTemplates] = useLocalStorageState<string>('tutorialTemplate');

  const screenHeight = window.innerHeight * 0.8; // * .85
  const screenWidth = window.innerWidth * 0.8; // * .85

  const dimensions = `${width}x${height}`;
  const canvasWidth = width;
  const canvasHeight = height;

  const canvasWrpRef = useRef<HTMLDivElement>(null);
  const canvas = useRef<Canvas>();

  const resetFocus = () => {
    canvas.current?.discardActiveObject().renderAll();
    canvas.current?.fire('focus:reset');
  };

  const handleDownload = (fileName: string) => {
    const zoom = canvas.current?.getZoom() || 0.68;
    const multi = ((canvasHeight * canvasWidth) / (canvasHeight * zoom * (canvasWidth * zoom))) * zoom;

    const url =
      canvas.current?.toDataURL({
        format: 'png',
        multiplier: multi,
      }) || '';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);

    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const useFabric = (ref: Ref<Canvas | unknown> | undefined) => {
    const screenArea = screenHeight * screenWidth;
    const canvasArea = canvasHeight * canvasWidth;
    let scale = 0;
    if (canvasArea > screenArea) scale = screenArea / canvasArea;
    else if (canvasArea < screenArea) scale = canvasArea / screenArea;

    if (scale > 0.7) scale = 0.68;

    const canvas = useRef<Canvas>();

    useImperativeHandle(ref, () => {
      return canvas.current;
    });

    const fabricRef = useCallback((element: HTMLCanvasElement | null) => {
      if (!element) return canvas.current?.dispose();
      canvas.current = new fabric.Canvas(element, { backgroundColor: '#eeeeee' });
      initAligningGuidelines(canvas.current);
      canvas.current.setZoom(scale);
      canvas.current.setHeight(canvasHeight * canvas.current.getZoom());
      canvas.current.setWidth(canvasWidth * canvas.current.getZoom());
      // creating a square that will be the background
      const rect = new fabric.Rect({
        height: canvasHeight,
        width: canvasWidth,
        fill: '#eeeeee',
        selectable: false,
      });
      canvas.current?.add(rect);
      canvas.current.renderAll();
      // eslint-disable-next-line
    }, []);
    return fabricRef;
  };
  /* eslint-disable react/display-name */
  const MyFabric = forwardRef((props, ref: Ref<Canvas | unknown> | undefined) => {
    const fabricRef = useFabric(ref);
    return <canvas ref={fabricRef} className={s.canvas} />;
  });

  // functionality for deleting
  const handleEventKeyPress = (event: KeyboardEvent) => {
    // check to see if there is another active input focused. for now the font-size input
    // is what we need to ignore
    if (document.activeElement && document.activeElement.id === 'font-size-input') return;
    if (event.key === 'Backspace' || event.code === '8' || event.key === 'Delete' || event.code === '46') {
      // eslint-disable-next-line
      const activeObject: any = canvas.current?.getActiveObject();
      // delete all Objects but textbox type
      if (activeObject && !activeObject.isType('textbox')) {
        canvas?.current?.remove(activeObject);
        canvas?.current?.renderAll();
      } // delete textbox if it is currently not being edited
      else if (activeObject && activeObject.isType('textbox') && !activeObject.isEditing) {
        canvas?.current?.remove(activeObject);
        canvas?.current?.renderAll();
      }
    }
  };
  // GRABBED FROM TemplateModule
  const transformObjects = (objects: CanvasObject[], scale: number) => {
    const transformedObjects: CanvasObject[] = [];
    objects.map((obj: CanvasObject) => {
      if (obj && obj.scaleX && obj.scaleY && scale > 1) {
        obj.scaleX = obj.scaleX * (scale * 0.75);
        obj.scaleY = obj.scaleY * (scale * 0.75);
      } else if (obj && obj.scaleX && obj.scaleY) {
        obj.scaleX = obj.scaleX * (scale * 1.5);
        obj.scaleY = obj.scaleY * (scale * 1.5);
      }
      transformedObjects.push(obj);
    });
    return transformedObjects;
  };

  // adding an event listeren to key down pressed
  useEffect(() => {
    // Check to see if we are currently on the template step
    if (tutorialStep === 5 && tutorialTemplates) {
      const decryptedTemplates: string = decrypt(tutorialTemplates);
      const templateObject = JSON.parse(decryptedTemplates);
      const JSONData = templateObject[0].data;
      if (JSONData) {
        const ogHeight = JSONData.height ? JSONData.height : 1080;
        const ogWidth = JSONData.width ? JSONData.width : 1920;

        const ogArea = ogHeight * ogWidth;
        const newArea = height * width;

        const scale = newArea / ogArea;

        const loadJSON = {
          background: JSONData.background,
          objects: scale == 1 ? JSONData.objects : transformObjects(JSONData.objects, scale),
          version: JSONData.version,
        };
        canvas.current?.clear();
        canvas.current?.loadFromJSON(loadJSON, function () {
          canvas.current?.renderAll();
        });
      }
    }
    const fonts = [
      { name: 'Open Sans', class: 'open-sans' },
      { name: 'EB Garamond', class: 'eb-garamond' },
      { name: 'Jost', class: 'jost' },
      { name: 'Inter', class: 'inter' },
      { name: 'Bodoni Moda', class: 'bodoni-moda' },
      { name: 'Martian Mono', class: 'martian-mono' },
      { name: 'Gothic A1', class: 'gothic-a1' },
      { name: 'Source Serif 4', class: 'source-serif-4' },
      { name: 'Arvo', class: 'arvo' },
      { name: 'Libre Franklin', class: 'libre-franklin' },
      { name: 'Roboto', class: 'roboto' },
      { name: 'La Belle Aurore', class: 'la-belle-aurore' },
    ];
    for (let i = 0; i < fonts.length; i++) {
      const myfont = new FontFaceObserver(fonts[i].name);
      myfont.load();
    }
    // keep below
    document.addEventListener('keydown', handleEventKeyPress);
    return function cleanup() {
      document.removeEventListener('keydown', handleEventKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid container position={'relative'} height={'88vh'} width={'100%'}>
        <Grid item xs={1} height={'100%'}>
          <ToolsSidebar canvas={canvas} canvasHeight={canvasHeight} canvasWidth={canvasWidth} />
        </Grid>
        <Grid item xs={9.5} height={'100%'}>
          <Grid container height={'100%'} display={'grid'} gridTemplateRows={'10% 90%'}>
            <Grid item xs={12}>
              <Header
                canvas={canvas}
                handleDownload={handleDownload}
                canvasHeight={canvasHeight}
                canvasWidth={canvasWidth}
                user={user}
              />
            </Grid>
            <Grid item xs={12} height={'100%'} bgcolor={'#171719'}>
              <Box
                ref={canvasWrpRef}
                height={'100%'}
                position={'relative'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <div className={s.canvasWrpBackground} onClick={() => resetFocus()}></div>
                <MyFabric ref={canvas} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1.5} height={'100%'}>
          <Layers canvas={canvas} />
        </Grid>
        <Box position={'absolute'} bottom={'1%'} right={'12vw'}>
          <Typography color={'#fff'}>{dimensions}</Typography>
        </Box>
      </Grid>
    </>
    // </div>
  );
};

export default ContentCreation;
