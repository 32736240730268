import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Grid, List, ListItem, Stack, Tooltip, Typography } from '@mui/material';
import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import checkIfEmptyString from '../../../../utils/check-if-empty';
import { decrypt, encrypt } from '../../../../utils/cryptojs-helper';
// import { CanvasObject, CanvasTemplate, CanvasTemplateData } from '../../types';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { API_DS_TEMPLATES } from '../../../../api/digital-signage-cms/templates';
import {
  CanvasJSON,
  CanvasObject,
  CanvasTemplateData,
  LocalSavedTemplate,
} from '../../../../api/digital-signage-cms/types';
import { useAuthContext } from '../../../../context/AuthContext';
import AlertDialog from '../common/AlertDialog';
import TemplateNameTextfield from '../common/TemplateNameTextfield';
import s from '../styles/template.module.scss';
import ConfirmDialog from '../../../common/ConfirmDialog';

// more info icon messages
const MORE_INFO = {
  myTemplates:
    'These are templates saved on your local browser. Clearing cookies on your browser will delete these templates.',
  sharedTemplates: 'These are templates shared amongst you and your co-workers',
  cloudTemplates: 'These are cloud templates available to everyone. They are managed by Creating Margin.',
};
const TemplateModule = ({
  canvas,
  handleClose,
  height,
  width,
  bgColorUpdate,
}: {
  canvas: MutableRefObject<Canvas | undefined>;
  handleClose: () => void;
  height: number;
  width: number;
  bgColorUpdate: Dispatch<SetStateAction<string>>;
}) => {
  // make sure no objects are selected, so when they type and delete a character
  // it wont delete any objects, since there is an event listener for the 'delete' btn
  canvas.current?.discardActiveObject();
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  const [alertInView, setAlertInView] = useState<boolean>(false);
  const [confirmationInView, setConfirmationInView] = useState<boolean>(false);
  // const [templateName, setTemplateName] = useState<string>('');
  // eslint-disable-next-line
  const templateNameRef = useRef<any>(null);
  const [templateSelected, setTemplateSelected] = useState<number>(-1);
  const [view, setView] = useState<string>('local');
  const defaultTemplateStr = JSON.stringify([
    {
      name: 'default',
      img: '',
      data: { version: '5.2.4', height: 1080, width: 1920, background: '#eee', objects: [] },
    },
  ]);
  const encryptedTemplateStr = encrypt(defaultTemplateStr);
  const [templates, setTemplates] = useLocalStorageState<string>('templates', {
    defaultValue: encryptedTemplateStr,
  });
  // cloud templates
  const { data: cloudTemplates } = useQuery(['AllCloudDSTemplates'], API_DS_TEMPLATES.fetchCloudTemplates);
  // const [cloudTemplates, setCloudTemplates] = useState<{id: string, name: string, previewURL: string}[]>([])
  // const { data: sharedTemplates } = useQuery(['SharedCloudDSTemplates'], API_DS_TEMPLATES.fetchCloudTemplates);
  // const sharedTemplates = [];
  const { data: sharedTemplates, refetch: refetchSharedTemplates } = useQuery(['AllSharedDSTemplates'], () =>
    API_DS_TEMPLATES.fetchSharedTemplates(user.currentClient.client.id)
  );
  const handleSave = () => {
    if (templateNameRef.current && canvas.current) {
      const templateName = templateNameRef.current.getValue();
      if (checkIfEmptyString(templateName)) {
        toast.error('Please give this template a name.');
        return;
      }
      // eslint-disable-next-line
      const canvasJSON: CanvasJSON | undefined = canvas.current?.toJSON();
      if (canvasJSON) {
        const canvasIMG = canvas.current?.toDataURL();
        if (canvasIMG) {
          let backgroundColor = '';
          if (typeof canvas.current.backgroundColor === 'string') {
            backgroundColor = canvas.current.backgroundColor;
          } else {
            backgroundColor = '#ffffff';
          }
          // adding original dimensions
          const finalData: CanvasTemplateData = {
            ...canvasJSON,
            ['height']: height,
            ['width']: width,
            background: backgroundColor,
          };
          const newData: LocalSavedTemplate = { name: templateName, img: canvasIMG, data: finalData };
          const decryptedTemplates: string = decrypt(templates);
          const templatesObject = JSON.parse(decryptedTemplates);
          const newArrayData: LocalSavedTemplate[] = [...templatesObject, newData];
          const encryptedNewTemplates = encrypt(JSON.stringify(newArrayData));
          setTemplates(encryptedNewTemplates);
          toast.success('Template successfully saved locally!');
          // handleClose();
        }
      }
    }
  };

  // uploading a template to the cloud
  const uploadTemplateToCloud = async () => {
    // eslint-disable-next-line
    const canvasJSON: CanvasJSON | undefined = canvas.current?.toJSON();
    if (canvasJSON && templateNameRef.current) {
      const templateName = templateNameRef.current.getValue();
      if (checkIfEmptyString(templateName)) {
        toast.error('Please give this template a name.');
        return;
      }
      const canvasIMG = canvas.current?.toDataURL();
      if (canvasIMG) {
        if (canvas.current) {
          let backgroundColor = '';
          if (typeof canvas.current.backgroundColor === 'string') {
            backgroundColor = canvas.current.backgroundColor;
          } else {
            backgroundColor = '#ffffff';
          }
          // adding original dimensions
          const finalData: CanvasTemplateData = {
            ...canvasJSON,
            ['height']: height,
            ['width']: width,
            background: backgroundColor,
          };
          const newData: LocalSavedTemplate = { name: templateName, img: canvasIMG, data: finalData };
          // Attempting to update to clodu tempaltes
          const success = await API_DS_TEMPLATES.uploadTemplateToCloud(newData);
          if (success) {
            toast.success('Successfully created a cloud template!');
          } else {
            toast.error('Failed to upload template to the cloud. Please try again.');
          }
        } else {
          toast.error('Canvas does not exist.');
        }
      } else {
        toast.error('Canvas failed to create an image from the canvas.');
      }
    } else {
      toast.error('Canvas failed to create JSON. Please try again.');
    }
  };
  // uploading a shared template
  const uploadSharedTemplate = async () => {
    // check to make sure there is a Canvas that exist
    if (canvas.current && templateNameRef.current) {
      const templateName = templateNameRef.current.getValue();
      if (checkIfEmptyString(templateName)) {
        toast.error('Please give this template a name.');
        return;
      }
      // eslint-disable-next-line
      const canvasJSON: CanvasJSON | undefined = canvas.current?.toJSON();
      if (canvasJSON) {
        // creating the image data
        const canvasIMG = canvas.current?.toDataURL();
        if (canvasIMG) {
          // grabbing the background of the canvas. if non exist
          // then give white background as default
          let backgroundColor = '';
          if (typeof canvas.current.backgroundColor === 'string') {
            backgroundColor = canvas.current.backgroundColor;
          } else {
            backgroundColor = '#ffffff';
          }
          // creating the CanvasTemplateData which will be all the data for the canvas
          // adding the JSON objects, original dimensions, and background color
          const finalData: CanvasTemplateData = {
            ...canvasJSON,
            ['height']: height,
            ['width']: width,
            background: backgroundColor,
          };
          const newData: LocalSavedTemplate = { name: templateName, img: canvasIMG, data: finalData };
          // Attempting to update to cloud tempaltes
          // we also need to send the clientId this User is currently viewing
          const success = await API_DS_TEMPLATES.uploadSharedTemplateToCloud(newData, user.currentClient);
          if (success) {
            refetchSharedTemplates();
            toast.success('Successfully created a cloud template!');
          } else {
            toast.error('Failed to upload template to the cloud. Please try again.');
          }
        } else {
          toast.error('Canvas failed to create an image from the canvas.');
        }
      } else {
        toast.error('Canvas failed to create JSON. Please try again.');
      }
    } else {
      toast.error('Error! Canvas does not exist. Please try again.');
    }
  };

  const handleAlertResponse = (response: boolean) => {
    if (response) grabTemplateData();
    setAlertInView(!alertInView);
  };

  const transformObjects = (objects: CanvasObject[], scale: number) => {
    const transformedObjects: CanvasObject[] = [];
    objects.map((obj: CanvasObject) => {
      if (obj && obj.scaleX && obj.scaleY && scale > 1) {
        obj.scaleX = obj.scaleX * (scale * 0.75);
        obj.scaleY = obj.scaleY * (scale * 0.75);
      } else if (obj && obj.scaleX && obj.scaleY) {
        obj.scaleX = obj.scaleX * (scale * 1.5);
        obj.scaleY = obj.scaleY * (scale * 1.5);
      }
      transformedObjects.push(obj);
    });
    return transformedObjects;
  };

  const handleTemplateLoad = () => {
    grabTemplateData();
  };

  const loadTemplateToCanvas = (JSONData: CanvasTemplateData) => {
    if (JSONData) {
      const ogHeight = JSONData.height ? JSONData.height : 1080;
      const ogWidth = JSONData.width ? JSONData.width : 1920;

      const ogArea = ogHeight * ogWidth;
      const newArea = height * width;

      const scale = newArea / ogArea;
      const _objects = [...JSONData.objects];
      if (_objects && _objects[0]) {
        _objects[0]['selectable'] = false;
      }
      const loadJSON = {
        background: JSONData.background,
        objects: scale == 1 ? _objects : transformObjects(JSONData.objects, scale),
        version: JSONData.version,
      };
      bgColorUpdate(JSONData.background);
      canvas.current?.clear();
      canvas.current?.renderAll();
      canvas.current?.loadFromJSON(loadJSON, function () {
        // grab all the Text objects and lock the scaling option
        const allTexObjs = canvas.current
          ?.getObjects()
          .filter((obj) => obj instanceof fabric.Text || obj instanceof fabric.IText || obj instanceof fabric.Textbox);
        if (allTexObjs) {
          for (let i = 0; i < allTexObjs?.length; i++) {
            // eslint-disable-next-line
            const obj: any = allTexObjs[i];
            obj.setControlsVisibility({
              mt: false, // Middle top
              mb: false, // Middle bottom
              ml: true, // Middle left
              mr: true, // Middle right
              tl: false, // Top left
              tr: false, // Top right
              bl: false, // Bottom left
              br: false, // Bottom right
            });
            obj.set('fontFamily', obj.fontFamily);
          }
        }
        canvas.current?.renderAll();
      });
      handleClose();
    }
  };

  // grabbing the Canvas Template information for both
  // local and cloud templates
  const grabTemplateData = async () => {
    if (templateSelected !== -1) {
      // check to see what template is in view
      if (view === 'local') {
        const decryptedTemplates: string = decrypt(templates);
        const templateObject: LocalSavedTemplate[] = JSON.parse(decryptedTemplates);
        // we need to check from where to get the the JSON Data
        const JSONData: CanvasTemplateData = templateObject[templateSelected].data;
        loadTemplateToCanvas(JSONData);
      } else if (view === 'shared') {
        if (typeof sharedTemplates === 'object' && sharedTemplates) {
          // check to see which template is selected
          // then we need to fetch that templates JSON
          const templateJSON: CanvasTemplateData | boolean = await API_DS_TEMPLATES.fetchSharedTemplateJSON(
            sharedTemplates[templateSelected].id
          );
          if (templateJSON && typeof templateJSON === 'object') {
            // templateJSON?.objects.shift();// remove the first object which is the background
            loadTemplateToCanvas(templateJSON);
          } else {
            toast.error('Failed to fetch the cloud template data.');
          }
          // then we need to load the template
        }
      } else {
        if (typeof cloudTemplates === 'object' && cloudTemplates) {
          // check to see which template is selected
          // then we need to fetch that templates JSON
          const templateJSON: CanvasTemplateData | boolean = await API_DS_TEMPLATES.fetchCloudTemplateJSON(
            cloudTemplates[templateSelected].id
          );
          if (templateJSON && typeof templateJSON === 'object') {
            // templateJSON?.objects.shift();// remove the first object which is the background
            loadTemplateToCanvas(templateJSON);
          } else {
            toast.error('Failed to fetch the cloud template data.');
          }
        }
      }
    }
  };

  const handleDeleteClick = (res: boolean) => {
    if (res) handleTemplateDelete();
    setConfirmationInView(false);
  };

  const handleTemplateDelete = async () => {
    if (view === 'local') {
      const decryptedTemplates: string = decrypt(templates);
      const templateObject: LocalSavedTemplate[] = JSON.parse(decryptedTemplates);
      const newTemplates = templateObject.filter((_, index: number) => {
        return index !== templateSelected;
      });

      const newTemplatesStr = JSON.stringify(newTemplates);
      const encryptedTemplates = encrypt(newTemplatesStr);
      setTemplates(encryptedTemplates);
      return;
    }
    if (view === 'shared') {
      const success = await API_DS_TEMPLATES.deleteSharedTemplate(sharedTemplates[templateSelected].id);
      if (success) refetchSharedTemplates();
      return;
    }
    if (view === 'cloud') {
      if (typeof cloudTemplates === 'object' && cloudTemplates) {
        await API_DS_TEMPLATES.deleteCloudTemplate(cloudTemplates[templateSelected].id);
        return;
      }
    }
  };

  const loadSharedTemplate = async () => {
    setTemplateSelected(-1);
    setView('shared');
  };

  const loadCloudTemplates = async () => {
    setTemplateSelected(-1);
    setView('cloud');
  };
  return (
    <Box position={'absolute'} zIndex={12} height={'100%'} width={'100%'} top={0} bgcolor={'#2a2c31'}>
      <Grid container height={'100%'}>
        <Grid item xs={4} height={'100%'}>
          <Grid container height={'100%'} p={2}>
            <Grid item xs={12} height={'15%'}>
              <Stack direction={'column'} height={'100%'}>
                <Grid container height={'50%'}>
                  <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <Typography fontSize={{ md: 16 }} color={'#fff'} textAlign={'center'} fontWeight={'bold'}>
                      Templates
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container height={'60%'} justifyContent={'space-between'}>
                  <Grid item xs={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                      onClick={() => {
                        setView('local');
                        setTemplateSelected(-1);
                      }}
                      sx={{ padding: 0, height: '100%', width: '80%' }}
                    >
                      <Typography color={view !== 'local' ? '#fff' : '#1785C4'} fontSize={{ md: 11 }}>
                        Mine
                      </Typography>
                    </Button>
                    <Tooltip title={MORE_INFO['myTemplates']}>
                      <InfoIcon htmlColor='lightgray' fontSize='small' />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button onClick={loadSharedTemplate} sx={{ padding: 0, height: '100%', width: '80%' }}>
                      <Typography color={view !== 'shared' ? '#fff' : '#1785C4'} fontSize={{ md: 11 }}>
                        Shared
                      </Typography>
                    </Button>
                    <Tooltip title={MORE_INFO['sharedTemplates']}>
                      <InfoIcon htmlColor='lightgray' fontSize='small' />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button onClick={loadCloudTemplates} sx={{ padding: 0, height: '100%', width: '80%' }}>
                      <Typography color={view !== 'cloud' ? '#fff' : '#1785C4'} fontSize={{ md: 11 }}>
                        Cloud
                      </Typography>
                    </Button>
                    <Tooltip title={MORE_INFO['cloudTemplates']}>
                      <InfoIcon htmlColor='lightgray' fontSize='small' />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} height={'80%'}>
              <List
                className='hide-scrollbars'
                // className={s.templatesList}
                sx={{ overflow: 'scroll', height: '100%' }}
              >
                {view === 'local' ? (
                  JSON.parse(decrypt(templates)).length > 1 ? (
                    JSON.parse(decrypt(templates))
                      .slice(1)
                      .map((template: LocalSavedTemplate, index: number) => {
                        return (
                          <ListItem
                            onClick={() => setTemplateSelected(templateSelected == index + 1 ? -1 : index + 1)}
                            key={`${template.name}-${index}`}
                          >
                            <Grid
                              style={{ border: index + 1 == templateSelected ? '2px solid #ffffff' : 'none' }}
                              height={'20vh'}
                              width={'75%'}
                              ml={'12.5%'}
                              p={1}
                            >
                              <Grid item xs={12} height={'20%'}>
                                <Typography textAlign={'center'} color={'#fff'}>
                                  {template.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} height={'80%'} display={'flex'} justifyContent={'center'}>
                                <img src={template.img} height={'100%'}></img>
                              </Grid>
                            </Grid>
                          </ListItem>
                        );
                      })
                  ) : (
                    <ListItem>
                      <Typography fontWeight={'bold'} color={'#fff'} textAlign={'center'} width={'100%'}>
                        You have no saved templates
                      </Typography>
                    </ListItem>
                  )
                ) : view === 'shared' ? (
                  sharedTemplates && typeof sharedTemplates === 'object' && sharedTemplates.length > 0 ? (
                    sharedTemplates.map((template: { id: string; name: string; previewURL: string }, index: number) => {
                      return (
                        <ListItem
                          onClick={() => setTemplateSelected(templateSelected == index ? -1 : index)}
                          key={`${template.name}-${index}`}
                        >
                          <Grid
                            style={{ border: index == templateSelected ? '2px solid #ffffff' : 'none' }}
                            height={'20vh'}
                            width={'75%'}
                            ml={'12.5%'}
                            p={1}
                          >
                            <Grid item xs={12} height={'20%'}>
                              <Typography textAlign={'center'} color={'#fff'}>
                                {template.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} height={'80%'} display={'flex'} justifyContent={'center'}>
                              <img
                                src={`${process.env.REACT_APP_BUCKET_URL}/${template.previewURL}`}
                                height={'100%'}
                              ></img>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    })
                  ) : (
                    <ListItem>
                      <Typography textAlign={'center'} fontWeight={'bold'} color={'#fff'} width={'100%'}>
                        There are no Shared Templates
                      </Typography>
                    </ListItem>
                  )
                ) : cloudTemplates && typeof cloudTemplates === 'object' && cloudTemplates.length > 0 ? (
                  cloudTemplates.map((template, index) => {
                    return (
                      <ListItem
                        onClick={() => setTemplateSelected(templateSelected == index ? -1 : index)}
                        key={`${template.name}-${index}`}
                      >
                        <Grid
                          style={{ border: index == templateSelected ? '2px solid #ffffff' : 'none' }}
                          height={'20vh'}
                          width={'75%'}
                          ml={'12.5%'}
                          p={1}
                        >
                          <Grid item xs={12} height={'20%'}>
                            <Typography textAlign={'center'} color={'#fff'}>
                              {template.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} height={'80%'} display={'flex'} justifyContent={'center'}>
                            <img
                              src={`${process.env.REACT_APP_BUCKET_URL}/${template.previewURL}`}
                              height={'100%'}
                            ></img>
                          </Grid>
                        </Grid>
                      </ListItem>
                    );
                  })
                ) : (
                  <ListItem>
                    <Typography textAlign={'center'} fontWeight={'bold'} color={'#fff'} width={'100%'}>
                      No Cloud templates
                    </Typography>
                  </ListItem>
                )}
              </List>
            </Grid>
            <Grid item xs={12} height={'5%'} display={'flex'} justifyContent={'center'} gap={1}>
              <Button
                disabled={templates.length > 1 ? false : true}
                onClick={handleTemplateLoad}
                sx={{ backgroundColor: '#fff', width: '30%' }}
              >
                LOAD
              </Button>
              {templateSelected > -1 ? (
                view == 'local' || view == 'shared' ? (
                  <Button
                    onClick={() => setConfirmationInView(true)}
                    variant='contained'
                    color={'error'}
                    sx={{ width: '30%' }}
                  >
                    <Typography>DELETE</Typography>
                  </Button>
                ) : view == 'cloud' && user.currentClient.client.roles.includes(4000) ? (
                  <Button
                    onClick={() => setConfirmationInView(true)}
                    variant='contained'
                    color={'error'}
                    sx={{ width: '30%' }}
                  >
                    <Typography>DELETE</Typography>
                  </Button>
                ) : null
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} height={'100%'} borderLeft={'2px solid #fff'}>
          <Box height={'100%'} p={2} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <TemplateNameTextfield ref={templateNameRef} />
            <img crossOrigin='anonymous' src={canvas.current?.toDataURL()} alt='' style={{ marginBottom: '2vh' }} />
            <Stack direction={'row'} justifyContent={'center'} gap={2}>
              <Button onClick={handleSave} variant='contained'>
                <Typography color={'#fff'}>SAVE LOCAL</Typography>
              </Button>
              <Button onClick={uploadSharedTemplate} variant='contained'>
                <Typography color={'#fff'}>SAVE SHARED</Typography>
              </Button>
              {user.clients[0].client.roles.includes(4000) && (
                <Button onClick={uploadTemplateToCloud} variant='contained'>
                  <Typography color={'#fff'}>CLOUD</Typography>
                </Button>
              )}
              <Button className={s.cancelBtn} onClick={handleClose} variant='contained'>
                <Typography color={'#fff'}>CANCEL</Typography>
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      {alertInView && (
        <AlertDialog
          open={alertInView}
          handleClose={handleAlertResponse}
          title={'Dimensions Notice'}
          contentText={
            'The template you are trying to load was created for a different sized canvas. You can still load all the parts, it just might need some adjustments.'
          }
          confirmBtnText={'Load It Up'}
          rejectBtnText={'Cancel'}
        />
      )}
      {confirmationInView && (
        <ConfirmDialog
          title='Please confirm this action'
          message='Are you sure you want to delete this template?'
          handleClick={handleDeleteClick}
        />
      )}
    </Box>
  );
};

export default TemplateModule;
