import toast from 'react-hot-toast';
import axios from '../../../config/axiosV2';
import { MediaGetData, MediaInPlaylist } from '../types';

/**
 * This function will fetch the Media for the logged in client
 * with their currently selected Client.
 * @param clientId - the current selected client
 * @returns list of all Media for client
 */
const getAllMedia = async (clientId: string): Promise<MediaGetData[] | undefined> => {
  // the second item of array of queryKey contains the url passed to function
  try {
    const url = '/digitalsignage/media/all';
    const { data } = await axios.get(url, {
      headers: {
        ccid: clientId,
      },
    });
    const { media } = data;
    return media;
  } catch (err) {
    toast.error('Error Getting Media');
  }
};

/**
 * This function is called when a user wants to save the image they have
 * created in Content Creation.
 * @param clientName - current client name
 * @param clientId - current client Id
 * @param form - a form data containing all the data of the image
 * @returns boolean for success of failure
 */
const save = async (clientName: string, clientId: string, form: FormData): Promise<boolean | undefined> => {
  const url = '/digitalsignage/media/save';
  try {
    await axios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ccid: clientId,
        ccn: clientName,
      },
    });
    return true;
  } catch (err) {
    toast.error('Error Saving Media');
  }
};

const saveToPlaylist = async (
  clientName: string,
  clientId: string,
  form: FormData
): Promise<boolean | { [key: string]: MediaInPlaylist } | undefined> => {
  const url = '/digitalsignage/media/playlist/save';
  try {
    const { data } = await axios.post(url, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ccid: clientId,
        ccn: clientName,
      },
    });
    return data.mediaInAllPlaylists;
  } catch (err) {
    toast.error('Error Getting Media');
  }
};

const deleteMedia = async (id: string) => {
  try {
    const url = `/digitalsignage/media/delete/${id}`;
    await axios.post(url);
    toast.success('Successfully deleted Media');
    return true;
  } catch (err) {
    toast.error('Error deleting Media');
    return false;
  }
};

export { deleteMedia, getAllMedia, save, saveToPlaylist };
