import { Dispatch, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
// import { IUser } from '../../context/AuthContext';
import { encrypt } from '../../utils/cryptojs-helper';
import { UserGetData, UserLoginFormData, UserRegisterData, UserUpdateData, UserUpdatePasswordData } from './types';

// gets all users if admin and gets users by specific client if not admin
const getAll = async ({ queryKey }: { queryKey: [string, string] }): Promise<UserGetData[] | undefined> => {
  // the second item of array of queryKey contains the url passed to function
  const url = queryKey[1];

  try {
    const { data } = await axios.get<UserGetData[]>(url);
    return data;
  } catch (err) {
    toast.error('Error Getting Users');
  }
};

const getSingle = async ({ queryKey }: { queryKey: [string, string] }): Promise<UserGetData | undefined> => {
  // the second item of array of queryKey contains the id passed to function
  const id = queryKey[1];

  try {
    const { data } = await axios.get<UserGetData>(`/user/one/${id}`);
    return data;
  } catch (err) {
    toast.error('Error Getting User Data');
  }
};

const register = async (data: UserRegisterData): Promise<boolean> => {
  try {
    await axios.post('/auth/register', { ...data });
    toast.success('User Created Successfully');
    return true;
  } catch (error) {
    toast.error('Failed to create User. Please Try Again');
    return false;
  }
};

const login = async (
  formData: UserLoginFormData,
  // setUser: Dispatch<SetStateAction<IUser>>,
  setUserNew: Dispatch<SetStateAction<string>>,
  setLoading: Dispatch<SetStateAction<boolean>>
): Promise<boolean> => {
  setLoading(true);
  try {
    const { data } = await axios.post('/auth/login', {
      email: formData.email,
      password: formData.password,
    });
    // data.user.currentClient = data.user.clients[0];

    // EXPERIMENTAL
    // eslint-disable-next-line
    data.user.clients.forEach((cc: any) => {
      const roleCodes: number[] = [];
      cc.client.roles.forEach((role: { role: { code: number } }) => {
        roleCodes.push(role.role.code);
      });
      cc.client.roles = roleCodes;
    });
    data.user.currentClient = data.user.clients[0];
    // BELOW WORKS
    // iterate through list of client roles and transform to an array of role codes
    // const roleCodes: number[] = [];
    // data.user.clients[0].client.roles.forEach((role: { role: { code: number } }) => {
    //   roleCodes.push(role.role.code);
    // });

    // data.user.currentClient.client.roles = roleCodes;
    const encryptedUser = encrypt(JSON.stringify(data.user));
    // const d = CryptoJS.AES.encrypt(data.user.toString(), `${process.env.REACT_APP_ENCRYPT_KEY}`); // Encryption Part
    // const decrypted = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);
    setUserNew(encryptedUser);
    // setUser(data.user);
    setLoading(false);
    return true;
  } catch (error) {
    toast.error('Invalid Credentials');
    setLoading(false);
    return false;
  }
};

const update = async (data: UserUpdateData, id: string): Promise<boolean> => {
  try {
    await axios.put(`/user/update/${id}`, { ...data });
    toast.success('User Updated Successfully');
    return true;
  } catch (error) {
    toast.error('Error Updating User. Please Try Again');
    return false;
  }
};

const remove = async (id: string): Promise<boolean> => {
  try {
    await axios.delete(`/user/delete/${id}`);
    toast.success('User Deleted Successfully');
    return true;
  } catch (error) {
    toast.error('Error Please Try Again');
    return false;
  }
};

const updatePassword = async (id: string, data: UserUpdatePasswordData) => {
  try {
    await axios.put(`/user/update/${id}`, { ...data });
    toast.success('Password Updated Successfully');
    return true;
  } catch (error) {
    toast.error('Error Please Try Again');
    return false;
  }
};

export { getAll, getSingle, login, register, remove, update, updatePassword };
