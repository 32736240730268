import LooksThreeIcon from '@mui/icons-material/Looks3';
import { Box, Button, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { API_DS_TEMPLATES } from '../../../../api/digital-signage-cms/templates';
import { CanvasTemplateData } from '../../../../api/digital-signage-cms/types';
import { encrypt } from '../../../../utils/cryptojs-helper';
import Loader from '../../../common/loader/Loader';
// import { CanvasTemplateData } from '../../../content-creation/types';

const TemplateSelection = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { error, data: themesList } = useQuery(['AllCloudDSTemplates'], API_DS_TEMPLATES.fetchCloudTemplates);
  const darkblue = theme.palette.primary.main;
  const [selectedTemplate, setSelectedTemplate] = useState<number>(-1);
  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');
  // eslint-disable-next-line
  const [tutorialTemplates, setTutorialTemplates] = useLocalStorageState<string>('tutorialTemplate');

  const handleTemplateSelectedClick = (index: number) => {
    if (index === selectedTemplate) {
      setSelectedTemplate(-1);
    } else {
      setSelectedTemplate(index);
    }
  };

  const handleDesignClick = async () => {
    // fetch the JSON data from the API to save as a template
    if (themesList && typeof themesList === 'object') {
      const selectedTheme = themesList[selectedTemplate];
      const canvasJSON: CanvasTemplateData | boolean = await API_DS_TEMPLATES.fetchCloudTemplateJSON(selectedTheme.id);
      // console.log(templates)
      if (canvasJSON && typeof canvasJSON === 'object') {
        const canvasIMG = selectedTheme.previewURL;
        const finalData = { ...canvasJSON, ['height']: 1080, ['width']: 1920 };
        const newData = { name: selectedTheme.name, img: canvasIMG, data: finalData };
        // const decryptedTemplates: string = decrypt(templates);
        // const templatesObject = JSON.parse(decryptedTemplates);
        // console.log(templatesObject)
        const newArrayData = [newData];
        const encryptedNewTemplates = encrypt(JSON.stringify(newArrayData));
        setTutorialTemplates(encryptedNewTemplates);
        if (tutorialStep) {
          setTutorialStep(tutorialStep + 1);
          navigate('/content-creation');
        }
      }
    } else {
      alert('No Themes downloaded please contact support.');
    }
  };

  return (
    <Stack bgcolor={darkblue} height={'100%'} alignItems={'center'} p={2} pt={4} borderRadius={2} spacing={4}>
      <Stack width={'100%'} direction={'row'} alignItems={'center'} justifyContent={'center'}>
        <LooksThreeIcon fontSize='medium' color={'info'} />
        <Typography color={'#fff'} width={'100%'} pl={1}>
          Template Selection
        </Typography>
      </Stack>
      <Typography color={'#fff'}>Let&apos;s get you started with the design by picking a template</Typography>
      <Box
        maxHeight={{ xs: '60vh', md: '80vh' }}
        overflow={'scroll'}
        width={'100%'}
        sx={{
          '&::-webkit-scrollbar': { display: 'none' },
          overflow: 'scroll',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        {error ? (
          <Typography>There was an error loading Templates from cloud</Typography>
        ) : themesList && typeof themesList !== 'boolean' ? (
          <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {themesList.map((template: { id: string; name: string; previewURL: string }, index: number) => {
              return (
                <ListItem key={template.id}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    p={1}
                    height={'15vh'}
                    width={'75%'}
                    ml={'12.5%'}
                    onClick={() => handleTemplateSelectedClick(index)}
                    sx={{
                      '&:hover': { cursor: 'pointer' },
                      border: index === selectedTemplate ? '2px solid #fff' : 'none',
                    }}
                  >
                    <Typography textAlign={'center'} color={'#fff'}>
                      {template.name}
                    </Typography>
                    <img src={`${process.env.REACT_APP_BUCKET_URL}/${template.previewURL}`} alt='' height={'80%'} />
                  </Box>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Loader />
        )}
      </Box>
      <Button
        onClick={handleDesignClick}
        disabled={selectedTemplate > -1 ? false : true}
        variant='contained'
        color={'info'}
      >
        Next: Design Time
      </Button>
    </Stack>
  );
};

export default TemplateSelection;
