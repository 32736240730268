import { Button, Card } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import BugReportIcon from '@mui/icons-material/BugReport';
import EmailIcon from '@mui/icons-material/Email';
import Header from '../common/header';

const Support = () => {
  return (
    <Card style={{ height: 400 }}>
      <Header text='Support' Icon={<ContactSupportIcon />} />
      <a href='mailto:managedservices@creatingmargin.com' style={{ textDecoration: 'none', marginBottom: 10 }}>
        <Button variant='contained' size='large' endIcon={<EmailIcon />}>
          Contact Us
        </Button>
      </a>
      <a href='mailto:managedservices@creatingmargin.com?subject=Curio Cloud Bug' style={{ textDecoration: 'none' }}>
        <Button color='error' variant='contained' size='large' endIcon={<BugReportIcon />}>
          Report Bug
        </Button>
      </a>
    </Card>
  );
};

export default Support;
