import { Box, List, ListItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const SelectFont = ({
  options,
  handleSelect,
  selected,
}: {
  options: { name: string; class: string }[];
  handleSelect: (e: string) => void;
  selected?: number;
}) => {
  const [fontFamiliesSelectInView, setFontFamilesSelectInView] = useState<boolean>(false);
  const [currentlySelected, setCurrentlySelected] = useState<number>(selected || 0);

  const handleOptionClick = (name: string, index: number) => {
    handleSelect(name);
    setCurrentlySelected(index);
  };

  useEffect(() => {
    if (selected && selected > -1) {
      setCurrentlySelected(selected);
    } else {
      setCurrentlySelected(0);
    }
  }, [selected]);
  return (
    <Box
      position={'relative'}
      display={'flex'}
      alignItems={'center'}
      bgcolor={'#fff'}
      pl={1}
      border={'none'}
      width={'90%'}
      height={'80%'}
      borderRadius={0}
      onClick={() => setFontFamilesSelectInView(!fontFamiliesSelectInView)}
    >
      <Typography fontSize={{ md: 12, lg: 18 }} style={{ fontFamily: options[currentlySelected].name }}>
        {options[currentlySelected].name}
      </Typography>
      {fontFamiliesSelectInView && (
        <Box
          position={'absolute'}
          top={'100%'}
          left={0}
          width={'100%'}
          maxHeight={'40vh'}
          overflow={'scroll'}
          paddingTop={{ md: '10px' }}
          bgcolor={'#fff'}
          className={'hide-scrollbars'}
        >
          <List>
            {options.map((option: { name: string; class: string }, index: number) => {
              return (
                <ListItem
                  style={{ fontFamily: option.name }}
                  key={option.name}
                  onClick={() => handleOptionClick(option.name, index)}
                >
                  <Typography fontSize={{ md: 12, lg: 18 }} fontFamily={option.name}>
                    {option.name}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default SelectFont;
