import CryptoJS from 'crypto-js';

const encrypt = (data: string) => {
  const d = CryptoJS.AES.encrypt(data, `${process.env.REACT_APP_ENCRYPT_KEY}`).toString(); // Encryption Part
  return d;
};

const decrypt = (data: string) => {
  const decrypted = CryptoJS.AES.decrypt(data, `${process.env.REACT_APP_ENCRYPT_KEY}`).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export { encrypt, decrypt };
