import ContentCreation from '../../components/content-creation';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';

const ContentCreationPage = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  return <ContentCreation user={user} presentationData={{ width: 1920, height: 1080 }} />;
};

export default ContentCreationPage;
