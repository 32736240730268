import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { API_DS_MEDIA } from '../../../../api/digital-signage-cms/media';
import { MediaGetData, MediaInPlaylist } from '../../../../api/digital-signage-cms/types';
import { useAuthContext } from '../../../../context/AuthContext';
import { decrypt } from '../../../../utils/cryptojs-helper';
import Loader from '../../../common/loader/Loader';

const EditMediaDialog = ({
  playlistId,
  playlistSlides,
  updateSelectedMedia,
  handleClose,
}: {
  playlistId: string;
  // eslint-disable-next-line
  playlistSlides: MediaInPlaylist[];
  updateSelectedMedia: (selectedMedia: MediaInPlaylist[]) => void;
  handleClose: () => void;
}) => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const userJSON = JSON.parse(decrypted);
  const { currentClient } = userJSON;
  const theme = useTheme();
  // const url = `/media/get/slides/${clientId}`; // url for get all media for client
  const { error, data: mediaData } = useQuery(['allClientMedia'], () =>
    API_DS_MEDIA.getAllMedia(currentClient.client.id)
  );

  const [selectedMedia, setSelectedMedia] = useState<string[]>([]);
  const [mediaList, setMediaList] = useState<MediaGetData[]>([]);
  // when component  is first loaded. we need to know
  // if there are previously selected media
  useEffect(() => {
    // if previously selected media. then we need to convert it to the
    // local selectedMedia state
    if (playlistSlides && playlistSlides.length > 0) {
      const _selectedMedia = playlistSlides.map((media) => {
        return media.media ? media.media.name : '';
      });
      setSelectedMedia(_selectedMedia);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mediaData) {
      const _mediaList = mediaData.filter((m) => {
        return m.type == 'media';
      });
      setMediaList(_mediaList);
    }
  }, [mediaData]);

  const handleMediaCheck = (mediaName: string) => {
    if (selectedMedia.length == 0) setSelectedMedia([mediaName]);
    else {
      const mediaIndex = selectedMedia.findIndex((i) => i == mediaName);
      if (mediaIndex == -1) setSelectedMedia([...selectedMedia, mediaName]);
      else {
        const newSelectedModalImages = selectedMedia.filter((media) => media != mediaName);
        setSelectedMedia(newSelectedModalImages);
      }
    }
  };

  const handleSelectedMediaUpdate = () => {
    // iterate through the selected media and create their media object
    const formattedSelectedData: MediaInPlaylist[] = [];
    selectedMedia.forEach((media, index) => {
      // finding the media info
      if (mediaList) {
        const mediaIndex = mediaList?.findIndex((m) => m.name == media);
        if (mediaIndex !== -1) {
          formattedSelectedData.push({
            created_at: new Date().toISOString(),
            duration: 6,
            mediaId: mediaList[mediaIndex].id,
            mediaPositionInPlaylist: index,
            playlistId: playlistId,
            updatedAt: new Date().toISOString(),
            validityStartDate: '',
            validityEndDate: '',
            media: {
              previewBucketURL: mediaList[mediaIndex].previewBucketURL,
              bucketURL: mediaList[mediaIndex].bucketURL,
              id: mediaList[mediaIndex].id,
              name: mediaList[mediaIndex].name,
            },
          });
        }
      }
    });
    // eslint-disable-next-line
    // const updatedSlides: any = selectedMedia.map((slide) => {
    //   const i = slideData?.findIndex((s) => s.name == slide);
    //   // returns 0 if found in first position. but JS considers 0 as undefined
    //   if (typeof i === 'number' && i > -1 && slideData) {
    //     const newSlide = {
    //       created_at: Date(),
    //       duration: 6,
    //       media: { bucket_url: slideData[i].bucket_url, id: slideData[i].id, name: slideData[i].name },
    //     };
    //     return newSlide;
    //   }
    // });
    // if (updatedSlides != undefined) updatePlaylists(updatedSlides);
    updateSelectedMedia(formattedSelectedData);
    handleClose();
  };

  if (error) return <p>error</p>;
  return (
    <Dialog open={true} fullWidth>
      <DialogTitle sx={{ textAlign: 'center', color: '#fff', backgroundColor: theme.palette.primary.main }}>
        List of Media
      </DialogTitle>
      {mediaList ? (
        <>
          <DialogContent style={{ padding: 0 }}>
            <Box display={'flex'} flexDirection={'column'} gap={2} pt={2}>
              {mediaList.map((media) => {
                return (
                  <Grid container key={`${media.name}-media`} alignItems={'center'} spacing={1}>
                    <Grid item xs={2}>
                      <Checkbox
                        checked={selectedMedia.indexOf(media.name) > -1 ? true : false}
                        onChange={() => handleMediaCheck(media.name)}
                      />
                    </Grid>
                    <Grid item xs={4} maxWidth={'100%'} maxHeight={'100%'}>
                      <img src={media.previewBucketURL} alt={media.name} width={'100%'} height={'100%'} />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography noWrap overflow={'hidden'} textOverflow={'ellipsis'}>
                        {media.name}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' color='error' onClick={handleClose}>
              Cancel
            </Button>

            <Button variant='contained' color='primary' onClick={handleSelectedMediaUpdate}>
              Select Images
            </Button>
          </DialogActions>
        </>
      ) : (
        <Loader />
      )}
    </Dialog>
  );
};

export default EditMediaDialog;
