import AxiosBase from 'axios';

const axios = AxiosBase.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_V2,
  withCredentials: true,
});

// attaches to every axios request and if gets back 401 status logs user out
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/logout';
    }
    throw Error;
  }
);

export default axios;
