import { fabric } from 'fabric';
import { Canvas } from 'fabric/fabric-impl';
import { MutableRefObject } from 'react';
import s from '../styles/shapes.module.scss';
import Circle from '../svgs/Circle';
import Square from '../svgs/Square';
import Triangle from '../svgs/Triangle';

const ShapesModule = ({ canvas }: { canvas: MutableRefObject<Canvas | undefined> }) => {
  const handleShapeClick = (shape: string) => {
    if (shape === 'square') {
      const rect = new fabric.Rect({
        height: 280,
        width: 200,
        fill: '#FF6961',
      });
      canvas.current?.add(rect);
      canvas.current?.setActiveObject(rect);
      canvas.current?.renderAll();
    } else if (shape === 'circle') {
      const circle = new fabric.Circle({
        radius: 50,
        height: 100,
        width: 100,
        fill: '#FF6961',
      });
      canvas.current?.add(circle);
      canvas.current?.setActiveObject(circle);
      canvas.current?.renderAll();
    } else if (shape === 'triangle') {
      const triangle = new fabric.Triangle({
        height: 280,
        width: 200,
        fill: '#FF6961',
      });
      canvas.current?.add(triangle);
      canvas.current?.setActiveObject(triangle);
      canvas.current?.renderAll();
    }
  };

  return (
    <div className={s.container}>
      <ul>
        <li onClick={() => handleShapeClick('square')}>
          <Square color={'#fff'} />
          <p>Square</p>
        </li>
        <li onClick={() => handleShapeClick('circle')}>
          <Circle color={'#fff'} />
          <p>Circle</p>
        </li>
        <li onClick={() => handleShapeClick('triangle')}>
          <Triangle color={'#fff'} />
          <p>Triangle</p>
        </li>
      </ul>
    </div>
  );
};

export default ShapesModule;
