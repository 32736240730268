import { Canvas } from 'fabric/fabric-impl';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import Loader from '../../../common/loader';
// import { CanvasObject } from '../../types';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { CanvasObject } from '../../../../api/digital-signage-cms/types';
import MiniCanvas from './components/MiniCanvas';

const Layers = ({ canvas }: { canvas: MutableRefObject<Canvas | undefined> }) => {
  const [shapes, setShapes] = useState<CanvasObject[] | undefined>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentActiveLayer, setCurrentActiveLayer] = useState<number>(-1);

  useEffect(() => {
    if (canvas.current) {
      canvas.current?.on('object:added', () => handleFabricObjectAdded());
      canvas.current?.on('object:modified', () => handleModifiedObject());
      canvas.current?.on('object:removed', () => {
        handleModifiedObject();
      });
      canvas.current?.on('selection:cleared', () => {
        handleCanvasCleared();
      });
    }

    return () => {
      // eslint-disable-next-line
      canvas.current?.off({
        'object:added': () => handleFabricObjectAdded(),
        'object:modified': () => handleModifiedObject(),
        'object:removed': () => {
          handleModifiedObject();
        },
        'selection:cleared': () => {
          handleCanvasCleared();
        },
      });
    };
    // eslint-disable-next-line
  }, []);

  const handleCanvasCleared = () => {
    setCurrentActiveLayer(-1);
  };

  const handleModifiedObject = () => {
    const objects = canvas.current?.getObjects();
    if (objects && objects.length === 0) {
      setCurrentActiveLayer(-1);
      setShapes([]);
    } else {
      setShapes(objects);
    }
  };

  const handleFabricObjectAdded = () => {
    const objects = canvas.current?.getObjects();
    setShapes(objects);
  };

  const handleLayerClick = (i: number) => {
    const activeObject = canvas.current?.getObjects()[i];
    if (i == currentActiveLayer) {
      setCurrentActiveLayer(-1);
      canvas.current?.discardActiveObject().renderAll();
    }
    if (activeObject) {
      setCurrentActiveLayer(i);
      canvas.current?.setActiveObject(activeObject).renderAll();
    }
  };

  if (containerRef == null) return <Loader />;
  return (
    <Grid
      container
      ref={containerRef}
      position={'relative'}
      bgcolor={'#2a2c31'}
      height={'100%'}
      display={'grid'}
      gridTemplateRows={'10% 90%'}
      gridTemplateColumns={'100%'}
    >
      <Grid
        item
        xs={12}
        bgcolor={'#171719'}
        height={'100%'}
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography color={'#fff'}>Layers</Typography>
      </Grid>
      <Grid item xs={12} height={'100%'}>
        {shapes && (
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              overflowY: 'scroll',
              listStyle: 'none',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': { display: 'none' },
              padding: '12px 8px',
              gap: '1vh',
              alignItems: 'center',
            }}
          >
            {shapes
              .slice(1)
              .reverse()
              .map((shape: CanvasObject, index: number) => {
                if (canvas.current) {
                  const miniCanvasData = {
                    ...canvas.current?.toJSON(),
                    background: canvas.current
                      ? typeof canvas.current.backgroundColor === 'string'
                        ? canvas.current.backgroundColor
                        : '#fff'
                      : '#fff',
                  };
                  return (
                    <ListItem
                      key={`${shape?.name}-${index}`}
                      onClick={() => handleLayerClick(shapes.length - 1 - index)}
                      style={{
                        backgroundColor: shapes.length - 1 - index == currentActiveLayer ? 'red' : 'transparent',
                        marginBottom: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 2,
                        height: '10vh',
                        width: '90%',
                      }}
                    >
                      <MiniCanvas
                        ogHeight={canvas.current?.height}
                        ogWidth={canvas.current?.width}
                        data={miniCanvasData}
                        index={shapes.length - 1 - index}
                        shape={shape}
                        zoom={canvas.current?.getZoom()}
                      />
                    </ListItem>
                  );
                }
              })}
          </List>
        )}
      </Grid>
    </Grid>
  );
};

export default Layers;
