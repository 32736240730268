import s from '../styles/toolbutton.module.scss';

const ToolButton = (props: { handleClick: () => void; title: string; children: JSX.Element }) => {
  return (
    <button className={s.iconBtn} onClick={props.handleClick} title={props.title}>
      {props.children}
    </button>
  );
};

export default ToolButton;
