import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ImageUploader from '../image-uploader/ImageUploader';

const UploadFile = () => {
  return (
    <>
      <Box bgcolor={'#ffffff'} border={'1px solid #e3e3e3'} borderRadius={2}>
        <Typography p={2}>
          <strong>Uploading Media</strong> - These images that will be full screen and rotating through a Playlist.
        </Typography>
        <Typography pl={2} fontWeight={'bold'}>
          For best quality Media should be 1920x1080 or 16:9 aspect ratio.
        </Typography>
        <ImageUploader />
      </Box>
      <Link to='/digital-signage/media' style={{ textDecoration: 'none' }}>
        <Button startIcon={<ArrowBackIcon />} color='warning'>
          Go Back
        </Button>
      </Link>
    </>
  );
};

export default UploadFile;
