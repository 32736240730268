import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import s from '../styles.module.scss';
import { TableData } from '../types';

type Props = {
  data: TableData;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setId: Dispatch<SetStateAction<string>>;
};

const Row = ({ data, setIsOpen, setId }: Props) => {
  const handleClick = (id: string) => {
    setIsOpen(true);
    setId(id);
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} className={s.row}>
      <TableCell>{data.deviceName}</TableCell>
      <TableCell>{data.zipCode}</TableCell>
      <TableCell>{data.callsActual}</TableCell>
      <TableCell>{data.client}</TableCell>
      {/* <td> wraps icon so don't get invalid DOM nesting error*/}
      <td className={s.delete} onClick={() => handleClick(data.id)}>
        <IconButton>
          <DeleteIcon />
        </IconButton>
      </td>
    </TableRow>
  );
};

export default Row;
