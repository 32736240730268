import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import AuthKeyDialog from './components/AuthKeyDialog/AuthKeyDialog';

const AuthKeysTable = (data: { data: { authkeys: { [key: string]: number } } }) => {
  const [authKeyModalInView, setAuthKeyModalInView] = useState(false);
  return (
    <Box maxWidth={1400} width={'100%'} borderRadius={2} bgcolor={'#fff'} border={'1px solid #e3e3e3'}>
      <Typography p={2} fontWeight={'bold'}>
        Auth Keys
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead sx={{ backgroundColor: 'rgb(27, 63, 130)' }}>
            <TableRow>
              <TableCell sx={{ borderRadius: 0 }}>
                <Typography color={'#fff'}>Client Name</Typography>
              </TableCell>
              <TableCell>
                <Typography color={'#fff'} textAlign={'right'}>
                  Auth Keys Count
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(data.data.authkeys).map((clientName) => {
              return (
                <TableRow key={clientName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    {clientName}
                  </TableCell>
                  <TableCell align='right'>{data.data.authkeys[clientName]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box marginLeft={'auto'} mt={1}>
        <Button onClick={() => setAuthKeyModalInView(true)} variant={'contained'}>
          New Auth Key
        </Button>
      </Box>
      {authKeyModalInView && <AuthKeyDialog handleClose={() => setAuthKeyModalInView(false)} />}
    </Box>
  );
};

export default AuthKeysTable;
