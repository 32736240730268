import ModeIcon from '@mui/icons-material/Mode';
import { Box, Menu, MenuItem } from '@mui/material';
import { useRef, useState } from 'react';

const ColorOption = ({ color }: { color: string }) => {
  return <Box height={'100%'} width={'100%'} borderRadius={'100%'} bgcolor={color}></Box>;
};

type Props = {
  colors: string[];
  drawMode: (color: string) => void;
  endDrawMode: () => void;
  setPenSize: (size: number) => void;
};

const Pencils = ({ colors, drawMode, endDrawMode, setPenSize }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [selectedColor, setSelectedColor] = useState<string>('gray');
  // const [inDrawMode, setInDrawMode] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorClick = (color: string) => {
    setSelectedColor(color);
    // setInDrawMode(true);
    drawMode(color);
  };

  const cancelDrawMode = () => {
    endDrawMode();
    // setInDrawMode(false)
    setSelectedColor('gray');
  };

  const lastTap = useRef<number>(0);
  // this captures whether it is a single click or double
  const handlePencilClick = (e: React.MouseEvent<HTMLElement>) => {
    const now = Date.now();
    const DOUBLE_TAP_DELAY = 300; // Adjust this value as needed

    if (now - lastTap.current < DOUBLE_TAP_DELAY) {
      handleClick(e);
      lastTap.current = 0;
    } else {
      lastTap.current = now;
      cancelDrawMode();
    }
  };

  // this function is for adjusting the size of the pen
  const handlePenSizeClick = (size: number) => {
    setPenSize(size);
    handleClose();
  };

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'100%'}>
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={handlePencilClick}>
        <ModeIcon fontSize='large' htmlColor={selectedColor} />
      </Box>
      {colors.map((color) => {
        return (
          <Box flex={1} height={'75%'} key={color} onClick={() => handleColorClick(color)} p={0.5}>
            <ColorOption color={color} />
          </Box>
        );
      })}
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handlePenSizeClick(1)}>Small</MenuItem>
        <MenuItem onClick={() => handlePenSizeClick(2)}>Medium</MenuItem>
        <MenuItem onClick={() => handlePenSizeClick(3)}>Large</MenuItem>
      </Menu>
    </Box>
  );
};

export default Pencils;
