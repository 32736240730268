import { Close, Info } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import axios from '../../config/axiosV2';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';

type FileError =
  | ''
  | 'Only jpeg, jpg, and png files are allowed.'
  | 'File size should not exceed 1MB.'
  | 'No files selected for upload.';

const ImageUploader = () => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const user = JSON.parse(decrypted);
  const [selectedFiles, setSelectedFiles] = useState<{ adjustmentNeed: string; file: File }[]>([]);
  const [error, setError] = useState<FileError>('');
  const [resizeMessageInView, setResizeMessageInview] = useState<boolean>(false);
  // progress bar
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);

  const handleDrop = async (acceptedFiles: File[]) => {
    const validFiles: { adjustmentNeed: string; file: File }[] = [];
    let errorMsg: FileError = '';
    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        errorMsg = 'Only jpeg, jpg, and png files are allowed.';
      } else if (file.size > 1000000) {
        errorMsg = 'File size should not exceed 1MB.';
      } else {
        const isValid = await validateFile(file);
        if (isValid) {
          validFiles.push({ adjustmentNeed: 'null', file: file });
        } else {
          setResizeMessageInview(true);
          validFiles.push({ adjustmentNeed: 'scale-to-fit', file: file });
        }
      }
    }

    if (errorMsg) {
      setError(errorMsg);
    } else {
      setError('');
      setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };

  const handleRemove = (fileToRemove: File) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file: { adjustmentNeed: string | null; file: File }) => file.file !== fileToRemove)
    );
  };

  const handleUpload = () => {
    if (selectedFiles.length > 0) {
      // Logic to handle the file upload
      setUploading(true);
      setUploadProgress(0);
      const formData: FormData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        const _file = selectedFiles[i].file;
        formData.append('media[]', _file);
        formData.append('sizing[]', selectedFiles[i].adjustmentNeed ? selectedFiles[i].adjustmentNeed : 'null');
      }
      axios
        .post(`/digitalsignage/media/upload/${user.currentClient.client.id}`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        })
        .then(() => {
          setSelectedFiles([]);
          setError('');
          setResizeMessageInview(false);
          setUploading(false);
          setUploadProgress(0);
          toast.success('Successfully uploaded all Media');
        })
        .catch(() => {
          console.error('Error: Uploading Media.');
        });
    } else {
      setError('No files selected for upload.');
    }
  };

  const handleCancel = () => {
    setSelectedFiles([]);
    setError('');
    setResizeMessageInview(false);
  };

  const validateFile = (file: File): Promise<boolean> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.onload = () => {
          // Check if dimensions are within limits
          if (img.width != 1920 && img.height != 1080) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: '.jpg, .jpeg, .png',
    maxSize: 1000000,
    multiple: true,
    noClick: true, // Disable the click-to-select functionality in the dropzone
  });

  const handleAdjustmentChange = (e: SelectChangeEvent<string>, index: number) => {
    const _selectedFiles = [...selectedFiles];
    _selectedFiles[index].adjustmentNeed = e.target.value;
    setSelectedFiles(_selectedFiles);
  };

  useEffect(() => {
    async function checking() {
      let messageInView = false;
      if (selectedFiles.length > 0) {
        for (let i = 0; i < selectedFiles.length; i++) {
          if (selectedFiles[i].adjustmentNeed) messageInView = true;
        }
      }
      setResizeMessageInview(messageInView);
    }
    checking();
  }, [selectedFiles]);

  return (
    <Box pt={'2vh'}>
      <Stack direction={'row'} spacing={'1vw'} pl={'1vw'}>
        <Button onClick={() => document.getElementById('fileInput')?.click()} variant='contained' color={'primary'}>
          <Typography color={'#fff'} fontWeight={'bold'}>
            Choose
          </Typography>
        </Button>
        <Button onClick={handleUpload} variant='contained' color={'secondary'}>
          Upload
        </Button>
        <Button onClick={handleCancel} variant='contained' color={'error'}>
          Cancel
        </Button>
      </Stack>
      <input
        id='fileInput'
        type='file'
        style={{ display: 'none' }}
        accept='.jpg, .jpeg, .png'
        multiple
        onChange={(e) => handleDrop(Array.from(e.target.files || []))}
      />
      {error && (
        <Box display={'flex'} alignItems={'center'} width={'100%'} height={'5vh'} mt={2}>
          <Typography style={{ color: 'red' }}>{error}</Typography>
        </Box>
      )}
      {resizeMessageInView && (
        <Box display={'flex'} flexDirection={'row'} gap={1} width={'100%'} mt={2} pl={2}>
          <Typography>
            Looks like some of your Media is not 1920x1080. Please select how you would like the Media to scale:
          </Typography>
          <Tooltip
            title={'Scales the Media as large as possible within the screen without cropping or stretching the image.'}
          >
            <Typography fontWeight={'bold'} display={'flex'} alignItems={'center'}>
              <Info fontSize='small' color={'disabled'} />
              Scale-to-fit
            </Typography>
          </Tooltip>
          <Typography>or</Typography>
          <Tooltip
            title={
              'Scales the media to fill the entire container while maintaining its aspect ratio. If the media has proportions different from the container, it will be cropped either vertically or horizontally'
            }
          >
            <Typography fontWeight={'bold'} display={'flex'} alignItems={'center'}>
              <Info fontSize='small' color={'disabled'} />
              Scale-to-fill
            </Typography>
          </Tooltip>
        </Box>
      )}
      <Box
        {...getRootProps({ className: 'dropzone' })}
        style={{
          border: '2px dashed #ccc',
          borderRadius: '4px',
          padding: '20px',
          textAlign: 'center',
          backgroundColor: '#f9f9f9',
          cursor: 'default', // Indicates that the area is non-clickable
          marginTop: '20px',
        }}
      >
        <input {...getInputProps()} />
        <Typography>Drag and drop files here to upload</Typography>
      </Box>
      <Box p={2} maxHeight={'50vh'} overflow={'scroll'} className={'hide-scrollbars'}>
        {selectedFiles.length > 0
          ? selectedFiles.map(({ adjustmentNeed, file }, index) => {
              return (
                <Box key={file.name} display={'flex'} p={1} mb={'10px'}>
                  <Box
                    flex={'1 1 auto'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    boxSizing={'border-box'}
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{ width: '50px', objectFit: 'contain' }}
                    />
                  </Box>
                  <Grid container display={'flex'} flexDirection={'row'} flex={'1 1 auto'}>
                    <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <Typography>{file.name}</Typography>
                    </Grid>
                    <Grid item xs={4} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                      <Typography>{(file.size / 1000).toFixed(2)} KB</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      flex={'1 1 auto'}
                    >
                      {adjustmentNeed !== 'null' ? (
                        <FormControl variant='standard' sx={{ m: 1, minWidth: '50%' }}>
                          <Select
                            id={`select-adjustment-type-${index}`}
                            value={adjustmentNeed}
                            onChange={(e) => handleAdjustmentChange(e, index)}
                            label='Adjustment'
                          >
                            <MenuItem value={'scale-to-fit'}>
                              <Typography textAlign={'center'}>scale to fit</Typography>
                            </MenuItem>
                            <MenuItem value={'scale-to-fill'}>
                              <Typography textAlign={'center'}>scale-to-fill</Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>OK</Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} flex={'1 1 auto'}>
                    <Button
                      onClick={() => handleRemove(file)}
                      style={{
                        height: '48px',
                        width: '45px',
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        padding: '0',
                        cursor: 'pointer',
                        minWidth: '25px',
                      }}
                    >
                      <Close />
                    </Button>
                  </Box>
                </Box>
              );
            })
          : null}
      </Box>
      {uploading && (
        <div
          style={{ marginTop: '20px', width: '100%', height: '10px', backgroundColor: '#e0e0e0', borderRadius: '5px' }}
        >
          <div
            style={{
              width: `${uploadProgress}%`,
              height: '100%',
              backgroundColor: uploadProgress === 100 ? '#4caf50' : '#3b82f6',
              transition: 'width 0.5s ease-in-out',
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default ImageUploader;
