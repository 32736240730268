import { Box, Button, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';
import LooksFourIcon from '@mui/icons-material/Looks4';
import LooksFiveIcon from '@mui/icons-material/Looks5';
import LooksSixIcon from '@mui/icons-material/Looks6';
import useLocalStorageState from 'use-local-storage-state';

type Props = {
  updateNextStep: () => void;
};

const SetupStepsList = ({ updateNextStep }: Props) => {
  const theme = useTheme();
  const darkblue = theme.palette.primary.main;

  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');

  const handleNextClick = () => {
    updateNextStep();
    if (tutorialStep) {
      setTutorialStep(tutorialStep + 1);
    } else setTutorialStep(1);
  };

  return (
    <Stack bgcolor={darkblue} height={'100%'} alignItems={'center'} p={4} borderRadius={2} spacing={4}>
      <Typography color={'#fff'} fontSize={'3rem'} textAlign={'center'}>
        Set Up Steps{' '}
      </Typography>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'50vh'}>
        <List sx={{ padding: 0 }}>
          <ListItem>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <LooksOneIcon fontSize='large' color={'info'} />
              <Typography color={'#fff'} pl={1} fontSize={'2rem'}>
                Palette Creation
              </Typography>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <LooksTwoIcon fontSize='large' color={'info'} />
              <Typography color={'#fff'} pl={1} fontSize={'2rem'}>
                Logo Upload
              </Typography>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <LooksThreeIcon fontSize='large' color={'info'} />
              <Typography color={'#fff'} pl={1} fontSize={'2rem'}>
                Select A Template
              </Typography>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <LooksFourIcon fontSize='large' color={'info'} />
              <Typography color={'#fff'} pl={1} fontSize={'2rem'}>
                Create A Playlist
              </Typography>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <LooksFiveIcon fontSize='large' color={'info'} />
              <Typography color={'#fff'} pl={1} fontSize={'2rem'}>
                Create a Presentation
              </Typography>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
              <LooksSixIcon fontSize='large' color={'info'} />
              <Typography color={'#fff'} pl={1} fontSize={'2rem'}>
                Copy URL
              </Typography>
            </Stack>
          </ListItem>
        </List>
      </Box>
      <Button variant='contained' color={'info'} onClick={handleNextClick}>
        <Typography p={1} fontWeight={'bold'}>
          Create Palette
        </Typography>
      </Button>
    </Stack>
  );
};

export default SetupStepsList;
