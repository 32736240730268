import { useQuery } from '@tanstack/react-query';
import { API_CLIENT } from '../../api/client';
import ClientsTable from '../../components/clients-table';
import Loader from '../../components/common/loader';

const ClientsPage = () => {
  const url = '/client'; // url for get all clients
  const { error, data } = useQuery(['GetAllClients', url], API_CLIENT.getAll);

  if (error) return <p>error</p>;
  if (data) return <ClientsTable data={data} />;
  return <Loader />;
};

export default ClientsPage;
