import { Add, Delete } from '@mui/icons-material';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import { Canvas } from 'fabric/fabric-impl';
import { Dispatch, FormEvent, MouseEvent, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { ColorPalette } from '../../types';
import AlertDialog from '../common/AlertDialog';
import s from '../styles/colorPalette.module.scss';
import CreateColorPalette from './CreateColorPalette';

const ColorPaletteModule = ({
  paletteRef,
  canvas,
  colorPalettes,
  setColorPalettes,
}: {
  paletteRef: MutableRefObject<HTMLLIElement | null>;
  canvas: MutableRefObject<Canvas | undefined>;
  colorPalettes: ColorPalette[];
  setColorPalettes: Dispatch<SetStateAction<ColorPalette[]>>;
}) => {
  // fetch the color palettes from local storage
  const [colorPaletteModuleInView, setColorPaletteModuleInView] = useState<boolean>(false);
  const [activePaletteColorIndex, setActivePaletteColorIndex] = useState({ palette: -1, color: -1 });
  const [rightClicked, setRightClicked] = useState<boolean>(false);
  const [alertInView, setAlertInView] = useState<boolean>(false);

  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  });

  const handleColorSelected = (e: MouseEvent<HTMLLIElement>, color: string) => {
    const activeObject = canvas.current?.getActiveObject();
    if (activeObject) {
      activeObject.set('fill', color);
      canvas.current?.renderAll();
    }
  };

  const handleNewColorPalette = () => {
    setColorPaletteModuleInView(!colorPaletteModuleInView);
  };

  const handleColorPaletteSubmit = (
    e: FormEvent<HTMLFormElement>,
    newColorPaletteTitle: string,
    newColorPaletteColors: string[]
  ) => {
    e.preventDefault();
    // check to see if there exist a localstorage
    // ENCRYPT HERE
    setColorPalettes([...colorPalettes, { title: newColorPaletteTitle, colors: newColorPaletteColors }]);
    setColorPaletteModuleInView(!colorPaletteModuleInView);
  };

  const handleRightClick = (e: MouseEvent<HTMLLIElement>, paletteIndex: number, index: number) => {
    e.preventDefault();
    setRightClicked(true);
    setPoints({ x: e.pageX, y: e.clientY });
    setActivePaletteColorIndex({ palette: paletteIndex, color: index });
  };

  const handleRemoveColorFromPalette = () => {
    const newColorPalettes = colorPalettes.filter(
      (colorPalette: { title: string; colors: string[] }, index: number) => {
        if (index != activePaletteColorIndex.palette) return colorPalette;
        else {
          const newColorPalette = colorPalette.colors.filter((color: string, index: number) => {
            return index !== activePaletteColorIndex.color;
          });
          colorPalette.colors = newColorPalette;
          return colorPalette;
        }
      }
    );
    setColorPalettes(newColorPalettes);
  };

  const handleDeletePaletteClick = (colorPaletteIndex: number) => {
    setActivePaletteColorIndex({ palette: colorPaletteIndex, color: -1 });
    setAlertInView(!alertInView);
  };

  const handleColorPaletteDeletion = (decision: boolean) => {
    if (decision) {
      // delete palette
      const newColorPalettes = colorPalettes.filter((p: ColorPalette, index: number) => {
        return index != activePaletteColorIndex.palette;
      });
      setColorPalettes(newColorPalettes);
    }
    setAlertInView(false);
  };

  useEffect(() => {
    const handleClick = () => {
      if (activePaletteColorIndex.palette != -1 && activePaletteColorIndex.color != -1) {
        setActivePaletteColorIndex({ palette: -1, color: -1 });
      }
      setRightClicked(false);
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* colorPaletteModuleContainer */}
      <Box
        position={'absolute'}
        display={'flex'}
        flexDirection={'column'}
        zIndex={8}
        minWidth={'10vw'}
        maxWidth={'16vw'}
        bgcolor={'#fff'}
        top={paletteRef.current ? paletteRef.current.offsetTop : '20%'}
        left={paletteRef.current ? paletteRef.current.offsetLeft + paletteRef.current.offsetWidth : '0px'}
      >
        {/* header */}
        <Box
          display={'grid'}
          gridTemplateColumns={'70% 30%'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'5vh'}
          bgcolor={'#2a2c31'}
          color={'#fff'}
          mb={1}
        >
          {/* title */}
          <Typography pl={2} fontSize={{ md: 11 }} fontWeight={'bold'} textAlign={'center'}>
            ({colorPalettes.length}) Color Palettes
          </Typography>
          <Button
            title='Add New Color Palette'
            onClick={handleNewColorPalette}
            style={{ backgroundColor: 'transparent', border: 'none', color: '#fff' }}
          >
            <Add />
          </Button>
        </Box>
        {/* listOfPalettes */}
        <List
          style={{ listStyle: 'none', padding: '0 4px', margin: 0, maxHeight: '20vh', overflow: 'scroll' }}
          sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
        >
          {colorPalettes.map((colorPalette: ColorPalette, paletteIndex: number) => {
            return (
              // colorPalette
              <ListItem
                key={colorPalette.title}
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  width: '100%',
                  gap: 1,
                  marginBottom: '4px',
                }}
              >
                <Typography fontWeight={'bold'} width={'100%'} textAlign={'left'}>
                  {colorPalette.title}
                </Typography>
                {/* colorsList */}
                <List
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: '25px',
                    flexWrap: 'wrap',
                    gap: '4px',
                    width: '100%',
                  }}
                >
                  {colorPalette.colors.map((color: string, index: number) => {
                    return (
                      // color
                      <ListItem
                        key={`color-${color}-${index}`}
                        onClick={(e) => handleColorSelected(e, color)}
                        onContextMenu={(e) => handleRightClick(e, paletteIndex, index)}
                        style={{ width: 20, padding: 0, cursor: 'pointer' }}
                      >
                        <Box
                          sx={{ display: 'block', borderRadius: '50%', height: 20, width: 20, padding: 0 }}
                          bgcolor={color}
                        ></Box>
                      </ListItem>
                    );
                  })}
                  <ListItem
                    onClick={() => handleDeletePaletteClick(paletteIndex)}
                    style={{ width: 25, padding: 0, cursor: 'pointer', color: 'red' }}
                  >
                    <Delete />
                  </ListItem>
                </List>
              </ListItem>
            );
          })}
        </List>
      </Box>
      {colorPaletteModuleInView && (
        <CreateColorPalette handleColorPaletteSubmit={handleColorPaletteSubmit} handleClose={handleNewColorPalette} />
      )}
      {rightClicked && (
        <div className={s.menuContext} style={{ top: `${points.y - 70}px`, left: `${points.x - 80}px` }}>
          <ul>
            <li onClick={() => handleRemoveColorFromPalette()}>DELETE</li>
          </ul>
        </div>
      )}
      {alertInView && (
        <AlertDialog
          open={alertInView}
          handleClose={handleColorPaletteDeletion}
          title={'Are you sure you would like to delete this color palette?'}
          contentText={'Once deleted the color palette can not be undone.'}
          confirmBtnText={'Delete Palette'}
          rejectBtnText={'Cancel'}
        />
      )}
    </>
  );
};

export default ColorPaletteModule;
