const Square = ({ color }: { color: string }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 50 50'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>square</title>
      <g id='photo-edit' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='svgs' transform='translate(-131.000000, -70.000000)'>
          <g id='square' transform='translate(131.000000, 70.000000)'>
            <rect id='ViewBox' x='0' y='0' width='50' height='50'></rect>
            <polygon
              id='Path'
              fill={color}
              fillRule='nonzero'
              points='6.25 43.75 6.25 6.25 43.75 6.25 43.75 43.75'
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Square;
