import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BadgeIcon from '@mui/icons-material/Badge';
import BlockIcon from '@mui/icons-material/Block';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, Card, Switch } from '@mui/material';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_USER } from '../../api/user';
import { emailRegex } from '../../constants';
import { useAuthContext } from '../../context/AuthContext';
import { decrypt } from '../../utils/cryptojs-helper';
import BaseInput from '../common/BaseInput';
import Header from '../common/header';
import Modal from '../common/modal';
import s from './styles.module.scss';
import { Props, UserData } from './types';

const SingleUser = ({ data, id, refetch }: Props) => {
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);

  const user = JSON.parse(decrypted);

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState<UserData>(data);

  const [errors, setErrors] = useState({
    email: false,
    password: false,
    passwordCheck: false,
  });

  const update = async () => {
    const { email, firstName, lastName, password, passwordCheck, blacklisted } = userData;
    if (!userData.email.toLowerCase().match(emailRegex)) return setErrors({ ...errors, email: true });
    if (password && password.length < 8) return setErrors({ ...errors, password: true });
    if (password && password !== passwordCheck) return setErrors({ ...errors, passwordCheck: true });

    const dataToSend = {
      email,
      firstName,
      lastName,
      ...(password && { password }),
      blacklisted,
    };

    const success = await API_USER.update(dataToSend, userData.id);
    success ? navigate('/users') : '';
    refetch();
  };

  // fn passed to modal
  const deleteUser = async () => {
    const success = await API_USER.remove(userData.id);
    success ? navigate('/users') : '';
  };

  return (
    <Box className={s.container}>
      <Box width='100%' display='flex' justifyContent='space-between'>
        <Link to={user.id === id ? '/profile' : '/users'} style={{ textDecoration: 'none' }}>
          <Button color='warning' endIcon={<ArrowBackIcon />}>
            Cancel
          </Button>
        </Link>
        {user.id !== id && (
          <Button color='error' onClick={() => setIsOpen(true)}>
            Delete User
          </Button>
        )}
      </Box>
      <Card>
        <Header text='Update Name' Icon={<BadgeIcon />} />
        <BaseInput
          label='First Name'
          value={userData.firstName}
          onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
        />
        <BaseInput
          label='Last Name'
          value={userData.lastName}
          onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
        />
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <Header text='Update Email' Icon={<EmailIcon />} />
        <BaseInput
          label='Email'
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          error={errors.email}
          helperText={errors.email && 'Please enter a valid email'}
          onFocus={() => setErrors({ ...errors, email: false })}
        />
      </Card>
      <Card sx={{ marginBottom: 1, marginTop: 2 }}>
        <Header text='Update Password' Icon={<LockIcon />} />
        <BaseInput
          label='New Password'
          type='password'
          onChange={(e) => setUserData({ ...userData, password: e.target.value })}
          error={errors.password}
          helperText={errors.password && 'Password must be longer than 8 characters'}
          onFocus={() => setErrors({ ...errors, password: false })}
        />
        <BaseInput
          label='Confirm New Password'
          type='password'
          onChange={(e) => setUserData({ ...userData, passwordCheck: e.target.value })}
          error={errors.passwordCheck}
          helperText={errors.passwordCheck && 'Passwords do not match'}
          onFocus={() => setErrors({ ...errors, passwordCheck: false })}
        />
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <Header text='Remove Access' Icon={<BlockIcon />} />
        <Box sx={{ display: 'flex', width: '100%' }}>
          <div style={{ flex: '1' }}>
            <Switch
              checked={userData.blacklisted}
              onChange={() => setUserData({ ...userData, blacklisted: !userData.blacklisted })}
            ></Switch>
          </div>
          {userData.blacklisted ? (
            <p style={{ flex: '1' }}>User account exist but cannot log in.</p>
          ) : (
            <p style={{ flex: '1' }}>User can log in</p>
          )}
        </Box>
      </Card>
      <Button variant='contained' size='small' className={s.button} onClick={() => update()}>
        Update
      </Button>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} fn={deleteUser} />
    </Box>
  );
};

export default SingleUser;
