const rolesData = [
  { label: 'Admin', id: 4000 },
  { label: 'Users', id: 2000 },
  { label: 'Lane-Management', id: 5001 },
  { label: 'Weather-Widget', id: 5002 },
  { label: 'Content-Management', id: 5003 },
  { label: 'Content-Creation', id: 5004 },
  { label: 'Menu-Management', id: 5005 },
  { label: 'Whiteboard-Management', id: 5006 },
];

export default rolesData;
