import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

const TutorialRoute = ({ minReqStep }: { minReqStep?: number }) => {
  const location = useLocation();
  const [tutorialStep] = useLocalStorageState<number>('tutorial-step');
  if (tutorialStep) {
    return (minReqStep && tutorialStep >= minReqStep) || tutorialStep === -1 ? (
      <Outlet />
    ) : (
      <Navigate to='/tutorial' state={{ from: location }} replace />
    );
  } else {
    return <Navigate to='/tutorial' state={{ from: location }} replace />;
  }
};

export default TutorialRoute;
