import CampaignIcon from '@mui/icons-material/Campaign';
import { Box, Button, Card, Stack } from '@mui/material';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Header from '../common/header';
import s from './styles.module.scss';

const Carousel = () => {
  return (
    <Card style={{ height: 400 }}>
      <Header text='Announcements' Icon={<CampaignIcon />} />
      <ReactCarousel
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        emulateTouch={true}
        className={s.carousel}
      >
        <Box width='100%'>
          <h2 className={s.red}>Welcome</h2>
          <h2 className={s.blue}>Welcome</h2>
          <h2 className={s.green}>Welcome</h2>
          <h3>To CURIO Cloud, your digital signage application CMS</h3>
        </Box>
        <Box width='100%'>
          <h3>Do you have a data set you want to integrate with? Ask us about our Database/API services.</h3>
          <Stack direction='row' justifyContent='center' alignItems='center' flexWrap='wrap'>
            <Box className={s.iconCard}>
              <img className={s.icon} src='/icons/salesforce.png' alt='Salesforce Icon' />
            </Box>
            <Box className={s.iconCard}>
              <img className={s.icon} src='/icons/sql.png' alt='SQL Icon' />
            </Box>
            <Box className={s.iconCard}>
              <img className={s.icon} src='/icons/excel.png' alt='Excel Icon' />
            </Box>
            <Box className={s.iconCard}>
              <img className={s.icon} src='/icons/json.png' alt='JSON Icon' />
            </Box>
          </Stack>
        </Box>
        <Box width='100%'>
          <Stack direction='row' justifyContent='center' alignItems='center' className={s.floorContainer}>
            <Box flex={1} textAlign='left' ml={5}>
              <h3>Did you know we provide wayfinding development services?</h3>
              <Button variant='contained'>
                <a href='https://creatingmargin.com/digital-signage/wayfinding/' target='_blank' rel='noreferrer'>
                  Learn More
                </a>
              </Button>
            </Box>
            <Box flex={1}>
              <img className={s.floor} src='/icons/floor.jpeg' alt='floor' />
            </Box>
          </Stack>
        </Box>
      </ReactCarousel>
    </Card>
  );
};

export default Carousel;
