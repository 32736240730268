import { Box } from '@mui/material';
import s from './styles.module.scss';

// This component is used as a loading state for all promises
const Loader = () => {
  return (
    <Box className={s.loader}>
      <Box className={s.one}></Box>
      <Box className={s.two}></Box>
      <Box className={s.three}></Box>
    </Box>
  );
};

export default Loader;
