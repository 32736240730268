const Circle = ({ color }: { color: string }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 50 50'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>circle</title>
      <g id='photo-edit' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='svgs' transform='translate(-330.000000, -71.000000)'>
          <g id='circle' transform='translate(330.000000, 71.000000)'>
            <rect id='ViewBox' x='0' y='0' width='50' height='50'></rect>
            <path
              d='M25,45.8333333 C22.1180556,45.8333333 19.4097222,45.2861111 16.875,44.1916667 C14.3402778,43.0986111 12.1354167,41.6145833 10.2604167,39.7395833 C8.38541667,37.8645833 6.90138889,35.6597222 5.80833333,33.125 C4.71388889,30.5902778 4.16666667,27.8819444 4.16666667,25 C4.16666667,22.1180556 4.71388889,19.4097222 5.80833333,16.875 C6.90138889,14.3402778 8.38541667,12.1354167 10.2604167,10.2604167 C12.1354167,8.38541667 14.3402778,6.90069444 16.875,5.80625 C19.4097222,4.71319444 22.1180556,4.16666667 25,4.16666667 C27.8819444,4.16666667 30.5902778,4.71319444 33.125,5.80625 C35.6597222,6.90069444 37.8645833,8.38541667 39.7395833,10.2604167 C41.6145833,12.1354167 43.0986111,14.3402778 44.1916667,16.875 C45.2861111,19.4097222 45.8333333,22.1180556 45.8333333,25 C45.8333333,27.8819444 45.2861111,30.5902778 44.1916667,33.125 C43.0986111,35.6597222 41.6145833,37.8645833 39.7395833,39.7395833 C37.8645833,41.6145833 35.6597222,43.0986111 33.125,44.1916667 C30.5902778,45.2861111 27.8819444,45.8333333 25,45.8333333 Z'
              id='Path'
              fill={color}
              fillRule='nonzero'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Circle;
