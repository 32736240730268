import BackupTableIcon from '@mui/icons-material/BackupTable';
import { Box, Button, Card, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import MUITable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { Link } from 'react-router-dom';
import { ClientGetData } from '../../api/client/types';
import Header from '../common/header';
import Row from './components/Row';
import s from './styles.module.scss';
import { Props, TableData } from './types';
import { createData } from './utils/create-data';

const ClientsTable = ({ data }: Props) => {
  const rows: TableData[] = [];

  data.forEach((item: ClientGetData) => {
    rows.push(createData(item.id, item.name, item.users));
  });

  return (
    <TableContainer component={Card} className={s.container}>
      <Header text='Clients' Icon={<BackupTableIcon />} />
      <MUITable sx={{ marginTop: 5.5 }}>
        <TableHead className={s.header}>
          <TableRow>
            <TableCell className={s.cell}>Name</TableCell>
            <TableCell className={s.cell}># Of Users</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return <Row key={row.id} data={row} />;
          })}
        </TableBody>
      </MUITable>
      <Box className={s.bottom}>
        <Link to='/clients/add' style={{ textDecoration: 'none' }}>
          <Button variant='contained' sx={{ alignSelf: 'flex-start' }}>
            Add Client
          </Button>
        </Link>
      </Box>
    </TableContainer>
  );
};

export default ClientsTable;
