import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import s from '../styles.module.scss';

const PasswordExpired = () => (
  <Typography lineHeight={2}>
    Your password has <span className={s.day}>expired</span> please update it{' '}
    <Link className={s.link} to='/update-password'>
      here
    </Link>
  </Typography>
);

export default PasswordExpired;
