import { Dispatch, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { ClientCreateData, ClientGetData, ClientRoleData, ClientUpdateData } from './types';

const getAll = async ({ queryKey }: { queryKey: [string, string] }): Promise<ClientGetData[] | undefined> => {
  // the second item of array of queryKey contains the url passed to function
  const url = queryKey[1];
  try {
    const { data } = await axios.get<ClientGetData[]>(url);
    return data;
  } catch (err) {
    toast.error('Error Getting Clients');
  }
};

const getSingle = async ({ queryKey }: { queryKey: [string, string] }): Promise<ClientGetData | undefined> => {
  // the second item of array of queryKey contains the id passed to function
  const id = queryKey[1];

  try {
    const { data } = await axios.get<ClientGetData>(`/client/one/${id}`);
    return data;
  } catch (err) {
    toast.error('Error Getting Client Data');
  }
};

const create = async (setLoading: Dispatch<SetStateAction<boolean>>, data: ClientCreateData): Promise<boolean> => {
  try {
    setLoading(true);
    await axios.post('/client/create', { ...data });
    setLoading(false);
    toast.success('Client Created Successfully');
    return true;
  } catch (error) {
    setLoading(false);
    toast.error('Error Please Try Again');
    return false;
  }
};

const update = async (data: ClientUpdateData): Promise<boolean> => {
  try {
    await axios.put(`/client/update/${data.id}`, { name: data.name, roles: data.roles });
    toast.success('Client Updated Successfully');
    return true;
  } catch (error) {
    toast.error('Error Please Try Again');
    return false;
  }
};

const remove = async (id: string): Promise<boolean> => {
  try {
    await axios.delete(`/client/delete/${id}`);
    toast.success('Client Deleted Successfully');
    return true;
  } catch (error) {
    toast.error('Error Please Try Again');
    return false;
  }
};

const getAllClientsByRole = async ({
  queryKey,
}: {
  queryKey: [string, string];
}): Promise<ClientRoleData | undefined> => {
  // the second item of array of queryKey contains the url passed to function
  const url = queryKey[1];
  try {
    const r = await axios.get(url);
    // toast.success('Client Deleted Successfully');
    return r.data;
  } catch (error) {
    toast.error('Error fetching client data');
    // return undefined;
  }
};

export { getAll, getSingle, create, update, remove, getAllClientsByRole };
