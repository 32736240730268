import {
  ArrowDownward,
  ArrowUpward,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  Lock,
  LockOpen,
} from '@mui/icons-material';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { Box, Button, Grid, List, ListItem, Stack, Tooltip } from '@mui/material';
import { Textbox } from 'fabric/fabric-impl';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ColorPalette } from '../types';
import SelectFont from './common/SelectFont';
import ColorPicker from './modules/ColorPicker';
import './styles/fonts.module.scss';

const ObjectEditTools = ({
  canvas,
  colorPalettes,
  setColorPalettes,
}: {
  // eslint-disable-next-line
  canvas: any;
  colorPalettes: ColorPalette[];
  setColorPalettes: Dispatch<SetStateAction<ColorPalette[]>>;
}) => {
  const [isSelectable, setIsSelectable] = useState<boolean>(true);
  const [selectedFontIndex, setSelectedFontIndex] = useState(0);
  const [fontSize, setFontSize] = useState<number>(12);
  const [buttonsInView, setButtonsInView] = useState<boolean>(false);
  const [colorPalleteInView, setColorPalleteInView] = useState<boolean>(false);
  const [textFontInView, setTextFontInView] = useState<boolean>(false);
  const [activeColor, setActiveColor] = useState<string>('#000');

  const fonts = [
    { name: 'Open Sans', class: 'open-sans' },
    { name: 'EB Garamond', class: 'eb-garamond' },
    { name: 'Jost', class: 'jost' },
    { name: 'Inter', class: 'inter' },
    { name: 'Bodoni Moda', class: 'bodoni-moda' },
    { name: 'Martian Mono', class: 'martian-mono' },
    { name: 'Gothic A1', class: 'gothic-a1' },
    { name: 'Source Serif 4', class: 'source-serif-4' },
    { name: 'Arvo', class: 'arvo' },
    { name: 'Libre Franklin', class: 'libre-franklin' },
    { name: 'Roboto', class: 'roboto' },
    { name: 'La Belle Aurore', class: 'la-belle-aurore' },
  ];

  useEffect(() => {
    canvas.current?.on({
      'selection:created': handleSelectionCreated,
      'selection:updated': handleSelectionCreated,
      // 'object:scaling': handleFontScaling,
    });
    // when an object is deselected it should hide the color pallete
    canvas.current?.on('selection:cleared', function () {
      setColorPalleteInView(false);
      setTextFontInView(false);
      setButtonsInView(false);
    });
    return () => {
      // eslint-disable-next-line
      canvas.current?.off({
        'selection:created': handleSelectionCreated,
        'selection:updated': handleSelectionCreated,
        // 'object:scaling': handleFontScaling,
      });
      // eslint-disable-next-line
      canvas.current?.off('selection:cleared', function () {
        setColorPalleteInView(false);
        setTextFontInView(false);
        setButtonsInView(false);
      });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const activeObject = canvas.current?.getActiveObject();
    if (activeObject) setIsSelectable(activeObject.selectable);
    // eslint-disable-next-line
  }, [canvas.current?.getActiveObject()]);

  const handleFontSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const numValue = parseInt(e.target.value);
    const activeObject = canvas.current?.getActiveObject();
    activeObject.set({ fontSize: numValue });
    canvas.current?.renderAll();
    setFontSize(numValue);
  };

  const handleSelectionCreated = () => {
    const selectedObject = canvas.current?.getActiveObject();
    if (selectedObject.type !== 'image' && selectedObject.type !== 'textbox') {
      setColorPalleteInView(true);
      setActiveColor(selectedObject.fill);
      setTextFontInView(false);
      setButtonsInView(true);
    } else if (selectedObject.type == 'textbox') {
      setColorPalleteInView(true);
      setTextFontInView(true);
      setActiveColor(selectedObject.fill);
      setButtonsInView(true);
      const i = fonts.findIndex((elem: { name: string; class: string }) => {
        return elem.name === selectedObject.fontFamily;
      });
      if (i == -1) setSelectedFontIndex(0);
      else setSelectedFontIndex(i);
      setFontSize(selectedObject.fontSize);
    } else if (selectedObject.type == 'image') {
      setColorPalleteInView(false);
      setTextFontInView(false);
      setButtonsInView(true);
      setActiveColor('#ffffff');
    }
  };

  const handleColorPalleteChange = (color: string) => {
    setActiveColor(color);
    canvas.current?.getActiveObject()?.set('fill', color);
    canvas.current?.renderAll();
  };

  const handleFontFamilyChange = (font: string) => {
    canvas.current?.getActiveObject()?.set('fontFamily', font);
    canvas.current?.renderAll();
  };

  const handleMoveObjectForward = () => {
    const activeObject = canvas.current?.getActiveObject();
    canvas.current?.bringForward(activeObject).renderAll();
    canvas.current?.fire('object:modified');
  };

  const handleMoveObjectBackward = () => {
    const activeObject = canvas.current?.getActiveObject();
    canvas.current?.sendBackwards(activeObject).renderAll();
    canvas.current?.fire('object:modified');
  };

  const handleContainerClick = () => {
    if (!colorPalleteInView && !textFontInView && !buttonsInView) {
      canvas.current?.fire('focus:reset');
    }
  };

  const handleAlignClick = (index: number) => {
    const activeObject: Textbox | undefined = canvas.current?.getActiveObject();
    if (index == 0 && activeObject) {
      activeObject.textAlign = 'left';
      canvas.current.renderAll();
    } else if (index == 1 && activeObject) {
      activeObject.textAlign = 'center';
      canvas.current.renderAll();
    } else if (index == 2 && activeObject) {
      activeObject.textAlign = 'right';
      canvas.current.renderAll();
    }
  };

  const handleUnderline = () => {
    const activeObject: Textbox | undefined = canvas.current?.getActiveObject();
    if (activeObject) {
      if (activeObject.underline === false) {
        activeObject.underline = true;
        canvas.current.renderAll();
      } else {
        activeObject.underline = false;
        canvas.current.renderAll();
      }
    }
  };

  const handleLockClick = () => {
    const activeObject = canvas.current?.getActiveObject();
    if (activeObject && isSelectable) {
      activeObject.selectable = false;
      activeObject.lockMovementX = true;
      activeObject.lockMovementY = true;
      activeObject.lockRotation = true;
      activeObject.lockScalingFlip = true;
      activeObject.lockScalingY = true;
      activeObject.lockScalingX = true;
      activeObject.lockSkewingY = true;
      activeObject.lockSkewingX = true;
      setIsSelectable(false);
    } else if (activeObject && !isSelectable) {
      activeObject.selectable = true;
      activeObject.lockMovementX = false;
      activeObject.lockMovementY = false;
      activeObject.lockRotation = false;
      activeObject.lockScalingFlip = false;
      activeObject.lockScalingY = false;
      activeObject.lockScalingX = false;
      activeObject.lockSkewingY = false;
      activeObject.lockSkewingX = false;
      setIsSelectable(true);
    }
  };

  return (
    <Box
      // className={s.editToolsContainer}
      position={'absolute'}
      zIndex={8}
      top={'10vh'}
      left={'8.3333%'}
      height={'5vh'}
      width={'79.1666%'}
      display={'flex'}
      // p={1}
      alignItems={'center'}
      style={{ borderBottom: colorPalleteInView ? '1px solid #fff' : 'none' }}
      onClick={handleContainerClick}
    >
      {colorPalleteInView && (
        <Box width={'20%'} height={'100%'}>
          <ColorPicker
            handleChange={handleColorPalleteChange}
            activeColor={activeColor}
            colorPalettes={colorPalettes}
            setColorPalettes={setColorPalettes}
          />
        </Box>
      )}
      {textFontInView && (
        <Box width={'55%'} height={'100%'}>
          <Grid container height={'100%'}>
            <Grid item xs={4} height={'100%'}>
              <SelectFont options={fonts} handleSelect={handleFontFamilyChange} selected={selectedFontIndex} />
            </Grid>
            <Grid item xs={2} display={'flex'} alignItems={'center'} justifyContent={'center'} height={'82%'}>
              <Box width={'100%'} height={'100%'} display={'flex'} alignItems={'stretch'}>
                <input
                  type='number'
                  style={{ width: '100%' }}
                  height={'100%'}
                  name='font-size-input'
                  id='font-size-input'
                  value={fontSize}
                  onChange={(e) => handleFontSizeChange(e)}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  padding: 0,
                }}
              >
                <ListItem
                  title='align left'
                  onClick={() => handleAlignClick(0)}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                >
                  <FormatAlignLeft htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
                </ListItem>
                <ListItem
                  title='align-center'
                  onClick={() => handleAlignClick(1)}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                >
                  <FormatAlignCenter htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
                </ListItem>
                <ListItem
                  title='align-right'
                  onClick={() => handleAlignClick(2)}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                >
                  <FormatAlignRight htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
                </ListItem>
                <ListItem
                  title='underline'
                  onClick={handleUnderline}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1 }}
                >
                  <FormatUnderlinedIcon htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      )}
      {buttonsInView ? (
        <Box width={'5%'} height={'100%'} marginLeft={'auto'}>
          <Button onClick={handleLockClick} sx={{ padding: 0, minWidth: '100%', width: '100%', height: '100%' }}>
            {isSelectable ? (
              <LockOpen htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
            ) : (
              <Lock sx={{ width: '18px', height: '18px' }} />
            )}
          </Button>
        </Box>
      ) : (
        ''
      )}
      {buttonsInView ? (
        <Stack direction={'row'} width={'auto'} height={'100%'}>
          <Tooltip title='Move Up a Layer'>
            <Button onClick={handleMoveObjectForward}>
              <ArrowUpward htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
            </Button>
          </Tooltip>
          <Tooltip title='Move Down a Layer'>
            <Button onClick={handleMoveObjectBackward}>
              <ArrowDownward htmlColor='#fff' sx={{ width: '18px', height: '18px' }} />
            </Button>
          </Tooltip>
        </Stack>
      ) : (
        ''
      )}
    </Box>
  );
};

export default ObjectEditTools;
