import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { ClientNames } from '../../types';
import { AccessTokenGetData } from './types';

const getAllTokens = async (): Promise<AccessTokenGetData[]> => {
  try {
    const { data } = await axios.get<AccessTokenGetData[]>('/access-tokens/tokens');
    return data;
  } catch (error) {
    toast.error('Error Getting Access Tokens');
    return [];
  }
};

const generateToken = async (deviceName: string, zipCode: string, client: ClientNames): Promise<string> => {
  const body = {
    deviceName,
    zipCode,
    client,
  };

  try {
    const res = await axios.post<{ token: string }>('/access-tokens/generate', { ...body });
    return res.data.token;
  } catch (error) {
    toast.error('Error Generating Widget');
    return '';
  }
};

const removeToken = async (id: string): Promise<boolean> => {
  try {
    await axios.delete(`/access-tokens/token/${id}`);
    toast.success('Token Deleted Successfully');
    return true;
  } catch (error) {
    toast.error('Error Please Try Again');
    return false;
  }
};

export { generateToken, getAllTokens, removeToken };
