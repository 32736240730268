import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { decrypt } from './cryptojs-helper';

const ProtectedRoute = ({ allowedRoles }: { allowedRoles: number[] }) => {
  // const { user } = useAuthContext();
  const { userNew } = useAuthContext();
  const decrypted = decrypt(userNew);
  const location = useLocation();
  if (decrypted) {
    const user = JSON.parse(decrypted);
    // iterating through the roles of a current users
    return user?.currentClient?.client?.roles?.find((role: number) => allowedRoles?.includes(role)) ? (
      <Outlet />
    ) : user?.currentClient ? (
      <Navigate to='/dashboard' state={{ from: location }} replace />
    ) : (
      <Navigate to='/login' state={{ from: location }} replace />
    );
  } else {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
};

export default ProtectedRoute;
