import { CopyAll, Download } from '@mui/icons-material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import IosShareIcon from '@mui/icons-material/IosShare';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Canvas } from 'fabric/fabric-impl';
import { ChangeEvent, MutableRefObject, useState } from 'react';
import toast from 'react-hot-toast';
import checkIfEmptyString from '../../../utils/check-if-empty';
import Loader from '../../common/loader';
// import { CanvasHistory, CanvasObject } from '../types';
import AlertDialog from './common/AlertDialog';
import ToolButton from './common/ToolButton';
import PlaylistModal from './modules/PlaylistModal';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { API_DS_MEDIA } from '../../../api/digital-signage-cms/media';
import { CanvasObject, PlaylistData } from '../../../api/digital-signage-cms/types';
import { UserAuthData } from '../../../api/user/types';
import s from './styles/header.module.scss';

const Header = ({
  canvas,
  handleDownload,
  canvasHeight,
  canvasWidth,
  user,
}: {
  canvas: MutableRefObject<Canvas | undefined>;
  handleDownload: (fileName: string) => void;
  canvasHeight: number;
  canvasWidth: number;
  user: UserAuthData;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSavingToPlaylist, setIsSavingToPlaylist] = useState<boolean>(false);
  // let processingHistory = false;
  // let history: any = [];
  const [title, setTitle] = useState<string>('');
  const [titleError, setTitleError] = useState<boolean>(false);
  const [alertInView, setAlertInView] = useState<boolean>(false);
  // tutorial steps
  const [tutorialStep, setTutorialStep] = useLocalStorageState<number>('tutorial-step');
  // state needed for the tutorial to know if user has saved their art
  const [hasSaved, setHasSaved] = useState<boolean>(tutorialStep === -1 ? true : false);

  // this will remove focus of any object so it is not deleted
  // when deleting a character from the input
  const handleInputClick = () => {
    canvas.current?.discardActiveObject().renderAll();
  };

  const handleClearCanvasClick = () => {
    setAlertInView(!alertInView);
  };

  const clearCanvas = (choice: boolean) => {
    if (choice) {
      // there is a clear function but that removes the canvas as well
      // so we need to remove all the objects on the canvas instead
      // canvas.current?.forEachObject(function (obj) {
      canvas.current
        ?.getObjects()
        .slice(1)
        .forEach(function (obj) {
          canvas.current?.remove(obj);
        });
    }
    setAlertInView(false);
  };

  const handleDownloadClick = () => {
    if (!checkIfEmptyString(title)) handleDownload(title);
    else {
      setTitleError(true);
      toast.error('Please Give this Document a Title');
    }
  };

  const handleSaveClick = () => {
    if (!checkIfEmptyString(title)) handleMediaSave(title);
    else {
      setTitleError(true);
      toast.error('Please Give this Document a Title');
    }
  };
  /**
   * This will convert the Canvas object into a dataURL object that will
   * be passed to a form and then send back to the API
   * @param fileName - the name to give the saved Media
   */
  const saveMedia = async (fileName: string) => {
    const zoom = canvas.current?.getZoom() || 0.68;
    const multi = ((canvasHeight * canvasWidth) / (canvasHeight * zoom * (canvasWidth * zoom))) * zoom;
    const url =
      canvas.current?.toDataURL({
        format: 'png',
        multiplier: multi,
      }) || '';
    const form = new FormData();
    form.append('file', url);
    form.append('filename', fileName);
    form.append('height', `${canvasHeight}`);
    form.append('width', `${canvasWidth}`);

    const response = await API_DS_MEDIA.save(user.currentClient.client.name, user.currentClient.client.id, form);
    if (response) {
      toast.success('Slide successfully saved!');
      setHasSaved(true);
    } else toast.error('Slide failed to save. Please try again.');
    setIsSaving(false);
    setTitle('');
    // '/save/:clientName/:clientId/:userId'
  };

  // const handleSaveToPlaylist = (playlistIds: string[]) => {
  const handleSaveToPlaylist = async (playlistSelected: PlaylistData[]) => {
    // now create a list of just IDs
    const playlistIds = playlistSelected.map((player) => {
      return player.id;
    });
    if (!checkIfEmptyString(title)) {
      const success = await saveMediaAndAddToPlaylist(title, playlistIds);
      if (success && typeof success == 'object') {
        // now we need to add that new Media to the media list
        for (let i = 0; i < playlistSelected.length; i++) {
          const _playlist = { ...playlistSelected[i] };
          const _media = [..._playlist.media];
          // now grab the new media from the response
          const _newMedia = success[_playlist.id];
          _media.push(_newMedia);
          _playlist.media = _media;
          queryClient.setQueryData<PlaylistData>(['playlistDetails', _playlist.id], { ..._playlist });
        }
      }
    } else {
      setTitleError(true);
      toast.error('Please Give this Document a Title');
    }
  };

  const saveMediaAndAddToPlaylist = async (fileName: string, playlistIds: string[]) => {
    const zoom = canvas.current?.getZoom() || 0.68;
    const multi = ((canvasHeight * canvasWidth) / (canvasHeight * zoom * (canvasWidth * zoom))) * zoom;

    const url =
      canvas.current?.toDataURL({
        format: 'png',
        multiplier: multi,
      }) || '';

    const form = new FormData();
    form.append('file', url);
    form.append('filename', fileName);
    form.append('height', `${canvasHeight}`);
    form.append('width', `${canvasWidth}`);
    form.append('playlistIds', playlistIds.toString());

    const response = await API_DS_MEDIA.saveToPlaylist(
      user.currentClient.client.name,
      user.currentClient.client.id,
      form
    );
    setIsSaving(false);
    setIsSavingToPlaylist(false);
    // '/save/:clientName/:clientId/:userId'
    if (response) {
      toast.success('Slide successfully saved and added to playlist!');
      return response;
    } else {
      toast.error('Slide failed to save. Please try again.');
      return false;
    }
  };

  const handleMediaSave = async (fileName: string) => {
    setIsSaving(true);
    saveMedia(fileName);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    setTitleError(checkIfEmptyString(e.target.value));
  };

  const handleObjectCopy = () => {
    if (canvas.current) {
      canvas.current?.getActiveObject()?.clone(function (cloned: CanvasObject) {
        if (cloned) {
          cloned.clone(function (clonedObj: CanvasObject) {
            canvas.current?.discardActiveObject();
            if (clonedObj) {
              if (clonedObj.left && clonedObj.top) {
                clonedObj.left = clonedObj.left + 10;
                clonedObj.top = clonedObj.top + 10;
                if (clonedObj.type === 'activeSelection') {
                  clonedObj.canvas = canvas.current;
                  // eslint-disable-next-line
                  clonedObj.canvas?.forEachObject(function (obj: any) {
                    canvas.current?.add(obj);
                  });
                  clonedObj.setCoords();
                } else {
                  canvas.current?.add(clonedObj);
                }
                canvas.current?.setActiveObject(clonedObj);
                canvas.current?.requestRenderAll();
              }
            }
          });
        }
      });
    }
  };

  // const KeyPress = (ev: KeyboardEvent) => {
  //   const evtobj = ev;
  //   if ((evtobj.code == 'KeyZ' || evtobj.key == 'z') && evtobj.ctrlKey) {
  //     processingHistory = true;
  //     if (history.length > 0) {
  //       canvas.current?.loadFromJSON(history[history.length - 1], function () {
  //         canvas.current?.renderAll();
  //         processingHistory = false;
  //         history = [...history.slice(0, -1)];
  //       });
  //     }
  //   }
  // };
  // useEffect for ctrl + Z
  // useEffect(() => {
  //   if (canvas.current) {
  //     canvas.current?.on('object:added', () => handleHistoryAddChange());
  //     canvas.current?.on('before:transform', () => handleHistoryModifiedChange());
  //   }

  //   // document.onkeydown = KeyPress;
  //   document.addEventListener('keydown', KeyPress);
  //   // fired after object has been added
  //   return () => {
  //     // eslint-disable-next-line
  //     canvas.current?.off({
  //       'object:added': () => handleHistoryAddChange(),
  //       'before:transform': () => handleHistoryModifiedChange(),
  //     });
  //     document.removeEventListener('keydown', KeyPress);
  //   };
  //   // eslint-disable-next-line
  // }, []);

  // const handleHistoryAddChange = () => {
  //   if (!processingHistory) {
  //     // eslint-disable-next-line
  //     const stringJSON: any = canvas.current?.toDatalessJSON();
  //     if (stringJSON) {
  //       const json: any = stringJSON;
  //       if (json) {
  //         const newObjects = [...json.objects.slice(0, -1)];
  //         json.objects = newObjects;
  //         history = [...history, json];
  //       }
  //     }
  //   }
  // };

  // const handleHistoryModifiedChange = () => {
  //   if (!processingHistory) {
  //     // eslint-disable-next-line
  //     const stringJSON: any = canvas.current?.toDatalessJSON();
  //     if (stringJSON) {
  //       const json: any = stringJSON;
  //       history = [...history, json];
  //     }
  //   }
  // };

  // next step clicking
  // Next step Handle
  const handleNextStepBtnClick = () => {
    if (hasSaved) {
      setTutorialStep(6);
      navigate('/digital-signage/playlist');
    } else {
      alert('Please make sure you save');
    }
  };

  return (
    <>
      <Box
        height={'100%'}
        display={'grid'}
        alignItems={'center'}
        gridTemplateColumns={'25% 70%'}
        gap={'2%'}
        fontSize={12}
        bgcolor={'#2a2c31'}
      >
        <TextField
          error={titleError}
          id={titleError ? 'outlined-error' : 'outlined-basic'}
          label={titleError ? 'Must have a value' : ''}
          value={title}
          onChange={handleTitleChange}
          variant='outlined'
          placeholder={'Document Title'}
          InputProps={{
            style: {
              color: '#fff',
              borderColor: '#fff',
            },
          }}
          onClick={handleInputClick}
        />
        <Box display={'flex'} justifyContent={'space-around'} paddingLeft={'10%'}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography color={'#fff'} fontSize={12}>
              Copy
            </Typography>
            <ToolButton handleClick={handleObjectCopy} title={'Copy Object'}>
              <CopyAll />
            </ToolButton>
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography color={'#fff'} fontSize={12}>
              Clear
            </Typography>
            <ToolButton handleClick={handleClearCanvasClick} title={'Clear Canvas'}>
              <ClearAllIcon />
            </ToolButton>
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography color={'#fff'} fontSize={12}>
              Save
            </Typography>
            <ToolButton handleClick={handleSaveClick} title={'Save to Media'}>
              <IosShareIcon />
            </ToolButton>
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography color={'#fff'} fontSize={12}>
              Download
            </Typography>
            <ToolButton handleClick={handleDownloadClick} title={'Download'}>
              <Download />
            </ToolButton>
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography color={'#fff'} fontSize={12}>
              Playlist
            </Typography>
            <ToolButton handleClick={() => setIsSavingToPlaylist(true)} title={'Add To Playlist'}>
              <PlaylistAddCircleIcon />
            </ToolButton>
          </Box>
        </Box>
        {alertInView && (
          <AlertDialog
            open={alertInView}
            handleClose={clearCanvas}
            title={'Are you sure you want to clear the canvas?'}
            contentText={'You will lose all progress you have made on this masterpiece'}
            confirmBtnText={'Clear Canvas'}
            rejectBtnText={'Cancel'}
          />
        )}
      </Box>
      {tutorialStep === 5 ? (
        <Box position={'absolute'} zIndex={10} bottom={'5%'} right={'50%'} bgcolor={'#fff'} borderRadius={2}>
          <Button onClick={handleNextStepBtnClick}>Next Step: Playlist</Button>
        </Box>
      ) : (
        ''
      )}
      {isSaving && (
        <div className={s.savingPopup}>
          <h3>Saving...</h3>
          <Loader />
        </div>
      )}
      {isSavingToPlaylist && (
        <PlaylistModal user={user} handleCancel={setIsSavingToPlaylist} handleAdd={handleSaveToPlaylist} />
      )}
    </>
  );
};

export default Header;
